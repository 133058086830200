import React,{useEffect,useState} from 'react';
import Googlemap from '../../Components/Googlemap/Googlemap';
import img2 from '../../Images/Pages/contact.png';
import Layout from '../../Components/Layout/Layout';
import wow from 'wowjs';
import { ToastContainer,toast } from 'react-toastify';
import emailjs from 'emailjs-com';
const Contact = () => {
    const [formData,setFormData]=useState({email:'',name:'',subject:'',phnno:'',message:''});
    const [isBooking, setIsBooking] = useState(false);
    useEffect(()=>{
        new wow.WOW().init();
    },[]);
    
    const handleChange=(event)=>{
        const {value,name}=event.target;
        setFormData(prevdata=>({
            ...prevdata,
            [name]:value,
        }));
    };

    const isValidEmail=(email)=> {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

    const submitForm = async()=>{
        if(formData.name==='')
        {
            toast.info('Please Enter Your Name.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        } else if(formData.email===''){

            toast.info('Please Enter Your Email.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }else if(formData.subject==='')
        {
            toast.info('Please Enter Subject.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }else if(formData.phnno==='')
        {
            toast.info('Please Enter Phone Number.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }else if(formData.message==='')
        {
            toast.info('Please Enter Message.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }
        else if(!isValidEmail(formData.email))
        {
            toast.info('Please Enter a valid email.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }

        setIsBooking(true);
        await sendUserMessage();
        setIsBooking(false);
        toast.success('Your query has been submitted successfully.')
    }
    const sendUserMessage = async()=> {
        const messages = [
            {
                recipient: '+966509786777',
                message: `Dear Haramayn Hotels,
              
          A customer has contact via contact form.
              
          Name:${formData.name}
          Email:${formData.email}
          Number:${formData.phnno}
          Subject:${formData.subject}
          Message:${formData.message}
              
          Thank You`
              },
            {
              recipient:formData.phnno ,
              message: `Dear Customer,\nThank you for contacting Haramayn Hotels.\nOne of our agent will contact you within 12 to 24 hours.
            
        Thank You 
        Team Haramayn Hotels`
            }
            
          ];
          const apiKey = 'RUU6Wkzm2JRt';
            for (let i = 0; i < messages.length; i++) {
                const { recipient, message } = messages[i];
                const encodedMessage = encodeURIComponent(message);
                const url = `http://api.textmebot.com/send.php?recipient=${recipient}&apikey=${apiKey}&text=${encodedMessage}`;
            
                try {
                  const response = await fetch(url);
                  
                  if (response.ok) {
                    const contentType = response.headers.get('content-type');
                    
                    if (contentType && contentType.includes('application/json')) {
                      const data = await response.json();
                      console.log(`Message sent to ${recipient}:`, data);
                    } else {
                      const text = await response.text();
                      console.log(`Message sent to ${recipient}:`, text);
                    }
            
                    // Wait 7 seconds before sending the next message
                    if (i < messages.length - 1) {
                      await new Promise(resolve => setTimeout(resolve, 7000));
                    }
            
                  } else {
                    console.error(`Error sending message to ${recipient}:`, response.statusText);
                  }
                } catch (error) {
                    if (i < messages.length - 1) {
                        await new Promise(resolve => setTimeout(resolve, 7000));
                      }
                  console.error(`Error sending message to ${recipient}:`, error);
                }
              }
          }
    return (
        <>
        <Layout>
            <div className="contact-img" >
                <img src={img2}/>
            </div>
            <div className="col-12 mt-2 mb-5">
            
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="wrapper">
                                <div className="wow animate__animated animate__fadeInUp mb-5 mt-5 text-center" data-wow-duration="1s" data-wow-delay="0.2s">
                                    {/* <h1 className="text-capitalize fw-bold" style={{color:'#363d48'}}>Contact<span style={{ color: '#ffb606', fontWeight: 'bold' }} > Us</span></h1> */}
                                    {/* <p className="mt-4" style={{ fontSize: "18px" }}><b>Experience the power of a single partner for all your industrial supply chain and engineered solutions.</b></p> */}
                                    <p>Haramayn Hotels stands out as the pioneer tour operator, providing unparalleled travel experiences for explorers worldwide. Our meticulously crafted packages cater to every aspect of your journey, including hotel bookings, flights, transfers, and activity arrangements. From securing comfortable accommodations to facilitating seamless travel arrangements, our dedicated team meticulously attends to every detail, ensuring a stress-free and enriching experience. Trust in Haramayn Hotels  to tailor packages that perfectly align with your individual needs and preferences, providing peace of mind and unforgettable memories throughout your expedition.</p>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch wow animate__animated animate__fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">
                                        <div className="contact-wrap w-100  p-4">

                                            <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group p-3">
                                                            <label className="label_text">You Name</label>
                                                            <input type="text" placeholder='Name' onChange={handleChange} className="form-control" name="name" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group p-3">
                                                            <label className="label_text">Your Email</label>
                                                            <input type="email" placeholder='Email' onChange={handleChange}  className="form-control" name="email"  required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group p-3">
                                                            <label className="label_text">Your Subject</label>
                                                            <input type="text" placeholder='Subject' onChange={handleChange}  className="form-control" name="subject"  required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group p-3">
                                                            <label className="label_text">Phone Number</label>
                                                            <input type="text" placeholder='+123 456 789' onChange={handleChange} className="form-control" name="phnno"  required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group p-3">
                                                            <label className="label_text">Message</label>
                                                            <textarea name="message" placeholder='Message'  className="form-control" onChange={handleChange} cols="30" rows="4" required></textarea>
                                                            <div className="mb-2">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                    <div className="form-group p-3">
                                                                <button type="submit" disabled={isBooking} onClick={submitForm} className="btn btn-primary select-styling search-btn1"> {isBooking ? <Spinner /> : 'Submit'}</button>
                                                            </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-5 d-flex align-items-stretch wow animate__animated animate__fadeInLeft" data-wow-duration="1s" data-wow-delay="0.2s">
                                        <div className="info-wrap bg-primary w-100  p-4">
                                            <div className="mapviewport">
                                                <div id="mapgyver_holder"  >
                                                    <h4 className="h4-contact text-center mt-3"><span style={{ color: '#ffb606', fontWeight: 'bold' }} >Haramayn Hotels</span></h4>
                                                    <div className="content-behind-map Contact_Us_Right_Div">
                                                        <h4 className="h4-contact h4-contact-gap"> Location</h4>
                                                        <p className="p-contact"> <strong className="strong_text"> Address:</strong> <b class="fa fa-map-marker" aria-hidden="true"> </b>Level 14, Boulevard Plaza Tower 1, Sheikh Mohammad Bin Rashid Boulevard, Downtown Dubai.<br /> <br />  </p>
                                                          <h4 className="h4-contact h4-contact-gap">Call Us:</h4>
                                                            
                                                        <p className="p-contact">  <strong className="strong_text"> Phone:</strong> <b className="fa fa-phone" aria-hidden="true"> </b>+966 509 786777</p>
                                                        <h4 className="h4-contact  h4-contact-gap"> Email</h4>
                                                       
                                                        <p className="p-contact mail" style={{ color: 'white' }}>info@haramaynhotels.com</p>
                                                        {/* <p className="p-contact mail"> <a href="mailto:hamza.shakeel@dynamissolutionz.com" style={{ color: 'white' }} >hamza.shakeel@dynamissolutionz.com</a></p> */}

                                                    </div>
                                                </div>
                                                {/* <div className="social-icons mt-5">
                                                    <a className="logo_hover" href="https://www.facebook.com/profile.php?id=100092518425376&is_tour_completed=true" target="_blank"> <img src="fb_logo.png" alt="Facebook Logo" width="38px" /></a>
                                                    <a className="logo_hover" href="https://instagram.com/decs324?igshid=ZGUzMzM3NWJiOQ==" target="_blank"> <img src="insta.png" alt="Insta Logo" height="50px" width="50px" /></a>
                                                    <a className="logo_hover" href="https://www.linkedin.com/company/dynamis-engineering-and-construction-solution/about/?viewAsMember=true" target="_blank"> <img src="linkedin_logo.png" alt="LinkedIn Logo" height="40px" width="40px" /></a>
                                                    <a className="logo_hover" href="https://api.whatsapp.com/send?phone=971542130049&text=decs" target="_blank"> <img src="whatsapp_logo.png" alt="Facebook Logo" width="38px" /></a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Googlemap/>
            </Layout>
        </>
    );

}

const Spinner = () => {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  };
export default Contact;