import React, { useEffect, useState } from 'react'
import { faKaaba, faMinus, faMosque, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { countryListLocal } from '../Data/CountryList';
import { DateRangePicker,SelectPicker ,Modal,Button} from 'rsuite'
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const { beforeToday } = DateRangePicker;
function HotelSearch () {

  const ReduxSearchData = useSelector((state) => state.hotelSearch.searchQuery);
  const navigate = useNavigate();
  const [places, setPlace] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [countryList, setCountryList] = useState([]);
  const [adultModal, setAdultModal] = useState(false);
  const [selectedCity, setSelectedCity] = useState("makkah");
  const [loading, setLoading] = useState(false);
  const [hotelView, setHotelView] = useState(null);
  const [childAgesError, setChildAgesError] = useState({});
  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedNationality, setSelectedNationality] = useState("");
  const [personData, setPersonData] = useState({
    adult: 2,
    children: 0,
    room: 1,
  });
  const [dateRangePicker, setDateRangePicker] = useState(null);
  const [placeDetail, setPlaceDetail] = useState({
    city: "",
    country: "",
    countrycode: "",
    lat: 0,
    long: 0,
    checkindate: "",
    Checkoutdate: "",
  });
  const [rooms, setRooms] = useState([
    {
      adults: 2,
      children: 0,
      childrenAges: [],
    },
  ]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [dateRange, setDateRange] = useState({
    checkindate: null,
    Checkoutdate: null,
  });

  useEffect(() => {
    const location = localStorage.getItem("SearchLocation");
    if (location === "All") {
      setSelectedLocation('1');
      if (ReduxSearchData.cityd === "Makkah") {
        setSelectedCity("makkah");
      } else {
        setSelectedCity("medina");
      }
    } else {
      if (ReduxSearchData.cityd === "Makkah") {
        setSelectedLocation('2');
      } else {
        setSelectedLocation('3');
      }
    }
    setPlaceDetail((prevPlaceDetail) => ({
      ...prevPlaceDetail,
      checkindate: ReduxSearchData.check_in,
      Checkoutdate: ReduxSearchData.check_out,
    }));
    if (ReduxSearchData.room_View !== "") {
      setHotelView(ReduxSearchData.room_View);
    }
    const checkInDatec = new Date(ReduxSearchData.check_in);
    const checkOutDatec = new Date(ReduxSearchData.check_out);
    setDateRange([checkInDatec, checkOutDatec]);
    setDateRangePicker([checkInDatec, checkOutDatec]);
    const roomsData = ReduxSearchData?.rooms_counter?.map((_, index) => {
      const childAgesKey = `child_ages${index + 1}`;
      return {
        adults: ReduxSearchData.Adults[index],
        children: ReduxSearchData.children[index],
        childrenAges: ReduxSearchData[childAgesKey], // Assuming this is empty for now, you can modify as needed
      };
    });
    setRooms(roomsData);
    getUserLocation();
    const countryOptions = countryListLocal.item.map((country) => ({
      value: country.name.common,
      label: country.name.common,
      flag: country.flags.png,
      phoneCode: country.idd.root + country.idd.suffixes[0],
    }));
    setCountryList(countryOptions);
  }, [ReduxSearchData]);

  function getUserLocation() {
    // Fetch user's location data
    fetch(
      "https://api.geoapify.com/v1/ipinfo?&apiKey=0708571ae58c4688824a7d01ca397041"
    )
      .then((response) => response.json())
      .then((data) => {
        setSelectedNationality(data.country.name);
        localStorage.setItem("UserCountry", data.country.name);
      })
      .catch((error) => {
        console.error("Error fetching user location:", error);
      });
  }
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false); 
      return; // Exit the effect early to avoid running the code below.
    }

    if (places.length !== 0) {
      // debugger
      if (
        places.address_components.filter(
          (f) =>
            JSON.stringify(f.types) ===
            JSON.stringify(["locality", "political"])
        )[0]
      ) {
        const city1 = places.address_components.filter(
          (f) =>
            JSON.stringify(f.types) ===
            JSON.stringify(["locality", "political"])
        )[0]?.short_name;
        // console.log('city1=' + city1)
        setPlaceDetail((prevPlaceDetail) => ({
          ...prevPlaceDetail,
          city: city1,
        }));
      } else {
        const city1 = places.address_components.filter(
          (f) =>
            JSON.stringify(f.types) ===
            JSON.stringify(["administrative_area_level_2", "political"])
        )[0]?.short_name;
        // console.log('city1=' + city1)
        setPlaceDetail((prevPlaceDetail) => ({
          ...prevPlaceDetail,
          city: city1,
        }));
      }

      if (
        places.address_components.filter(
          (f) =>
            JSON.stringify(f.types) ===
            JSON.stringify(["locality", "political"])
        )[0]
      ) {
        const displayCity1 = places.address_components.filter(
          (f) =>
            JSON.stringify(f.types) ===
            JSON.stringify(["locality", "political"])
        )[0]?.long_name;
        // console.log('displayCity1=' + displayCity1)
      } else {
        const displayCity1 = places.address_components.filter(
          (f) =>
            JSON.stringify(f.types) ===
            JSON.stringify(["administrative_area_level_2", "political"])
        )[0]?.long_name;
        // console.log('displayCity1=' + displayCity1)
      }

      if (
        places.address_components.filter(
          (f) =>
            JSON.stringify(f.types) === JSON.stringify(["country", "political"])
        )[0]
      ) {
        const country_code = places.address_components.filter(
          (f) =>
            JSON.stringify(f.types) === JSON.stringify(["country", "political"])
        )[0]?.short_name;
        // console.log('country_code=' + country_code)
        setPlaceDetail((prevPlaceDetail) => ({
          ...prevPlaceDetail,
          countrycode: country_code,
        }));
      }
      if (
        places.address_components.filter(
          (f) =>
            JSON.stringify(f.types) === JSON.stringify(["country", "political"])
        )[0]
      ) {
        const country = places.address_components.filter(
          (f) =>
            JSON.stringify(f.types) === JSON.stringify(["country", "political"])
        )[0]?.long_name;
        setPlaceDetail((prevPlaceDetail) => ({
          ...prevPlaceDetail,
          country: country,
        }));
      }
      var address = places.formatted_address;
      var latitude = places.geometry.location.lat();
      setPlaceDetail((prevPlaceDetail) => ({
        ...prevPlaceDetail,
        lat: latitude,
      }));
      var longitude = places.geometry.location.lng();
      setPlaceDetail((prevPlaceDetail) => ({
        ...prevPlaceDetail,
        long: longitude,
      }));
      const latlng = new window.google.maps.LatLng(latitude, longitude);
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ latLng: latlng }, function (results, status) {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            const addressComponents = results[0].address_components;
            const lastAddressComponent =
              addressComponents[addressComponents.length - 1];
            const country = lastAddressComponent.short_name;
            const state =
              addressComponents[addressComponents.length - 3].long_name;
            const city =
              addressComponents[addressComponents.length - 4].long_name;
          }
        }
      });
    }
  }, [places, initialRender]);

  useEffect(() => {
    const adults = rooms.reduce((acc, item) => acc + item.adults, 0);
    const children = rooms.reduce((acc, item) => acc + item.children, 0);
    const roomss = rooms.length;
    setPersonData((prevPersonData) => ({
      ...prevPersonData,
      adult: adults,
      children: children,
      room: roomss,
    }));
  }, [rooms]);

  const handleDateRangeChange = (value) => {
    setDateRangePicker(value);
    if (value === null) {
      setPlaceDetail((prevPlaceDetail) => ({
        ...prevPlaceDetail,
        checkindate: "",
        Checkoutdate: "",
      }));
      setDateRange(null);
    } else {
      setPlaceDetail((prevPlaceDetail) => ({
        ...prevPlaceDetail,
        checkindate: moment(value[0]).format("YYYY-MM-DD"),
        Checkoutdate: moment(value[1]).format("YYYY-MM-DD"),
      }));

      setDateRange(value);
    }
  };

  const toggleAdultModal = () => {
    let ageStatus = false;
    let messages = {}; // Store error messages for each room and child

    for (let i = 0; i < rooms.length; i++) {
      const { children, childrenAges } = rooms[i];

      if (children !== childrenAges.length) {
        for (let j = 0; j < children; j++) {
          if (!childrenAges[j]) {
            // Check if age is missing for a specific child
            messages[
              `room-${i}-child-${j}`
            ] = `Please select child age.`;
            ageStatus = true;
          }
        }
      }
    }

    if (ageStatus) {
      setChildAgesError(messages); // Store messages for each specific child missing an age
      return;
    }

    setChildAgesError({});
    setAdultModal(!adultModal);
  };

  const handleAdultIncrement = (roomIndex) => {
    // Increment the number of adults for a specific room
    const updatedRooms = [...rooms];
    if (updatedRooms[roomIndex].adults < 6) {
      updatedRooms[roomIndex].adults += 1;
      setRooms(updatedRooms);
    }
  };

  const handleAdultDecrement = (roomIndex) => {
    // Decrement the number of adults for a specific room
    const updatedRooms = [...rooms];
    if (updatedRooms[roomIndex].adults > 1) {
      updatedRooms[roomIndex].adults -= 1;
      setRooms(updatedRooms);
    }
  };

  const handleChildrenIncrement = (roomIndex) => {
    // Increment the number of children for a specific room, if less than 4
    const updatedRooms = [...rooms];
    if (updatedRooms[roomIndex].children < 4) {
      updatedRooms[roomIndex].children += 1;
      setRooms(updatedRooms);
    }
  };

  const handleChildrenDecrement = (roomIndex) => {
    // Decrement the number of children for a specific room
    const updatedRooms = [...rooms];
    if (updatedRooms[roomIndex].children > 0) {
      updatedRooms[roomIndex].children -= 1;
      updatedRooms[roomIndex].childrenAges.pop();
      setRooms(updatedRooms);
    }
  };

  const handleChildAgeChange = (roomIndex, childIndex, value) => {
    // Update the age of a specific child for a specific room
    const updatedRooms = [...rooms];
    updatedRooms[roomIndex].childrenAges[childIndex] = value;
    setRooms(updatedRooms);
  };
  const addRoom = () => {
    // Add a new room with default values
    if(rooms.length <9){
    setRooms([...rooms, { adults: 2, children: 0, childrenAges: [] }]);
    }
  };

  const removeLastRoom = () => {
    if (rooms.length > 1) {
      // Remove the last room by slicing the array
      setRooms(rooms.slice(0, -1));
    }
  };
  const handleDeleteRoomByIndex = (roomIndex) => {
    const updatedRooms = rooms.filter((room, index) => index !== roomIndex);
    setRooms(updatedRooms);
  };
  const childAgearray = [
    "1 Year",
    "2 Year",
    "3 Year",
    "4 Year",
    "5 Year",
    "6 Year",
    "7 Year",
    "8 Year",
    "9 Year",
    "10 Year",
  ];
  const Searchhotels = async () => {
    if (dateRange.Checkoutdate === null || dateRange.Checkoutdate === "") {
      toast.info("Please Select Hotel Stay.");
      return;
    } else if (moment(placeDetail.checkindate).isSame(moment(), "day")) {
      toast.info("Please Select a Future Check in Date.");
      return;
    }

    for (let i = 0; i < rooms.length; i++) {
      const { children, childrenAges } = rooms[i];
      
      if (children !== childrenAges.length) {
        toast.info(`Room ${i + 1}: You selected ${children} children but provided age(s) for only ${childrenAges.length}. Please select the ages for all children.`);
        return;
      }
    }

    setLoading(true);
    if (selectedLocation === '1') {
      localStorage.setItem("SearchLocation", "All");
    } else {
      localStorage.setItem("SearchLocation", "null");
    }
    var destination_name = "";
    var country = "";
    var lat = "";
    var lon = "";
    var pin = "";
    if (selectedLocation === '2') {
      destination_name = "Makkah";
      country = "Saudi Arabia";
      lat = 21.4240968;
      lon = 39.81733639999999;
      pin = "SA";
    } else if (selectedLocation === '3') {
      destination_name = "Madinah";
      country = "Saudi Arabia";
      lat = 24.4672132;
      lon = 39.6024496;
      pin = "SA";
    } else {
      if (selectedCity === "makkah") {
        destination_name = "Makkah";
        country = "Saudi Arabia";
        lat = 21.4240968;
        lon = 39.81733639999999;
        pin = "SA";
      } else {
        destination_name = "Madinah";
        country = "Saudi Arabia";
        lat = 24.4672132;
        lon = 39.6024496;
        pin = "SA";
      }
    }
    const FormData = {
      destination_name: destination_name,
      country: country,
      lat: lat,
      long: lon,
      pin: pin,
      cityd: destination_name,
      country_code: pin,
      check_in: placeDetail.checkindate,
      check_out: placeDetail.Checkoutdate,
      slc_nationality: String(selectedNationality),
      ...rooms.reduce(
        (acc, item, index) => {
          acc.adult += item.adults;
          acc.child += item.children;
          acc.room += 1;
          acc.Adults.push(item.adults);
          acc.children.push(item.children);
          acc[`child_ages${index + 1}`] = item.childrenAges.map((age) =>
            age ? parseInt(age) : 2
          );
          acc.rooms_counter.push(acc.room);
          return acc;
        },
        {
          adult: 0,
          child: 0,
          room: 0,
          Adults: [],
          children: [],
          child_ages1: [],
          rooms_counter: [],
          child_ages2: [],
        }
      ),
    };
    const queryString = new URLSearchParams(FormData).toString();
    navigate(`/hotel_listing?${queryString}`);
  };
  const HandleLocationChange =(value)=>{
    setSelectedLocation(value);
  };

  const handleHotelView = (value) => {
    setHotelView(value);
  };
  const handlecitychange = (event) => {
    setSelectedCity(event.target.value);
  };
  const searchoption=[
    { label: 'Makkah', value: '2' },
    { label: 'Medina', value: '3' },
  ]
  return (
    <>
      <ToastContainer />
      <Modal open={adultModal} onClose={toggleAdultModal} className="centered-modal">
        <Modal.Header>
          <Modal.Title>Select Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
            {rooms.map((room, roomIndex) => (
              <div key={roomIndex}>
                <div
                  className="hotel-detail-border d-flex text-center fw-bold mb-2"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p className="fw-bold m-1">Room {roomIndex + 1}</p>
                  {roomIndex > 0 && ( // Check if roomIndex is greater than 0
                    <button
                      className="delete-room-button m-1"
                      onClick={() => handleDeleteRoomByIndex(roomIndex)}
                    >
                      Delete
                    </button>
                  )}
                </div>
                <div>
                  <div className="select-child">
                    <div class="f4878764f1">
                      <label class="a984a491d9 fw-bold" for="group_adults">
                        Adults
                      </label>
                    </div>
                    <div class="d-flex fff">
                      <button
                        className="adult-modal-btn"
                        name={`adults-${roomIndex}`}
                        onClick={() => handleAdultDecrement(roomIndex)}
                      >
                        <i class="fas fa-minus">
                          <FontAwesomeIcon size="sm" icon={faMinus} />
                        </i>
                      </button>
                      <span className="d723d73d5f fw-bold" id="input">
                        {room.adults}
                      </span>
                      <button
                        className="adult-modal-btn"
                        name={`adults-${roomIndex}`}
                        onClick={() => handleAdultIncrement(roomIndex)}
                        id="increment"
                      >
                        <i class="fas fa-plus">
                          <FontAwesomeIcon size="sm" icon={faPlus} />
                        </i>
                      </button>
                    </div>
                  </div>
                  <div className="select-child">
                    <div class="f4878764f1">
                      <label class="a984a491d9 fw-bold" for="group_adults">
                        Children
                      </label>
                    </div>
                    <div class="d-flex fff">
                      <button
                        className="adult-modal-btn"
                        onClick={() => handleChildrenDecrement(roomIndex)}
                        id="decrement"
                      >
                        <i class="fas fa-minus">
                          <FontAwesomeIcon size="sm" icon={faMinus} />
                        </i>
                      </button>
                      <span className="d723d73d5f fw-bold" id="input">
                        {room.children}
                      </span>
                      <button
                        className="adult-modal-btn"
                        onClick={() => handleChildrenIncrement(roomIndex)}
                        id="increment"
                      >
                        <i class="fas fa-plus">
                          <FontAwesomeIcon size="sm" icon={faPlus} />
                        </i>
                      </button>
                    </div>
                  </div>
                  <div
                    className="select-child"
                    style={{ justifyContent: "left" }}
                  >
                    {room.children > 0 && (
                      <div className="row w-100 ">
                        {Array.from(
                          { length: room.children },
                          (_, childIndex) => (
                            <div
                              key={childIndex}
                              className="m-1 "
                              style={{ width: "45%", alignItems: "center" }}
                            >
                              <label>Child {childIndex + 1} (Age)</label>
                              <select
                                class="form-select child-age-select"
                                name="child1"
                                value={room.childrenAges[childIndex]}
                                onChange={(e) =>
                                  handleChildAgeChange(
                                    roomIndex,
                                    childIndex,
                                    e.target.value
                                  )
                                }
                                aria-label="Default select example"
                              >
                                <option selected>Age needed</option>
                                {childAgearray.map((item, index) => (
                                  <option key={index} value={index + 1}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                              {childAgesError[
                                `room-${roomIndex}-child-${childIndex}`
                              ] && (
                                <p className="text-danger">
                                  {
                                    childAgesError[
                                      `room-${roomIndex}-child-${childIndex}`
                                    ]
                                  }
                                </p>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end align-items-center">
          <div className="select-child">
            <div class="f4878764f1 pe-4">
              <label class="a984a491d9 fw-bold" for="group_adults">
                Room
              </label>
            </div>
            <div class="d-flex fff">
              <button
                className="adult-modal-btn"
                onClick={removeLastRoom}
                id="decrement"
              >
                <i class="fas fa-minus">
                  <FontAwesomeIcon size="sm" icon={faMinus} />
                </i>
              </button>
              <span className="d723d73d5f fw-bold" id="input">
                {rooms.length}
              </span>
              <button
                className="adult-modal-btn"
                onClick={addRoom}
                id="increment"
              >
                <i class="fas fa-plus">
                  <FontAwesomeIcon size="sm" icon={faPlus} />
                </i>
              </button>
            </div>
          </div>
          <button  className="btn ms-2 btn-primary" onClick={toggleAdultModal}>
            Apply
          </button>
        </Modal.Footer>
      </Modal>
      <div className='tab-content'>
        <div className='tab-pane fade show active'>
          <div className='row check-availabilty'>
            <div className=''>
              <div className='row'>
                <div className='col-md-3  mb-lg-0  mt-2'>
                  <div className='field-icon-wrap'>
                    <SelectPicker class="form-select" data={searchoption} 
               searchable={false} 
               value={selectedLocation}
               style={{ width: '100%' }} 
               onChange={HandleLocationChange}
               renderValue={(value) => {
                const selectedLocation = searchoption.find(
                  (option) => option.value === value
                );
                return selectedLocation ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                     <FontAwesomeIcon className="me-1" icon={selectedLocation.label==='Makkah'? faKaaba:faMosque}/>
                    {selectedLocation.label}
                  </div>
                ) : (
                  value
                );
              }}
               renderMenuItem={(label, item) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon className="me-1" icon={label==='Makkah'? faKaaba:faMosque}/>{label}{" "}
                  {/* Use label parameter for the country name */}
                </div>
              )}
               />
                  </div>
                </div>
                <div className='col-md-3  mb-lg-0 mt-2'>
                  {/* <label htmlFor='checkin_date' className='font-weight-bold text-black mb-2'>
                          Hotel Stay
                        </label> */}
                  <div className='field-icon-wrap'>
                  <DateRangePicker
                onChange={handleDateRangeChange}
                value={dateRange}
                format="dd-MM-yyyy"
                showOneCalendar={isMobile}
                disabledDate={beforeToday()}
                className="w-100 text-left  "
              />
                  </div>
                </div>
                <div className='col-md-3  mb-lg-0 mt-2'>
                  {/* <label htmlFor='checkin_date' className='font-weight-bold text-black mb-2'>
                          Select Rooms
                        </label> */}
                  <div className='field-icon-wrap'>
                    <button
                      onClick={toggleAdultModal}
                      className='btn text-left btn-primary btn-block select-styling button-2 search-btn1'
                      style={{
                        color: 'black',
                        background: 'none',
                        borderRadius: '5px'
                      }}
                    >
                      {personData.adult} Adults . {personData.children} Children
                      . {personData.room} Room
                    </button>
                  </div>
                </div>
                <div className='col-md-3 text-center mt-2 align-self-end'>
                  {/* <NavLink to='/hotels'> */}
                  <button
                   disabled={dateRangePicker===null|| selectedLocation===null ? true:false}
                    onClick={Searchhotels}
                    className='btn btn-primary btn-block select-styling search-btn1'
                  >
                   Search  <FontAwesomeIcon icon={faSearch} />
                  </button>
                  {/* </NavLink> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HotelSearch
