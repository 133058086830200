export function Hotelapitoken(){
    return 'IoOCQ3ObCcqj3BmtYVUBKTaCIWWoFnQcCorlRUsL-peMCb6m7dlEhXnSJhXXEo7Dh8bG7WQq18wbzMIvAKNk2RtIVKSBc3uUgZASa-0DZ0L5oiwJ9rSktbNb1dM3efA-b7BLH97ryRSj8vglisLUecscxtA1OFPF7kYWWaqDSKxovS9yKw4jBhUWwMrYT306oG2UZgmDpxP-zx6hENsrnFrHXtOqO6e5SA6ZdJsbJmOXZxDq5ZOcLdZ6PgzeQVdnivhXQHA8g3gzQoNuhYo4E1UYNOdTYGS16EvMpOUTxfmhmLz1-hw9SPnIiIzOX9K83qEOptngC4ftezuMmw2cFusTrxrKMvbH8SUqKAiywnTuiyV4yunaolsqVwbR-4PyM6FO8usVBMFf49vNBSO0nh-cdb8imZPtqb4xGeGHHIu5mG7uMAKZaJVbXGpC2eZfjab3NGV9Z-fmSmrDdAmO44ew0Xf0ZIXu4UoJx8a7EfGQRwWl51g5ZF93J0HH'
};

export function CustomerDomainName(){
    return 'https://admin.haramaynhotels.com/'
};
export function FlightSearchToken(){
    return 'IoOCQ3ObCcqj3BmtYVUBKTaCIWWoFnQcCorlRUsL-peMCb6m7dlEhXnSJhXXEo7Dh8bG7WQq18wbzMIvAKNk2RtIVKSBc3uUgZASa-0DZ0L5oiwJ9rSktbNb1dM3efA-b7BLH97ryRSj8vglisLUecscxtA1OFPF7kYWWaqDSKxovS9yKw4jBhUWwMrYT306oG2UZgmDpxP-zx6hENsrnFrHXtOqO6e5SA6ZdJsbJmOXZxDq5ZOcLdZ6PgzeQVdnivhXQHA8g3gzQoNuhYo4E1UYNOdTYGS16EvMpOUTxfmhmLz1-hw9SPnIiIzOX9K83qEOptngC4ftezuMmw2cFusTrxrKMvbH8SUqKAiywnTuiyV4yunaolsqVwbR-4PyM6FO8usVBMFf49vNBSO0nh-cdb8imZPtqb4xGeGHHIu5mG7uMAKZaJVbXGpC2eZfjab3NGV9Z-fmSmrDdAmO44ew0Xf0ZIXu4UoJx8a7EfGQRwWl51g5ZF93J0HH'
};

export function ApiEndPoint(){
    return 'https://api.synchtravel.com'
    
};
export function TransferApiToken(){
    return 'IoOCQ3ObCcqj3BmtYVUBKTaCIWWoFnQcCorlRUsL-peMCb6m7dlEhXnSJhXXEo7Dh8bG7WQq18wbzMIvAKNk2RtIVKSBc3uUgZASa-0DZ0L5oiwJ9rSktbNb1dM3efA-b7BLH97ryRSj8vglisLUecscxtA1OFPF7kYWWaqDSKxovS9yKw4jBhUWwMrYT306oG2UZgmDpxP-zx6hENsrnFrHXtOqO6e5SA6ZdJsbJmOXZxDq5ZOcLdZ6PgzeQVdnivhXQHA8g3gzQoNuhYo4E1UYNOdTYGS16EvMpOUTxfmhmLz1-hw9SPnIiIzOX9K83qEOptngC4ftezuMmw2cFusTrxrKMvbH8SUqKAiywnTuiyV4yunaolsqVwbR-4PyM6FO8usVBMFf49vNBSO0nh-cdb8imZPtqb4xGeGHHIu5mG7uMAKZaJVbXGpC2eZfjab3NGV9Z-fmSmrDdAmO44ew0Xf0ZIXu4UoJx8a7EfGQRwWl51g5ZF93J0HH'
};
export function NewTransferSearchToken(){
    return 'IoOCQ3ObCcqj3BmtYVUBKTaCIWWoFnQcCorlRUsL-peMCb6m7dlEhXnSJhXXEo7Dh8bG7WQq18wbzMIvAKNk2RtIVKSBc3uUgZASa-0DZ0L5oiwJ9rSktbNb1dM3efA-b7BLH97ryRSj8vglisLUecscxtA1OFPF7kYWWaqDSKxovS9yKw4jBhUWwMrYT306oG2UZgmDpxP-zx6hENsrnFrHXtOqO6e5SA6ZdJsbJmOXZxDq5ZOcLdZ6PgzeQVdnivhXQHA8g3gzQoNuhYo4E1UYNOdTYGS16EvMpOUTxfmhmLz1-hw9SPnIiIzOX9K83qEOptngC4ftezuMmw2cFusTrxrKMvbH8SUqKAiywnTuiyV4yunaolsqVwbR-4PyM6FO8usVBMFf49vNBSO0nh-cdb8imZPtqb4xGeGHHIu5mG7uMAKZaJVbXGpC2eZfjab3NGV9Z-fmSmrDdAmO44ew0Xf0ZIXu4UoJx8a7EfGQRwWl51g5ZF93J0HH'
};
export function TransferCheckoutToken(){
    return 'IoOCQ3ObCcqj3BmtYVUBKTaCIWWoFnQcCorlRUsL-peMCb6m7dlEhXnSJhXXEo7Dh8bG7WQq18wbzMIvAKNk2RtIVKSBc3uUgZASa-0DZ0L5oiwJ9rSktbNb1dM3efA-b7BLH97ryRSj8vglisLUecscxtA1OFPF7kYWWaqDSKxovS9yKw4jBhUWwMrYT306oG2UZgmDpxP-zx6hENsrnFrHXtOqO6e5SA6ZdJsbJmOXZxDq5ZOcLdZ6PgzeQVdnivhXQHA8g3gzQoNuhYo4E1UYNOdTYGS16EvMpOUTxfmhmLz1-hw9SPnIiIzOX9K83qEOptngC4ftezuMmw2cFusTrxrKMvbH8SUqKAiywnTuiyV4yunaolsqVwbR-4PyM6FO8usVBMFf49vNBSO0nh-cdb8imZPtqb4xGeGHHIu5mG7uMAKZaJVbXGpC2eZfjab3NGV9Z-fmSmrDdAmO44ew0Xf0ZIXu4UoJx8a7EfGQRwWl51g5ZF93J0HH'
};
export function CurrencyConverter(){
    return 'b8d4b285d48d1e690388e999'
};
export function StripeClientSecret(){
    // return 'sk_test_51N9pi8GwYlRApJ0wzqUOVIJBpCNJ1Lz7UcUjJoSdMU73WAVzoTXSJ1gfakCYThPWhnzWlKnLLQXugdB0s8dBFDJ500Beg22CmF'
    return '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'
};
export function ActivityToken(){
    return 'IoOCQ3ObCcqj3BmtYVUBKTaCIWWoFnQcCorlRUsL-peMCb6m7dlEhXnSJhXXEo7Dh8bG7WQq18wbzMIvAKNk2RtIVKSBc3uUgZASa-0DZ0L5oiwJ9rSktbNb1dM3efA-b7BLH97ryRSj8vglisLUecscxtA1OFPF7kYWWaqDSKxovS9yKw4jBhUWwMrYT306oG2UZgmDpxP-zx6hENsrnFrHXtOqO6e5SA6ZdJsbJmOXZxDq5ZOcLdZ6PgzeQVdnivhXQHA8g3gzQoNuhYo4E1UYNOdTYGS16EvMpOUTxfmhmLz1-hw9SPnIiIzOX9K83qEOptngC4ftezuMmw2cFusTrxrKMvbH8SUqKAiywnTuiyV4yunaolsqVwbR-4PyM6FO8usVBMFf49vNBSO0nh-cdb8imZPtqb4xGeGHHIu5mG7uMAKZaJVbXGpC2eZfjab3NGV9Z-fmSmrDdAmO44ew0Xf0ZIXu4UoJx8a7EfGQRwWl51g5ZF93J0HH'
};
export function HajjApiToken(){
    return 'IoOCQ3ObCcqj3BmtYVUBKTaCIWWoFnQcCorlRUsL-peMCb6m7dlEhXnSJhXXEo7Dh8bG7WQq18wbzMIvAKNk2RtIVKSBc3uUgZASa-0DZ0L5oiwJ9rSktbNb1dM3efA-b7BLH97ryRSj8vglisLUecscxtA1OFPF7kYWWaqDSKxovS9yKw4jBhUWwMrYT306oG2UZgmDpxP-zx6hENsrnFrHXtOqO6e5SA6ZdJsbJmOXZxDq5ZOcLdZ6PgzeQVdnivhXQHA8g3gzQoNuhYo4E1UYNOdTYGS16EvMpOUTxfmhmLz1-hw9SPnIiIzOX9K83qEOptngC4ftezuMmw2cFusTrxrKMvbH8SUqKAiywnTuiyV4yunaolsqVwbR-4PyM6FO8usVBMFf49vNBSO0nh-cdb8imZPtqb4xGeGHHIu5mG7uMAKZaJVbXGpC2eZfjab3NGV9Z-fmSmrDdAmO44ew0Xf0ZIXu4UoJx8a7EfGQRwWl51g5ZF93J0HH'
};


// d452152ef318121614eccb23
// b8d4b285d48d1e690388e999 new#
