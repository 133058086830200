import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMinus,
  faPlus,
  faLocationDot,
  faKaaba,
  faMosque
} from '@fortawesome/free-solid-svg-icons'
import ActivitySearch from './ActivitySearch'
import { ToastContainer, toast } from 'react-toastify'
import Loader from '../Loading/Loader'
import { DateRangePicker, SelectPicker } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import PackageSearch from './PackageSearch'
import { useTranslation } from 'react-i18next'
import FlightSearch from './FlightSearch'
import TransferNew from './TransferNew'
import { useNavigate } from 'react-router-dom'
import { countryListLocal } from '../Data/CountryList'
const { beforeToday } = DateRangePicker
function SearchBar () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  
  const [selectedCity, setSelectedCity] = useState('makkah')
  const [dateRangePicker, setDateRangePicker] = useState(null)
  const [childAgesError, setChildAgesError] = useState({})
  const [hotelView, setHotelView] = useState(null)
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [places, setPlace] = useState([])
  const [initialRender, setInitialRender] = useState(true)
  const [countryList, setCountryList] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedNationality, setSelectedNationality] = useState('')
  const [loading, setLoading] = useState(false)
  const [personData, setPersonData] = useState({
    adult: 2,
    children: 0,
    room: 1
  })
  const [placeDetail, setPlaceDetail] = useState({
    city: '',
    country: '',
    countrycode: '',
    lat: 0,
    long: 0,
    checkindate: '',
    Checkoutdate: ''
  })
  const [rooms, setRooms] = useState([
    // Initial room with default values
    {
      adults: 2,
      children: 0,
      childrenAges: []
    }
  ])

  const [showtours, setShowtours] = useState(false)
  const [Showhotels, setShowhotels] = useState(true)
  const [Showflights, setFlights] = useState(false)
  const [Showtransfers, setShowtransfers] = useState(false)
  const [ShowActivities, setShowActivities] = useState(false)
  const [activeItem, setActiveItem] = useState(2)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768) // You can adjust the breakpoint as needed
  const [adultModal, setAdultModal] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState({
    payment: false,
    booking: false,
    trackid: false
  })
  const [dateRange, setDateRange] = useState({
    checkindate: null,
    Checkoutdate: null
  })
  // State for input value

  useEffect(() => {
    getUserLocation()
    const countryOptions = countryListLocal.item.map(country => ({
      value: country.name.common,
      label: country.name.common,
      flag: country.flags.png,
      phoneCode: country.idd.root + country.idd.suffixes[0]
    }))
    setCountryList(countryOptions)
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768) // Update isMobile based on the screen width
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleChangeLocation = selectedOption => {
    setSelectedLocation(selectedOption)
  }
  useEffect(() => {
    const adults = rooms.reduce((acc, item) => acc + item.adults, 0)
    const children = rooms.reduce((acc, item) => acc + item.children, 0)
    const roomss = rooms.length
    setPersonData(prevPersonData => ({
      ...prevPersonData,
      adult: adults,
      children: children,
      room: roomss
    }))
  }, [rooms])

  function getUserLocation () {
    // Fetch user's location data
    fetch(
      'https://api.geoapify.com/v1/ipinfo?&apiKey=0708571ae58c4688824a7d01ca397041'
    )
      .then(response => response.json())
      .then(data => {
        setSelectedNationality(data.country.name)
        localStorage.setItem('UserCountry', data.country.name)
        // Handle location data as needed
      })
      .catch(error => {
        console.error('Error fetching user location:', error)
      })
  }
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false) // Update the initialRender state to false.
      return // Exit the effect early to avoid running the code below.
    }

    if (places.length !== 0) {
      // debugger
      if (
        places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['locality', 'political'])
        )[0]
      ) {
        const city1 = places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['locality', 'political'])
        )[0]?.short_name
        // console.log('city1=' + city1)
        setPlaceDetail(prevPlaceDetail => ({
          ...prevPlaceDetail,
          city: city1
        }))
      } else {
        const city1 = places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['administrative_area_level_2', 'political'])
        )[0]?.short_name
        // console.log('city1=' + city1)
        setPlaceDetail(prevPlaceDetail => ({
          ...prevPlaceDetail,
          city: city1
        }))
      }

      if (
        places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['locality', 'political'])
        )[0]
      ) {
        const displayCity1 = places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['locality', 'political'])
        )[0]?.long_name
        // console.log('displayCity1=' + displayCity1)
      } else {
        const displayCity1 = places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['administrative_area_level_2', 'political'])
        )[0]?.long_name
        // console.log('displayCity1=' + displayCity1)
      }

      if (
        places.address_components.filter(
          f =>
            JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
        )[0]
      ) {
        const country_code = places.address_components.filter(
          f =>
            JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
        )[0]?.short_name
        // console.log('country_code=' + country_code)
        setPlaceDetail(prevPlaceDetail => ({
          ...prevPlaceDetail,
          countrycode: country_code
        }))
      }
      if (
        places.address_components.filter(
          f =>
            JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
        )[0]
      ) {
        const country = places.address_components.filter(
          f =>
            JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
        )[0]?.long_name
        setPlaceDetail(prevPlaceDetail => ({
          ...prevPlaceDetail,
          country: country
        }))
      }
      var address = places.formatted_address
      var latitude = places.geometry.location.lat()
      setPlaceDetail(prevPlaceDetail => ({
        ...prevPlaceDetail,
        lat: latitude
      }))
      var longitude = places.geometry.location.lng()
      setPlaceDetail(prevPlaceDetail => ({
        ...prevPlaceDetail,
        long: longitude
      }))
      const latlng = new window.google.maps.LatLng(latitude, longitude)
      const geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({ latLng: latlng }, function (results, status) {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            const addressComponents = results[0].address_components
            const lastAddressComponent =
              addressComponents[addressComponents.length - 1]
            const country = lastAddressComponent.short_name
            const state =
              addressComponents[addressComponents.length - 3].long_name
            const city =
              addressComponents[addressComponents.length - 4].long_name
          }
        }
      })
    }
  }, [places, initialRender])
  const toggleModal = divNumber => {
    if (divNumber === 1) {
      setModalIsOpen({ payment: !modalIsOpen.payment })
    } else if (divNumber === 2) {
      setModalIsOpen({ booking: !modalIsOpen.booking })
    } else if (divNumber === 3) {
      setModalIsOpen({ trackid: !modalIsOpen.trackid })
    }
  }
  const handleDateChange = date => {
    setSelectedDate(date)
  }

  const handleCountryChange = selectedOption => {
    // debugger
    setSelectedCountry(selectedOption)
    setSelectedNationality(selectedOption.value) // Update the selected nationality when the user makes a selection
  }

  const toggleDiv = divNumber => {
    if (divNumber === 1) {
      setActiveItem(divNumber)
      setShowtransfers(false)
      setFlights(false)
      setShowtours(true)
      setShowActivities(false)
      setShowhotels(false) // Hide div2 when showing div1
    } else if (divNumber === 2) {
      setActiveItem(divNumber)
      setShowtransfers(false)
      setShowhotels(true)
      setFlights(false)
      setShowActivities(false)
      setShowtours(false) // Hide div1 when showing div2
    } else if (divNumber === 3) {
      setActiveItem(divNumber)
      setShowtransfers(true)
      setShowhotels(false)
      setShowActivities(false)
      setFlights(false)
      setShowtours(false) // Hide div1 when showing div2
    } else if (divNumber === 4) {
      setActiveItem(divNumber)
      setShowtransfers(false)
      setShowhotels(false)
      setShowActivities(false)
      setFlights(true)
      setShowtours(false) // Hide div1 when showing div2
    } else if (divNumber === 5) {
      setActiveItem(divNumber)
      setShowtransfers(false)
      setShowhotels(false)
      setShowActivities(true)
      setFlights(false)
      setShowtours(false) // Hide div1 when showing div2
    }
  }

  const handleDateRangeChange = value => {
    setDateRangePicker(value)
    if (value === null) {
      setPlaceDetail(prevPlaceDetail => ({
        ...prevPlaceDetail,
        checkindate: '',
        Checkoutdate: ''
      }))
      setDateRange(null)
    } else {
      setPlaceDetail(prevPlaceDetail => ({
        ...prevPlaceDetail,
        checkindate: moment(value[0]).format('YYYY-MM-DD'),
        Checkoutdate: moment(value[1]).format('YYYY-MM-DD')
      }))

      setDateRange(value)
    }
  }

  const toggleAdultModal = () => {
    let ageStatus = false
    let messages = {} // Store error messages for each room and child

    for (let i = 0; i < rooms.length; i++) {
      const { children, childrenAges } = rooms[i]

      if (children !== childrenAges.length) {
        for (let j = 0; j < children; j++) {
          if (!childrenAges[j]) {
            // Check if age is missing for a specific child
            messages[`room-${i}-child-${j}`] = `Please select child age.`
            ageStatus = true
          }
        }
      }
    }

    if (ageStatus) {
      setChildAgesError(messages) // Store messages for each specific child missing an age
      return
    }

    setChildAgesError({})
    setAdultModal(!adultModal)
  }

  const handleAdultIncrement = roomIndex => {
    // Increment the number of adults for a specific room
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].adults < 6) {
      updatedRooms[roomIndex].adults += 1
      setRooms(updatedRooms)
    }
  }

  const handleAdultDecrement = roomIndex => {
    // Decrement the number of adults for a specific room
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].adults > 1) {
      updatedRooms[roomIndex].adults -= 1
      setRooms(updatedRooms)
    }
  }

  const handleChildrenIncrement = roomIndex => {
    // Increment the number of children for a specific room, if less than 4
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].children < 4) {
      updatedRooms[roomIndex].children += 1
      setRooms(updatedRooms)
    }
  }

  const handleChildrenDecrement = roomIndex => {
    // Decrement the number of children for a specific room
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].children > 0) {
      updatedRooms[roomIndex].children -= 1
      updatedRooms[roomIndex].childrenAges.pop()
      setRooms(updatedRooms)
    }
  }

  const handleChildAgeChange = (roomIndex, childIndex, value) => {
    // Update the age of a specific child for a specific room
    const updatedRooms = [...rooms]
    updatedRooms[roomIndex].childrenAges[childIndex] = value
    setRooms(updatedRooms)
  }
  const addRoom = () => {
    // Add a new room with default values
    if (rooms.length < 9) {
      setRooms([...rooms, { adults: 2, children: 0, childrenAges: [] }])
    }
  }

  const removeLastRoom = () => {
    if (rooms.length > 1) {
      // Remove the last room by slicing the array
      setRooms(rooms.slice(0, -1))
    }
  }
  const handleDeleteRoomByIndex = roomIndex => {
    const updatedRooms = rooms.filter((room, index) => index !== roomIndex)
    setRooms(updatedRooms)
  }
  const childAgearray = [
    '1 Year',
    '2 Year',
    '3 Year',
    '4 Year',
    '5 Year',
    '6 Year',
    '7 Year',
    '8 Year',
    '9 Year',
    '10 Year'
  ]
  const Searchhotels = async () => {
    if (dateRange.Checkoutdate === null || dateRange.Checkoutdate === '') {
      toast.info('Please Select Hotel Stay.')
      return
    } else if (moment(placeDetail.checkindate).isSame(moment(), 'day')) {
      toast.info('Please Select a Future Check in Date.')
      return
    }
    setLoading(true)
    if (selectedLocation === '1') {
      localStorage.setItem('SearchLocation', 'All')
    } else {
      localStorage.setItem('SearchLocation', 'null')
    }
    var destination_name = ''
    var country = ''
    var lat = ''
    var lon = ''
    var pin = ''
    if (selectedLocation === '2') {
      destination_name = 'Makkah'
      country = 'Saudi Arabia'
      lat = 21.4240968
      lon = 39.81733639999999
      pin = 'SA'
    } else if (selectedLocation === '3') {
      destination_name = 'Madinah'
      country = 'Saudi Arabia'
      lat = 24.4672132
      lon = 39.6024496
      pin = 'SA'
    } else {
      if (selectedCity === 'makkah') {
        destination_name = 'Makkah'
        country = 'Saudi Arabia'
        lat = 21.4240968
        lon = 39.81733639999999
        pin = 'SA'
      } else {
        destination_name = 'Madinah'
        country = 'Saudi Arabia'
        lat = 24.4672132
        lon = 39.6024496
        pin = 'SA'
      }
    }
    const FormData = {
      destination_name: destination_name,
      country: country,
      lat: lat,
      long: lon,
      pin: pin,
      cityd: destination_name,
      country_code: pin,
      check_in: placeDetail.checkindate,
      check_out: placeDetail.Checkoutdate,
      slc_nationality: String(selectedNationality),
      ...rooms.reduce(
        (acc, item, index) => {
          acc.adult += item.adults
          acc.child += item.children
          acc.room += 1
          acc.Adults.push(item.adults)
          acc.children.push(item.children)

          // Dynamically create child ages for each room
          acc[`child_ages${index + 1}`] = item.childrenAges.map(age =>
            age ? parseInt(age, 10) : 2
          )

          acc.rooms_counter.push(acc.room)
          return acc
        },
        {
          adult: 0,
          child: 0,
          room: 0,
          Adults: [],
          children: [],
          rooms_counter: []
        }
      )
    }
    const queryString = new URLSearchParams(FormData).toString()
    navigate(`/hotel_listing?${queryString}`)
  }
  const HandleLocationChange = value => {
    setSelectedLocation(value)
  }

  const handleHotelView = value => {
    setHotelView(value)
  }
  const handlecitychange = event => {
    setSelectedCity(event.target.value)
  }
  const searchoption = [
    { label: 'Makkah', value: '2' },
    { label: 'Medina', value: '3' }
  ]

  return (
    <>
      <ToastContainer />
      <Modal isOpen={adultModal} centered={true} toggle={toggleAdultModal}>
        <ModalHeader toggle={toggleAdultModal}>Select Detail</ModalHeader>
        <ModalBody>
        <div className="p-3">
            {rooms.map((room, roomIndex) => (
              <div key={roomIndex}>
                <div
                  className="hotel-detail-border d-flex text-center fw-bold mb-2"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p className="fw-bold m-1">Room {roomIndex + 1}</p>
                  {roomIndex > 0 && ( // Check if roomIndex is greater than 0
                    <button
                      className="delete-room-button m-1"
                      onClick={() => handleDeleteRoomByIndex(roomIndex)}
                    >
                      Delete
                    </button>
                  )}
                </div>
                <div>
                  <div className="select-child">
                    <div class="f4878764f1">
                      <label class="a984a491d9 fw-bold" for="group_adults">
                        Adults
                      </label>
                    </div>
                    <div class="d-flex fff">
                      <button
                        className="adult-modal-btn"
                        name={`adults-${roomIndex}`}
                        onClick={() => handleAdultDecrement(roomIndex)}
                      >
                        <i class="fas fa-minus">
                          <FontAwesomeIcon size="sm" icon={faMinus} />
                        </i>
                      </button>
                      <span className="d723d73d5f fw-bold" id="input">
                        {room.adults}
                      </span>
                      <button
                        className="adult-modal-btn"
                        name={`adults-${roomIndex}`}
                        onClick={() => handleAdultIncrement(roomIndex)}
                        id="increment"
                      >
                        <i class="fas fa-plus">
                          <FontAwesomeIcon size="sm" icon={faPlus} />
                        </i>
                      </button>
                    </div>
                  </div>
                  <div className="select-child">
                    <div class="f4878764f1">
                      <label class="a984a491d9 fw-bold" for="group_adults">
                        Children
                      </label>
                    </div>
                    <div class="d-flex fff">
                      <button
                        className="adult-modal-btn"
                        onClick={() => handleChildrenDecrement(roomIndex)}
                        id="decrement"
                      >
                        <i class="fas fa-minus">
                          <FontAwesomeIcon size="sm" icon={faMinus} />
                        </i>
                      </button>
                      <span className="d723d73d5f fw-bold" id="input">
                        {room.children}
                      </span>
                      <button
                        className="adult-modal-btn"
                        onClick={() => handleChildrenIncrement(roomIndex)}
                        id="increment"
                      >
                        <i class="fas fa-plus">
                          <FontAwesomeIcon size="sm" icon={faPlus} />
                        </i>
                      </button>
                    </div>
                  </div>
                  <div
                    className="select-child"
                    style={{ justifyContent: "left" }}
                  >
                    {room.children > 0 && (
                      <div className="row w-100 ">
                        <p>
                          To find you a place to stay that fits your entire
                          group along with correct prices, we need to know how
                          old your child.
                        </p>
                        {Array.from(
                          { length: room.children },
                          (_, childIndex) => (
                            <div
                              key={childIndex}
                              className="m-1 "
                              style={{ width: "45%", alignItems: "center" }}
                            >
                              <label>Child {childIndex + 1} (Age)</label>
                              <select
                                class="form-select child-age-select"
                                name="child1"
                                value={room.childrenAges[childIndex]}
                                onChange={(e) =>
                                  handleChildAgeChange(
                                    roomIndex,
                                    childIndex,
                                    e.target.value
                                  )
                                }
                                aria-label="Default select example"
                              >
                                <option selected>Age needed</option>
                                {childAgearray.map((item, index) => (
                                  <option key={index} value={index + 1}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                              {childAgesError[
                                `room-${roomIndex}-child-${childIndex}`
                              ] && (
                                <p className="text-danger">
                                  {
                                    childAgesError[
                                      `room-${roomIndex}-child-${childIndex}`
                                    ]
                                  }
                                </p>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className='select-child'>
            <div class='f4878764f1 pe-4'>
              <label class='a984a491d9 fw-bold' for='group_adults'>
                Room
              </label>
            </div>
            <div class='d-flex fff'>
              <button
                className='adult-modal-btn'
                onClick={removeLastRoom}
                id='decrement'
              >
                <i class='fas fa-minus'>
                  <FontAwesomeIcon size='sm' icon={faMinus} />
                </i>
              </button>
              <span className='d723d73d5f fw-bold' id='input'>
                {rooms.length}
              </span>
              <button
                className='adult-modal-btn'
                onClick={addRoom}
                id='increment'
              >
                <i class='fas fa-plus'>
                  <FontAwesomeIcon size='sm' icon={faPlus} />
                </i>
              </button>
            </div>
          </div>
          <Button color='primary' onClick={toggleAdultModal}>
            Apply
          </Button>
        </ModalFooter>
      </Modal>
      {/***********  Model Windows ****************/}
      <Modal isOpen={modalIsOpen.payment} toggle={() => toggleModal(1)}>
        <ModalHeader toggle={() => toggleModal(1)}>Make Payment</ModalHeader>
        <ModalBody>
          <div className='form-group'>
            <label htmlFor=''>Enter Invoice No.</label>
            <input
              type='text'
              className='form-control select-styling mt-2'
              id=''
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={() => toggleModal(1)}>
            Close
          </Button>
          <button
            className=' btn btn-warning modal-btn1'
            onClick={() => toggleModal(1)}
          >
            Submit
          </button>
        </ModalFooter>
      </Modal>
      {/***********  Model Windows ****************/}
      <Modal isOpen={modalIsOpen.booking} toggle={() => toggleModal(2)}>
        <ModalHeader toggle={() => toggleModal(2)}>View Booking</ModalHeader>
        <ModalBody>
          <div className='form-group'>
            <label htmlFor=''>Enter Email.</label>
            <input
              type='email'
              className='form-control mt-2'
              id='exampleInputEmail1'
              aria-describedby='emailHelp'
              placeholder=''
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={() => toggleModal(2)}>
            Close
          </Button>
          <button
            className=' btn btn-warning modal-btn1'
            onClick={() => toggleModal(2)}
          >
            Submit
          </button>
        </ModalFooter>
      </Modal>
      {/***********  Model Windows ****************/}
      <Modal isOpen={modalIsOpen.trackid} toggle={() => toggleModal(3)}>
        <ModalHeader toggle={() => toggleModal(3)}>Track ID</ModalHeader>
        <ModalBody>
          <div className='form-group'>
            <label htmlFor=''>Enter Track ID.</label>
            <input
              type='text'
              className='form-control select-styling mt-2'
              id=''
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={() => toggleModal(3)}>
            Close
          </Button>
          <button className=' btn btn-warning modal-btn1'>Submit</button>
        </ModalFooter>
      </Modal>
      {loading ? (
        <Loader />
      ) : (
        <section className='booking_part'>
          <div className='row search-border'>
            <div className='col-lg-12 ps-0 pe-0'>
              <div className='booking_menu'>
                {/* <div className='search-menu-buttons'>
                  <div className='tab-btn-right'>
                    <button onClick={() => toggleModal(1)}
                            className='btn btn-info ms-2'
                            data-bs-toggle='modal'
                            data-bs-target='#exampleModalpayment'
                    >
                      {t('Make Payment')}
                    </button>
                    <button
                      onClick={() => toggleModal(2)}
                      className='btn btn-info ms-2'
                      data-bs-toggle='modal'
                      data-bs-target='#view_booking'
                    >
                      {t('View Booking')}
                    </button>
                    <button
                      onClick={() => toggleModal(3)}
                      className='btn btn-info ms-2'
                      data-bs-toggle='modal'
                      data-bs-target='#view_track_id_booking'
                    >
                      {t('Track ID')}
                    </button>
                  </div>
                </div> */}
                <div className='search-menu'>
                  <ul className='nav nav-tabs' id='myTab' role='tablist'>
                    {/* <li
                      className={`nav-item ${
                        activeItem === 4 ? 'active-search' : ''
                      }`}
                      onClick={() => toggleDiv(4)}
                    >
                      <a className='nav-link'>{t('Flights')}</a>
                    </li> */}
                    <li
                      className={`nav-item ${
                        activeItem === 2 ? 'active-search' : ''
                      }`}
                      onClick={() => toggleDiv(2)}
                    >
                      <a className='nav-link'>{t('Hotels')} </a>
                    </li>
                    {/* <li
                      className={`nav-item ${
                        activeItem === 1 ? 'active-search' : ''
                      }`}
                      onClick={() => toggleDiv(1)}
                    >
                      <a className='nav-link'> {t('Packages')} </a>
                    </li> */}
                    <li
                      className={`nav-item ${
                        activeItem === 3 ? 'active-search' : ''
                      }`}
                      onClick={() => toggleDiv(3)}
                    >
                      <a className='nav-link'>{t('Transfers')} </a>
                    </li>
                    {/* <li
                      className={`nav-item ${
                        activeItem === 5 ? 'active-search' : ''
                      }`}
                      onClick={() => toggleDiv(5)}
                    >
                      <a className='nav-link'>{t('Excursions')}</a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-lg-12 ps-0 pe-0'>
              <div className='booking_content'>
                <div className='tab-content'>
                  <div className='tab-pane fade show active'>
                    <div className='row check-availabilty'>
                      {showtours && <PackageSearch />}
                      {Showflights && <FlightSearch />}
                      {Showhotels && (
                        <div className='block-32'>
                          <div className='search-bg-color row home-hotel-search'>
                            <div className='col-md-6 set-padding  mb-lg-0  p-1 col-lg-3'>
                              <div className='field-icon-wrap'>
                                <span className='form-control-feedback'>
                                  <FontAwesomeIcon icon={faLocationDot} />{' '}
                                </span>
                                <SelectPicker
                                  class='form-select'
                                  data={searchoption}
                                  searchable={false}
                                  value={selectedLocation}
                                  style={{ width: '100%' }}
                                  onChange={HandleLocationChange}
                                  renderValue={value => {
                                    const selectedLocation = searchoption.find(
                                      option => option.value === value
                                    )
                                    return selectedLocation ? (
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          className='me-1'
                                          icon={
                                            selectedLocation.label === 'Makkah'
                                              ? faKaaba
                                              : faMosque
                                          }
                                        />
                                        {selectedLocation.label}
                                      </div>
                                    ) : (
                                      value
                                    )
                                  }}
                                  renderMenuItem={(label, item) => (
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        className='me-1'
                                        icon={
                                          label === 'Makkah' ? faKaaba : faMosque
                                        }
                                      />
                                      {label}{' '}
                                      {/* Use label parameter for the country name */}
                                    </div>
                                  )}
                                />
                              </div>
                            </div>
                            <div className='col-md-6 set-padding  mb-lg-0 col-lg-3  p-1'>
                              <div className='field-icon-wrap'>
                                <DateRangePicker
                                  onChange={handleDateRangeChange}
                                  format='dd-MM-yyyy'
                                  value={dateRange}
                                  showOneCalendar={isMobile}
                                  disabledDate={beforeToday()}
                                  className='w-100 text-left '
                                />
                              </div>
                            </div>
                            <div className='col-md-6 set-padding mb-lg-0 col-lg-4 p-1 '>
                              <div className='field-icon-wrap'>
                                <button
                                  onClick={toggleAdultModal}
                                  className='btn text-left btn-primary btn-block select-styling  search-btn1'
                                  style={{
                                    color: 'black',
                                    background: '#fff',
                                    border: 'none ',
                                    lineHeight: '1.9em'
                                  }}
                                >
                                  {personData.adult} Adults .{' '}
                                  {personData.children} Children .{' '}
                                  {personData.room} Room
                                </button>
                              </div>
                            </div>

                            <div className='col-md-6 set-padding col-lg-2 text-center  p-1  align-self-end'>
                              {/* <NavLink to='/hotels'> */}
                              <button
                                disabled={
                                  dateRangePicker === null ||
                                  selectedLocation === null
                                    ? true
                                    : false
                                }
                                style={{ lineHeight: '1.9em' }}
                                onClick={Searchhotels}
                                className='btn btn-primary btn-block select-styling search-btn1'
                              >
                                Search
                              </button>
                              {/* </NavLink> */}
                            </div>
                          </div>
                        </div>
                      )}
                      {Showtransfers && <TransferNew />}
                      {ShowActivities && <ActivitySearch />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default SearchBar
