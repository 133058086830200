import React, { useEffect, useState } from 'react'
import bgimage from '../../Images/Hotels/bg.jpg'
import axios from 'axios'
import moment from 'moment'
import payment from '../../Images/Packages/Logo/payment-img.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faLock } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import { Stripe } from 'stripe'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  ApiEndPoint,
  StripeClientSecret,
  Hotelapitoken
} from '../../Components/GlobalData/GlobalData'
import { ToastContainer, toast } from 'react-toastify'
import Layout from '../../Components/Layout/Layout'
import { countryListLocal } from '../../Components/Data/CountryList'
import BookingLoader from './BookingLoader'
import { Checkbox, Placeholder } from 'rsuite'
var StripePayment = 0
var StripeCurrency = ''
var ServiceCharges = 0
var Baseexhangeprice = 0
var Baseexhangerate = 0
var adminprice = 0
function BookRoom () {
  const token = Hotelapitoken()
  const baseurl = ApiEndPoint()
  const navigate = useNavigate()
  const todayDate = new Date()
  const BaseCurrRates = useSelector(state => state.currency.baseCurrRates)
  const SelectedCurrRates = useSelector(
    state => state.currency.selectedCurrRates
  )
  const hotelimg = useSelector(state => state.hotelDetail.hotelDetail.hotel_gallery[0])
  const ReduxSearchData = useSelector(state => state.hotelSearch.searchQuery)
  const HotelCheckout = useSelector(state => state.hotelCheckout.hotelCheckout)
  // console.log(HotelDetail);
  let Checkin = moment(HotelCheckout?.checkIn)
  let checkout = moment(HotelCheckout?.checkOut)
  let daysBetween = Math.abs(checkout.diff(Checkin, 'days'))
  var Secretkey = StripeClientSecret()
  const stripe = new Stripe(Secretkey)
  const elements = useElements()
  const stripeInstance = useStripe()
  const [error, setError] = useState(null)
  const [cardHolderName, setCardHolderName] = useState('')
  const [creditError, setCreditError] = useState('')
  const [savemarkup, setSavemarkup] = useState({
    totalroomprice: 0,
    totalmealprice: 0,
    admin: 0,
    client: 0,
    final: 0
  })

  const [gestinfo, setGestinfo] = useState({
    title: '',
    firstname: '',
    lastname: '',
    dateofbirth: '',
    numbercode: '',
    email: '',
    phonenumber: '',
    gender: '',
    pno: '',
    pexpiry: '',
    country: '',
    nationality: '',
    postalcode: ''
  })
  const [loaderLoading, setLoaderLoading] = useState(false)
  // const [selectedValue, setSelectedValue] = useState(
  //   HotelCheckout.hotel_provider !== "Custome_hotel" &&
  //     HotelCheckout.hotel_provider !== "custom_hotel_provider" &&
  //     new Date(
  //       HotelCheckout?.rooms_list[0].cancliation_policy_arr[0].from_date
  //     ) > todayDate
  //     ? "nopayment"
  //     : "cardpayment"
  // );
  const [selectedValue, setSelectedValue] = useState('cardpayment')
  const [message, setMessage] = useState(true)
  const [isBooking, setIsBooking] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [totalRoomsCount, setTotalRoomsCount] = useState(0)
  const [totalAdults, setTotalAdults] = useState(0)
  const [countryList, setCountryList] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [otherGuestData, setOtherGuestData] = useState(false)
  const [selectedNationality, setSelectedNationality] = useState('')
  const [selectedcurr, setSelectedCurr] = useState(
    localStorage.getItem('DefaultCurrency')
  )
  const [priceConversion, setPriceConversion] = useState(false)
  const [currencyRates, setCurrencyRates] = useState([])
  const [mealTypes, setMealTypes] = useState([])
  const [selectedMeals, setSelectedMeals] = useState([])
  const [mealTypesLoader, setMealTypesLoader] = useState(false)
  const adultCount = ReduxSearchData.adult
  const childCount = ReduxSearchData.child
  const [guests, setGuests] = useState(Array(adultCount).fill({}))
  const [childs, setChilds] = useState(Array(childCount).fill({}))
  const childsArray = Array.from({ length: childCount })
  const adultsArray = Array.from({ length: adultCount - 1 })

  useEffect(() => {
    const countryOptions = countryListLocal.item.map(country => ({
      value: country.name.common,
      label: country.name.common,
      flag: country.flags.png,
      phoneCode:
        country.cca3 === 'USA'
          ? country.idd.root
          : country.idd.root + country.idd.suffixes[0]
    }))
    var totalroom = HotelCheckout.rooms_list.reduce((sum, item) => {
      return sum + Number(item.selected_qty)
    }, 0)
    var totaladults = HotelCheckout.rooms_list.reduce((sum, item) => {
      return sum + Number(item.selected_qty) * item.adults
    }, 0)
    setTotalAdults(totaladults)
    setTotalRoomsCount(totalroom)
    var currdata = localStorage.getItem('AllHotelCurr')
    if (currdata !== 'null' && currdata !== null) {
      setPriceConversion(true)
      setCurrencyRates(currdata)
    }
    setCountryList(countryOptions)
    GetMealTypes()
    totalcount()
  }, [])

  const GetMealTypes = async () => {
    setMealTypesLoader(true)
    var data = {
      token: token,
      // hotel_Id:553,
      hotel_Id: HotelCheckout.hotel_id,
      start_Date: HotelCheckout?.checkIn,
      end_Date: HotelCheckout?.checkOut
    }
    try {
      const response = await axios.post(
        baseurl + '/api/get_Meal_Types_Custom_Hotel',
        data
      )
      // console.log(response);
      setMealTypesLoader(false)
      if (response.data.status === 'success') {
        setMealTypes(response.data.meal_Types)
      }
    } catch (error) {
      setMealTypesLoader(false)
      console.log(error)
    }
  }
  const totalcount = () => {
    let allroomsprice = 0
    let markupprice = 0
    let adminmarkupprice = 0
    let clientmarkupprice = 0
    let finalpricemarkup = 0
    allroomsprice = HotelCheckout.rooms_list.reduce(
      (sum, item) =>
        sum +
        Number(
          item.room_Promotions_Exist === '1'
            ? item.rooms_total_price_Promotion
            : item.rooms_total_price
        ),
      0
    )
    savemarkup.totalroomprice = allroomsprice
    finalpricemarkup = allroomsprice
    if (Number(HotelCheckout.admin_markup) !== 0) {
      if (HotelCheckout.admin_markup_type === 'Percentage') {
        markupprice = (allroomsprice * Number(HotelCheckout.admin_markup)) / 100
      } else {
        markupprice = Number(HotelCheckout.admin_markup)
      }
      adminmarkupprice = markupprice
      finalpricemarkup += markupprice
      savemarkup.admin = adminmarkupprice
      savemarkup.final = finalpricemarkup
    }
    if (Number(HotelCheckout.customer_markup) !== 0) {
      if (HotelCheckout.customer_markup_type === 'Percentage') {
        markupprice =
          (finalpricemarkup * Number(HotelCheckout.customer_markup)) / 100
      } else {
        markupprice = Number(HotelCheckout.customer_markup)
      }
      clientmarkupprice = markupprice
      finalpricemarkup += markupprice
      savemarkup.client = clientmarkupprice
      savemarkup.final = finalpricemarkup
    } else {
      savemarkup.final = allroomsprice
    }
  }
  const handlegestchange = e => {
    const { value, name } = e.target
    setGestinfo(prevgestinfo => ({ ...prevgestinfo, [name]: value }))
  }
  const handlePayment = async () => {
    if (cardHolderName === '') {
      setError('Enter Card Holder Name.')
      return { success: false }
    }
    const response = await stripe.paymentIntents.create({
      amount: (Number(StripePayment) * 100).toFixed(0), // Amount in cents
      currency: StripeCurrency,
      description: 'Live payment',
      payment_method_types: ['card']
    })
    var Client_Secret = response.client_secret

    try {
      // Confirm the payment intent with the payment method
      const { paymentIntent, error } = await stripeInstance.confirmCardPayment(
        Client_Secret,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: cardHolderName
            }
          }
        }
      )

      if (error) {
        setError(error.message)
        console.error(error)
        setLoaderLoading(false)
        setIsBooking(false)
        return { success: false, data: '' }
      } else {
        return { success: true, data: paymentIntent }
      }
    } catch (error) {
      console.error('Error:', error)
      setIsBooking(false)
      setLoaderLoading(false)

      return { success: false, data: '' }
    }
  }
  const handlecardnamechange = event => {
    setCardHolderName(event.target.value)
  }

  const calculateMarkup = price => {
    if (Object.keys(HotelCheckout).length !== 0) {
      let markupprice = 0
      let adminmarkupprice = 0
      let clientmarkupprice = 0
      let finalpricemarkup = Number(price)
      if (Number(HotelCheckout.admin_markup) !== 0) {
        if (HotelCheckout.admin_markup_type === 'Percentage') {
          markupprice = (price * Number(HotelCheckout.admin_markup)) / 100
        } else {
          markupprice = Number(HotelCheckout.admin_markup)
        }
        adminmarkupprice = markupprice
        finalpricemarkup += markupprice
      }
      if (Number(HotelCheckout.customer_markup) !== 0) {
        if (HotelCheckout.customer_markup_type === 'Percentage') {
          markupprice =
            (Number(price) * Number(HotelCheckout.customer_markup)) / 100
        } else {
          markupprice = Number(HotelCheckout.customer_markup)
        }
        clientmarkupprice = markupprice
        finalpricemarkup += markupprice
      }

      if (Number(finalpricemarkup) % 1 !== 0) {
        return Number(finalpricemarkup).toFixed(2)
      }
      return finalpricemarkup
    }
  }
  // const calculateServiceCharges = (price) => {
  //   var charges = (2.5 / 100) * price;
  //   if (Number(charges) % 1 !== 0) {
  //     return Number(charges).toFixed(2);
  //   }
  //   return charges;
  // };

  const handleCountryChange = selectedOption => {
    setSelectedCountry(selectedOption)
    setSelectedNationality(selectedOption.value)
    setGestinfo(prevdata => ({
      ...prevdata,
      phonenumber: selectedOption.phoneCode,
      numbercode: selectedOption.phoneCode,
      nationality: selectedOption.value
    }))
  }
  const ShowOtherGuestForm = () => {
    setOtherGuestData(!otherGuestData)
  }

  const otherGuestInfo = (e, guestIndex, digit) => {
    const selectedValue = e.target.value
    if (digit === 2) {
      setChilds(prevChilds => {
        const updatedChilds = [...prevChilds]
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          title: selectedValue
        }
        return updatedChilds
      })
    } else if (digit === 1) {
      setGuests(prevGuests => {
        const updatedGuests = [...prevGuests]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          other_title: selectedValue
        }
        return updatedGuests
      })
    }
  }
  const otherGuestFirstName = (e, guestIndex, digit) => {
    // Handle the first name input and update the state
    const firstName = e.target.value
    if (digit === 2) {
      setChilds(prevChilds => {
        const updatedChilds = [...prevChilds]
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          firstName: firstName
        }
        return updatedChilds
      })
    } else if (digit === 1) {
      setGuests(prevGuests => {
        const updatedGuests = [...prevGuests]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          other_first_name: firstName
        }
        return updatedGuests
      })
    }
  }
  const otherGuestLastName = (e, guestIndex, digit) => {
    // Handle the last name input and update the state
    const lastName = e.target.value
    if (digit === 2) {
      setChilds(prevChilds => {
        const updatedChilds = [...prevChilds]
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          lastName: lastName
        }
        return updatedChilds
      })
    } else if (digit === 1) {
      setGuests(prevGuests => {
        const updatedGuests = [...prevGuests]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          other_last_name: lastName
        }
        return updatedGuests
      })
    }
  }
  const otherGuestNationality = (e, guestIndex, digit) => {
    // Handle the last name input and update the state
    const newvalue = e.target.value
    if (digit === 2) {
      setChilds(prevChilds => {
        const updatedChilds = [...prevChilds]
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          nationality: newvalue
        }
        return updatedChilds
      })
    } else if (digit === 1) {
      setGuests(prevGuests => {
        const updatedGuests = [...prevGuests]
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          other_passport_country: newvalue
        }
        return updatedGuests
      })
    }
  }
  const handleCheckboxContinue = () => {
    setIsChecked(!isChecked)
  }
  function ValidEmail(email) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  }
  const confirmbooking = async () => {
    // console.log(selectedMeals)
    if (gestinfo.title === '') {
      toast.error('Please Select Lead Guest Title.')
      return
    } else if (gestinfo.firstname === '') {
      toast.error('Please Enter Lead Guest First Name.')
      return
    } else if (gestinfo.lastname === '') {
      toast.error('Please Enter Lead Guest Last Name.')
      return
    } else if (gestinfo.email === '') {
      toast.error('Please Enter Lead Guest Email.')
      return
    }else if (!ValidEmail(gestinfo.email)) {
      toast.error('Please Enter Valid Email.')
      return
    } else if (selectedNationality === '') {
      toast.error('Please Select Lead Guest Nationality.')
      return
    } else if (gestinfo.phonenumber.length < 5) {
      toast.error('Please Enter Phone Number.')
      return
    }
    if (isChecked) {
      setIsBooking(true)
      setLoaderLoading(true)
      var paymentmethod = 'slc_stripe'
      // var status = { success: false, data: '' }
        if(HotelCheckout?.rooms_list[0].request_type !=='1'){
          var status=await handlePayment();
          if(status.success===false){
            setLoaderLoading(false);
            setIsBooking(false);
            return;
          };
          setMessage(false);
      
        }else{
          var status = { success: false, data: '' }
        }
      var title = []
      var firstname = []
      var lastname = []
      var nationality = []
      if (guests.length !== 0) {
        guests.forEach(person => {
          if (person.other_title && person.other_first_name) {
            title.push(person.other_title)
            firstname.push(person.other_first_name)
            lastname.push(person.other_last_name)
            nationality.push(person.other_passport_country)
          }
        })
      }

      var Childtitle = []
      var Childfirstname = []
      var Childlastname = []
      var Childnationality = []
      if (childs.length !== 0) {
        childs.forEach(children => {
          if (children.title && children.firstName) {
            Childtitle.push(children.title)
            Childfirstname.push(children.firstName)
            Childlastname.push(children.lastName)
            Childnationality.push(children.nationality)
          }
        })
      }
      setAdminPrice(savemarkup.final)
      setBasePrice(savemarkup.final)
      const totalHotelPrice = HotelCheckout.rooms_list.reduce((sum, item) => {
        return sum + item.room_Promotions_Exist === '1'
          ? Number(item.rooms_total_price_Promotion)
          : Number(item.rooms_total_price)
      }, 0)
      var exchangehotelprice = 0
      var exchangeclientcomission = 0
      if (priceConversion) {
        exchangehotelprice = renderPrice(
          totalHotelPrice,
          HotelCheckout.currency
        )
        if (Number(savemarkup.client) !== 0 || savemarkup.client !== '') {
          exchangeclientcomission = renderPrice(
            Number(savemarkup.client),
            HotelCheckout.currency
          )
        }
      } else {
        exchangehotelprice = totalHotelPrice
        exchangeclientcomission = savemarkup.client
      }

      const jsonString = {
        lead_title: gestinfo.title,
        lead_first_name: gestinfo.firstname,
        lead_last_name: gestinfo.lastname,
        lead_email: gestinfo.email,
        lead_date_of_birth: gestinfo.dateofbirth,
        lead_country: selectedNationality,
        lead_phone: gestinfo.phonenumber,
        other_title: title,
        other_first_name: firstname,
        other_last_name: lastname,
        other_nationality: nationality,
        child_title: Childtitle,
        child_first_name: Childfirstname,
        child_last_name: Childlastname,
        child_nationality: Childnationality,
        slc_pyment_method: paymentmethod,
        name: 'on',
        base_exchange_rate: '1',
        base_currency: 'GBP',
        selected_exchange_rate: '1',
        exchange_price: StripePayment,
        admin_markup: HotelCheckout.admin_markup,
        client_markup: HotelCheckout.customer_markup,
        exchange_currency: StripeCurrency
      }
      const customersearch = {
        token: token,
        city_name: HotelCheckout.destinationName,
        destination: HotelCheckout.destinationName,
        room_searching: ReduxSearchData.room,
        child_searching: ReduxSearchData.child,
        adult_searching: ReduxSearchData.adult,
        adult_per_room: ReduxSearchData.adult,
        child_per_room: ReduxSearchData.children,
        country_nationality: ReduxSearchData.slc_nationality,
        check_in: ReduxSearchData.check_in,
        check_out: ReduxSearchData.check_out,
        request_all_data: JSON.stringify(ReduxSearchData)
      }
      const phpArray = {
        token: token,
        meal_Details: JSON.stringify(selectedMeals),
        // service_charges: ServiceCharges,
        lead_title: gestinfo.title,
        admin_exchange_currency: 'GBP',
        admin_exchange_rate: adminprice,
        admin_exchange_total_markup_price: adminprice,
        lead_first_name: gestinfo.firstname,
        lead_last_name: gestinfo.lastname,
        lead_email: gestinfo.email,
        lead_date_of_birth: gestinfo.dateofbirth,
        lead_country: selectedNationality,
        lead_phone: gestinfo.phonenumber,
        other_title: title,
        other_first_name: firstname,
        other_last_name: lastname,
        other_nationality: nationality,
        slc_pyment_method: paymentmethod,
        payment_details: JSON.stringify(status.data),
        base_exchange_rate: Baseexhangeprice,
        base_currency: 'GBP',
        selected_exchange_rate: StripePayment,
        selected_currency: StripeCurrency,
        exchange_price: StripePayment,
        admin_markup: HotelCheckout.admin_markup,
        client_markup: HotelCheckout.customer_markup,
        exchange_currency: StripeCurrency,
        request_data: JSON.stringify(jsonString),
        creditAmount: StripePayment,
        hotel_checkout_select: JSON.stringify(HotelCheckout),
        customer_search_data: JSON.stringify(customersearch),
        payable_price: totalHotelPrice,
        client_commission_amount: savemarkup.client,
        admin_commission_amount: savemarkup.admin,
        total_markup_price: Number(totalHotelPrice) + Number(savemarkup.client),
        currency: HotelCheckout.currency,
        exchange_payable_price: exchangehotelprice,
        exchange_client_commission_amount: exchangeclientcomission,
        exchange_total_markup_price:
          Number(exchangehotelprice) + Number(exchangeclientcomission),
        exchange_rate:
          StripeCurrency === HotelCheckout.currency ? 1 : Baseexhangerate,
        admin_exchange_amount: exchangehotelprice,
        exchange_admin_commission_amount: savemarkup.admin,
        admin_exchange_currency: StripeCurrency,
        admin_exchange_rate:
          StripeCurrency === HotelCheckout.currency ? 1 : Baseexhangerate,
        admin_exchange_total_markup_price:
          Number(exchangehotelprice) + Number(exchangeclientcomission)
      }
      // return;
      try {
        const response = await axios.post(
          baseurl + '/api/hotels/reservation_Live',
          phpArray,
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
              'Access-Control-Allow-Headers':
                'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            }
          }
        )
        if (response.data.message === 'Internal server error') {
          setLoaderLoading(false)
          setIsBooking(false)
          toast.error('Please Search Hotel Again.')
        } else if (response.data.message === 'Insufficient allotment') {
          setIsBooking(false)
          setLoaderLoading(false)
          toast.error('Insufficient allotment.')
        } else if (response.data.status === 'error') {
          setIsBooking(false)
          setLoaderLoading(false)
          toast.error(response.data.message)
        } else if (response.data.status === 'success') {
          // sendEmail(response.data.Invoice_data)
          // sendOwnerMessage(response.data.Invoice_data);
          setIsBooking(false)
          setLoaderLoading(false)
          navigate(`/hotel_voucher/${response.data.Invoice_id}`)
        }
      } catch (error) {
        setIsBooking(false)
        setLoaderLoading(false)
        console.error('Error:', error)
      }
    } else {
      toast.error('Please Agree with Terms and Conditions.')
    }
  }

  const setAdminPrice = price => {
    if (currencyRates.length === 0) {
      return
    }
    var currencyData = JSON.parse(currencyRates)
    if (BaseCurrRates !== null && StripeCurrency === 'GBP') {
      const adminrate = BaseCurrRates.conversion_rates['GBP'] // Use square brackets to access the property
      var admingbpprice = Number(adminrate) * Number(price)
      if (Number(admingbpprice) % 1 !== 0) {
        adminprice = Number(admingbpprice).toFixed(2)
      } else {
        adminprice = admingbpprice
      }
    } else if (currencyData.length !== 0 && BaseCurrRates !== null) {
      var filterroomcurrdata = currencyData.filter(
        item => item.base_code === HotelCheckout.currency
      )
      const gbpprice = filterroomcurrdata[0].conversion_rates['GBP'] // Use square brackets to access the property
      var baseprice = Number(gbpprice) * Number(price)
      if (Number(baseprice) % 1 !== 0) {
        adminprice = Number(baseprice).toFixed(2)
      } else {
        adminprice = baseprice
      }
    }
  }
  const setBasePrice = price => {
    if (currencyRates.length === 0) {
      return
    }
    var currencyData = JSON.parse(currencyRates)
    if (StripeCurrency === 'GBP') {
      if (Number(price) % 1 !== 0) {
        Baseexhangeprice = Number(price).toFixed(2)
      } else {
        Baseexhangeprice = price
      }
    } else if (currencyData.length !== 0 && BaseCurrRates !== null) {
      var filterroomcurrdata = currencyData.filter(
        item => item.base_code === HotelCheckout.currency
      )
      const gbpprice = filterroomcurrdata[0].conversion_rates['GBP'] // Use square brackets to access the property
      var baseprice = Number(gbpprice) * Number(price)
      if (Number(baseprice) % 1 !== 0) {
        Baseexhangeprice = Number(baseprice).toFixed(2)
      } else {
        Baseexhangeprice = baseprice
      }
    }
  }
  const renderPrice = (price, currency) => {
    var selectedcurrency = localStorage.getItem('selectedCurrency')
    var currencyData = JSON.parse(currencyRates)
    if (currencyData.length === 0 || currency === selectedcurrency) {
      if (Number(price) % 1 !== 0) {
        return Number(price).toFixed(2)
      }
      return Number(price)
    } else {
      var filterroomcurrdata = currencyData.filter(
        item => item.base_code === currency
      )
      const gbpprice = filterroomcurrdata[0].conversion_rates[selectedcurrency] // Use square brackets to access the property
      Baseexhangerate = gbpprice
      var baseprice = Number(gbpprice) * Number(price)
      if (Number(baseprice) % 1 !== 0) {
        return Number(baseprice).toFixed(2)
      }

      return baseprice
    }
  }

  const handleMealCheckboxChange = (mealId, mealPrice, name) => {
    setSelectedMeals(prev => {
      // Check if the meal is already selected
      const mealIndex = prev.findIndex(meal => meal.id === mealId)
      if (mealIndex >= 0) {
        // Meal is already selected, so remove it
        const newSelectedMeals = prev.filter(meal => meal.id !== mealId)
        updateRoomPrice(newSelectedMeals) // Update room price after removing meal
        return newSelectedMeals
      } else {
        // Add new meal with default quantity and totalPrice
        const newMeal = {
          id: mealId,
          quantity: totalAdults,
          name: name,
          totalPrice: Number(mealPrice) * Number(totalAdults) // Default total price with quantity 1
        }
        const newSelectedMeals = [...prev, newMeal]
        updateRoomPrice(newSelectedMeals) // Update room price after adding meal
        return newSelectedMeals
      }
    })
  }
  // const handleMealAdultCountChange = (mealId, mealPrice, quantity) => {
  //   setSelectedMeals((prev) => {
  //     const updatedMeals = prev.map((meal) =>
  //       meal.id === mealId
  //         ? {
  //             ...meal,
  //             quantity,
  //             totalPrice: Number(mealPrice) * Number(quantity),
  //           }
  //         : meal
  //     );
  //     updateRoomPrice(updatedMeals); // Update room price after changing quantity
  //     return updatedMeals;
  //   });
  // };
  const updateRoomPrice = mealist => {
    const totalPrice = mealist.reduce(
      (sum, meal) => sum + Number(meal.totalPrice),
      0
    )
    setSavemarkup(prev => ({
      ...prev,
      totalmealprice: totalPrice
    }))
  }
  // const BothServicesBooking= async()=>{

  // var token=FlightSearchToken();
  // var limit={
  //   'token':token
  // }
  // var limitcheck;
  // try {
  //   const response = await Axios.post(endpoint+'/api/flight_credit_limit_Live', limit, {
  //     headers: {
  //       "Access-Control-Allow-Origin": "*",
  //     } ,

  //   });
  //   limitcheck=response.data;
  //   // Handle the API response here

  // } catch (error) {
  //   // Handle errors here
  //   setIsBooking(false);
  //   console.error('Error:', error);
  // }

  // if(Number(flighttotal) > Number(limitcheck.credit_data.remaining_amount)){
  //   toast.info('There is a problem in the payment, Please contact Support or try again later on.', {
  //     position: toast.POSITION.TOP_RIGHT
  //   });
  //   setIsBooking(false);
  //   return;

  // }else{
  //   var status=await handlePayment();
  //       if(status.success===false){
  //         setIsBooking(false);
  //         return;
  //       };
  //       var adminmarkup='';
  //     var clientmarkup='';

  //     for(var i=0;i < FlightMarkup.markups.length;i++){
  //       if(FlightMarkup.markups[i].services_type==='flight'){
  //         if(FlightMarkup.markups[i].added_markup=='synchtravel'){
  //           adminmarkup=FlightMarkup.markups[i];
  //         }else if(FlightMarkup.markups[i].added_markup=='alhijaz'){
  //           clientmarkup=FlightMarkup.markups[i];
  //         }
  //       }
  //     };
  //   var leadpassengerdetail= {
  //     "title":gestinfo.title,
  //     "first_name": gestinfo.firstname,
  //     "last_name": gestinfo.lastname,
  //     "email": gestinfo.email,
  //     "date_of_birth":gestinfo.dateofbirth,
  //     "gender": gestinfo.gender,
  //     "passport_no": gestinfo.pno,
  //     "passport_expiry_date": gestinfo.pexpiry,
  //     "passport_country": gestinfo.country,
  //    "passenger_nationality_id": gestinfo.nationality,
  //     "passenger_nationality_code": gestinfo.numbercode,
  //     "passenger_phone_no": gestinfo.phonenumber,
  //     "postal_code":gestinfo.postalcode
  //   };

  //   const retrievedNumber = sessionStorage.getItem('15digitnumber');
  //   var token=FlightSearchToken();
  //   var farerequest={
  //     'token_authorization':token,
  // 'ConversationId':retrievedNumber,
  // 'FareSourceCode':CheckoutFlightData.PricedItineraries[0].AirItineraryPricingInfo.FareSourceCode,
  //   };
  //   const apitokenew=Hotelapitoken();
  //   const customersearch={
  //     "token":apitokenew,
  //     "city_name":HotelCheckout.destinationName,
  //     "destination": HotelCheckout.destinationName,
  //     "room_searching":homesearch.room,
  //     "child_searching": homesearch.child,
  //     "adult_searching": homesearch.adult,
  //     "adult_per_room": homesearch.adults,
  //     "child_per_room": homesearch.children,
  //     "country_nationality": homesearch.slc_nationality,
  //     "check_in": homesearch.check_in,
  //     "check_out": homesearch.check_out,
  //     "request_all_data":JSON.stringify(homesearch)
  //   };
  //   var adulttotal= Number(gbpPrices.adult)*Number(gbpPrices.adultqty);
  //   var childtotal=Number(gbpPrices.child)*Number(gbpPrices.childqty);
  //   var infanttotal=Number(gbpPrices.infant)*Number(gbpPrices.infantqty);
  //   var total=adulttotal+childtotal+infanttotal;
  //   var adminmarkupprice=0;
  //   var clientmarkupprice=0;

  //   FlightMarkup.markups.forEach((markup) => {
  //     if (markup.services_type === "flight" ) {
  //       if(markup.added_markup==="alhijaz"){

  //         if(markup.markup_type==='Percentage'){
  //           const markupValue = Number(markup.markup_value);
  //           const markupAmount = (Number(total) * markupValue) / 100;
  //           clientmarkupprice=markupAmount;
  //         }else {
  //           clientmarkupprice =Number(markup.markup_value);
  //         }

  //       }else if(markup.added_markup==="synchtravel"){

  //         if(markup.markup_type==='Percentage'){
  //           const markupValue = parseFloat(Number(markup.markup_value));
  //           const markupAmount = (Number(total) * markupValue) / 100;
  //           adminmarkupprice=markupAmount;
  //         }else {
  //           adminmarkupprice= Number(markup.markup_value);
  //         }

  //       }

  //     }
  //   });
  //   var data={
  //     'token_authorization':token,
  //     'ConversationId':retrievedNumber,
  //      'lead_passenger_details': JSON.stringify(leadpassengerdetail),
  //      'other_passenger_details': JSON.stringify(guests),
  //      'child_details': JSON.stringify(childs),
  //     'infant_details': JSON.stringify(infants),
  //     'extra_services_details':'',
  //     'other_extra_services_details':'',
  //     'child_extra_services_details':'',
  //     'revalidation_res':JSON.stringify({'Data':CheckoutFlightData}),
  //     'childs': IndexPageSearchData.child,
  //     'adults': IndexPageSearchData.adult,
  //     'infant': IndexPageSearchData.infant,
  //     'departure_date':IndexPageSearchData.DepartureDate,
  //     'search_rq':JSON.stringify( IndexPageSearchData),
  //     'search_rs': JSON.stringify({'Data':Search_response}),
  //     'farerules_rq': '',
  //     'farerules_rs':'',
  //     'revalidation_rq': JSON.stringify(farerequest),
  //     'revalidation_rs':  JSON.stringify({'Data':CheckoutFlightData}),
  //     'payment_details': JSON.stringify(status.data),
  //     'adult_price':Number(totalperson.adult)*AdultAmount,
  //     'child_price': Number(totalperson.child)*ChildAmount,
  //     'infant_price': Number(totalperson.infant)*InfantAmount,
  //     'total_price': ConverterTotalSum,
  //     'adult_price_markup': CalculateFLightMarkup(Number(totalperson.adult)*AdultAmount),
  //     'child_price_markup':CalculateFLightMarkup(Number(totalperson.child)*ChildAmount),
  //     'infant_price_markup': CalculateFLightMarkup(Number(totalperson.infant)*InfantAmount),
  //     'total_price_markup': MarkupSum,
  //     'client_commission_amount':exchangemarkup.client,
  //     'admin_commission_amount': exchangemarkup.admin,
  //     'currency': ConverterCurrency,
  //     'client_payable_price':Number(MarkupSum)-Number( exchangemarkup.client),
  //     'client_markup': clientmarkup.markup_value === undefined ? '' : clientmarkup.markup_value,
  //         'client_markup_type':clientmarkup.markup_type === undefined ? '' :  clientmarkup.markup_type,
  //         'client_commision_amount_exchange':clientmarkupprice,
  //         'client_without_markup_price': total,
  //         'client_markup_price': clientmarkupprice,
  //         'client_payable_price_exchange':  Number(total)+Number(clientmarkupprice),
  //         'client_currency': 'GBP',
  //         'admin_markup':adminmarkup.markup_value === undefined ? '' : adminmarkup.markup_value,
  //         'admin_markup_type':adminmarkup.markup_type === undefined ? '' : adminmarkup.markup_type,
  //         'admin_commision_amount_exchange': adminmarkupprice,
  //         'admin_without_markup_price': total,
  //         'admin_markup_price': adminmarkupprice,
  //         'admin_payable_price_exchange': Number(total)+Number(adminmarkupprice),
  //         'admin_currency': 'GBP',
  //     'creditAmount':MarkupSum,
  //     "hotel_checkout_select":JSON.stringify(HotelCheckout),
  //     "hotel_customer_search_data":JSON.stringify(customersearch),
  //   };

  //       try {
  //       const response = await Axios.post('https://api.synchtravel.com/api/combine_booking_apis_new',data , {
  //         headers: {
  //         // "Access-Control-Allow-Origin": "*",
  //           // Required for CORS support to work
  //           //"Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
  //           // "Access-Control-Allow-Headers":
  //           // "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
  //           // "Access-Control-Allow-Methods": "POST"
  //         } ,

  //       });
  //       setIsBooking(false);
  //         if(response.data.message==='success')
  //         {
  //             // navigation(`/Flight_invoice/${retrievedNumber}`, {
  //             //       state: {retrievedNumber}
  //             //     });
  //         }else{
  //           var data=JSON.parse( response.data.error);
  //           toast.info(data.Message, {
  //             position: toast.POSITION.TOP_RIGHT
  //           });
  //           return;

  //         }
  //       // navigate(`/hotel_detail/${id}`,{state:{index}})
  //     } catch (error) {
  //       // Handle errors here
  //       setIsBooking(false);

  //     console.log(error);
  //       console.error('Error:', error);
  //     }
  // }
  // };

  return (
    <>
      <Layout>
        {loaderLoading && <BookingLoader message={message} />}
        <ToastContainer />
        <div className='container'>
          <div className='row mt-2'>
            <div className='col-lg-8 margin-checkout'>
              <div class='row'>
                <div className='hotel-checkout-shadow p-3'>
                  <div className='row'>
                    <div>
                      <h2 class='checkout-title-visual-alignment'>
                        Guest Informations
                      </h2>
                    </div>

                    <div class='form-group mt-2 col-md-6'>
                      <label className='fw-bold'>Title</label>
                      <select
                        id='inputState'
                        name='title'
                        value={gestinfo.title}
                        onChange={handlegestchange}
                        class='form-control mt-2 form-select select-styling'
                      >
                        <option selected>Select Title</option>
                        <option value='MR'>Mr</option>
                        <option value='MRS'>Mrs</option>
                      </select>
                    </div>
                    <div class='form-group mt-2 col-md-6'>
                      <label className='fw-bold'>First Name</label>
                      <input
                        type='text'
                        class='form-control mt-2'
                        value={gestinfo.firstname}
                        name='firstname'
                        onChange={handlegestchange}
                        placeholder='First Name'
                      />
                    </div>
                    <div class='form-group mt-2 col-md-6'>
                      <label className='fw-bold'>Last Name</label>
                      <input
                        type='text'
                        class='form-control mt-2'
                        value={gestinfo.lastname}
                        name='lastname'
                        onChange={handlegestchange}
                        placeholder='Last Name'
                      />
                    </div>
                    <div class='form-group mt-2 col-md-6'>
                      <label className='fw-bold'>Email</label>
                      <input
                        type='email'
                        class='form-control mt-2'
                        value={gestinfo.email}
                        name='email'
                        onChange={handlegestchange}
                        placeholder='Email'
                      />
                    </div>
                    {/* <div class='form-group mt-4 row'>
                    <label for='inputEmail3' class='col-sm-2 col-4 col-form-label'>
                      Date Of Birth
                    </label>
                    <div class='col-sm-10 col-8'>
                      <input
                        type='date'
                        class='form-control'
                        value={gestinfo.dateofbirth}
                        name='dateofbirth'
                        onChange={handlegestchange}
                        placeholder='Email'
                      />
                    </div>
                  </div> */}
                    <div class='form-group field-icon-wrap mt-2 col-md-6'>
                      <label className='fw-bold'>Nationality</label>
                      <Select
                        options={countryList}
                        isSearchable={true}
                        className='mt-2'
                        onChange={handleCountryChange}
                        value={selectedCountry}
                        getOptionLabel={option => (
                          <div>
                            <img
                              src={option.flag}
                              alt={option.label}
                              style={{ width: '20px', marginRight: '8px' }}
                            />
                            {option.label} ({option.phoneCode})
                          </div>
                        )}
                        getOptionValue={option => option.value}
                      />
                    </div>
                    <div class='form-group mt-2 col-md-6'>
                      <label className='fw-bold'>Phone Number</label>
                      <input
                        type='text'
                        class='form-control mt-2'
                        value={gestinfo.phonenumber}
                        name='phonenumber'
                        onChange={handlegestchange}
                        placeholder='Phone Number'
                      />
                    </div>
                    {/* {storedData !==null && (
                    <>
                  <div class='form-group mt-2 col-md-6'>
                  <label className='fw-bold'>Gender</label>
                    <select id='inputState' name='gender' value={gestinfo.gender} onChange={handlegestchange} class='form-control form-select mt-2 select-styling'>
                    <option selected>Select Gender</option>
                            <option value='M'>Male</option>
                            <option value='F'>Female</option>
                    </select>
                  </div>
                  <div class='form-group mt-2 col-md-6'>
                  <label className='fw-bold'>Passport Number</label>
                    <input
                      type='text'
                      class='form-control mt-2'
                      value={gestinfo.pno}
                      name='pno'
                      onChange={handlegestchange}
                      placeholder='Passport Number'
                    />
                  </div>
                  <div class='form-group mt-2 col-md-6'>
                    <label className='fw-bold'>Passport Expiry</label>
                    <input
                      type='date'
                      value={gestinfo.pexpiry}
                      onChange={handlegestchange}
                      class='form-control mt-2'
                      name='pexpiry'
                    />
                  </div>
                  <div class='form-group mt-2 col-md-6'>
                    <label className='fw-bold'>Country</label>
                    <Select
                                        options={countryList}
                                        isSearchable={true}
                                        className="mt-2"
                                        onChange={handleCountryChangeother}
                                        value={selectedCountryother}
                                        getOptionLabel={(option) => (
                                            <div>
                                            <img
                                                src={option.flag}
                                                alt={option.label}
                                                style={{ width: '20px', marginRight: '8px' }}
                                            />
                                            {option.label} ({option.phoneCode})
                                            </div>
                                        )}
                                        getOptionValue={(option) => option.value}
                                        />
                  </div>
                  <div class='form-group mt-2 col-md-6'>
                  <label className='fw-bold'>Pstal Code</label>
                    <input
                      type='number'
                      class='form-control mt-2'
                      value={gestinfo.postalcode}
                      name='postalcode'
                      onChange={handlegestchange}
                      placeholder='Postal Code'
                    />
                  </div>
                  </>
                  )} */}
                  </div>
                </div>
              </div>
              {(adultCount - 1 !== 0 || childCount !== 0) && (
                <div class='row'>
                  <div className='hotel-checkout-shadow mt-4 p-3'>
                    <div className='d-flex justify-content-between'>
                      <h4>Other Guest Information (Optional)</h4>
                      <span
                        onClick={ShowOtherGuestForm}
                        style={{ cursor: 'pointer' }}
                        className='mt-auto mb-auto'
                      >
                        <FontAwesomeIcon icon={faAngleDown} />
                      </span>
                    </div>
                    {otherGuestData && (
                      <div class='card-body p-4'>
                        <div className='row'>
                          <div>
                            {adultsArray.map((_, index) => (
                              <div key={index} className='row pt-2'>
                                <h5 className='mb-2'>Guest #{index + 2}</h5>
                                <div className='col-md-4 mt-2'>
                                  <label className='fw-bold'>Title</label>
                                  <select
                                    value={guests.title}
                                    id={`inputState_${index}`}
                                    name='title'
                                    onChange={e => otherGuestInfo(e, index, 1)}
                                    class='form-control mt-2 form-select select-styling'
                                  >
                                    <option selected>Select Title</option>
                                    <option value='MR'>Mr.</option>
                                    <option value='MRS'>Mrs.</option>
                                  </select>
                                </div>
                                <div className='col-md-4 mt-2'>
                                  <label className='fw-bold'>First Name</label>
                                  <input
                                    type='text'
                                    class='form-control mt-2'
                                    value={guests.firstname}
                                    name='firstname'
                                    placeholder='First Name'
                                    onChange={e =>
                                      otherGuestFirstName(e, index, 1)
                                    }
                                  />
                                </div>
                                <div className='col-md-4 mt-2'>
                                  <label className='fw-bold'>Last Name</label>
                                  <input
                                    type='text'
                                    class='form-control mt-2'
                                    value={guests.lastname}
                                    name='lastname'
                                    placeholder='Last Name'
                                    onChange={e =>
                                      otherGuestLastName(e, index, 1)
                                    }
                                  />
                                </div>
                              </div>
                            ))}
                            {childsArray.map((_, index) => (
                              <div key={index} className='row pt-2'>
                                <h5 className='mb-2'>Child #{index + 1}</h5>
                                <div className='col-md-4 mt-2'>
                                  <select
                                    value={guests.title}
                                    id={`inputState_${index}`}
                                    name='title'
                                    onChange={e => otherGuestInfo(e, index, 2)}
                                    class='form-control form-select select-styling'
                                  >
                                    <option selected>Select Title</option>
                                    <option value='MSTR'>Mr.</option>
                                    <option value='MSTRS'>Mrs.</option>
                                  </select>
                                </div>
                                <div className='col-md-4 mt-2'>
                                  <input
                                    type='text'
                                    class='form-control'
                                    name='firstname'
                                    placeholder='First Name'
                                    onChange={e =>
                                      otherGuestFirstName(e, index, 2)
                                    }
                                  />
                                </div>
                                <div className='col-md-4 mt-2'>
                                  <input
                                    type='text'
                                    class='form-control'
                                    name='firstname'
                                    placeholder='Last Name'
                                    onChange={e =>
                                      otherGuestLastName(e, index, 2)
                                    }
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <article
                id='complete'
                class='row segment mt-2 no-target review-and-book checkout-hotel-detail'
              >
                <div id='fxmp' class='hidden'>
                  <p class='fx-header'>
                    <strong>
                      The following currencies are available for your booking:
                    </strong>
                  </p>
                  <fieldset class='fx-fields'>
                    <label class='check'>
                      <input
                        type='radio'
                        class='fx-poscurrency gb-whitelist'
                        name='fx_selected'
                        value='false'
                        data-cko-change='trackFX'
                      />
                      <span class='inline-label fx-label'>
                        Pay in <span class='fx-code fx-poscurrency-code'></span>{' '}
                        - <span class='fx-value fx-poscurrency-value'></span>
                      </span>
                    </label>
                    <label class='check'>
                      <input
                        type='radio'
                        class='fx-cccurrency gb-whitelist'
                        name='fx_selected'
                        value='true'
                        checked='checked'
                        data-cko-change='trackFX'
                      />
                      <span class='inline-label fx-label'>
                        Pay in <span class='fx-code fx-cccurrency-code'></span>{' '}
                        - <span class='fx-value fx-cccurrency-value'></span>{' '}
                      </span>
                    </label>
                  </fieldset>
                </div>
                {HotelCheckout?.rooms_list[0].request_type === '1' && (
                  <div
                    id='important-information'
                    class='important-information-rr-cancellation'
                  >
                    <h2 class='checkout-title-visual-alignment'>
                      Important information{' '}
                    </h2>
                    <div class='rr-cancellation-div'>
                      <ul
                        class='rr-bullets rules-list'
                        style={{ listStyle: 'circle', marginLeft: '20px' }}
                      >
                        <li>
                          Room on Request means we will check availability and
                          confirm your booking as soon as possible.{' '}
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                <div id='booking-summary'>
                {HotelCheckout?.hotel_provider === "Custome_hotel" &&(
              <>
              {mealTypes.length !==0 && (
            <div>
              <h5 className="mt-3">Additional Meal</h5>
              {mealTypesLoader ? (
                <div>
                  <Placeholder.Paragraph rows={4} />
                </div>
              ) : (
                <table class="table mt-2">
                  <thead>
                    <tr className="meal-table-heading ">
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Price</th>
                      <th scope="col">Adult</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mealTypes.map((item, index) => {
                      const selectedMeal = selectedMeals.find((meal) => meal.id === item.id);
                      const quantity = totalAdults;
                      const totalPrice = Number(item.meal_Price) * Number(totalAdults);
                      var totalmealprice=0
                      if (Number(totalPrice) % 1 !== 0) {
                        totalmealprice = Number(totalPrice).toFixed(2);
                      } else {
                        totalmealprice = totalPrice;
                      }
                      return(
                      <tr key={index} >
                        <th>
                          <Checkbox
                           checked={!!selectedMeal}
                            onChange={() =>
                              handleMealCheckboxChange(item.id, item.meal_Price,item.meal_Name)
                            }
                          />
                        </th>
                        <td className="align-middle">{item.meal_Name}</td>
                        <td className="align-middle">
                        {priceConversion == false
                      ? HotelCheckout.currency
                      : Object.keys(SelectedCurrRates).length === 0
                      ? BaseCurrRates.base_code
                      : SelectedCurrRates.base_code}{" "}
                          {priceConversion == false
                      ? Number(item.meal_Price)
                      : renderPrice(Number(item.meal_Price), HotelCheckout.currency)}
                          </td>
                        <td className="align-middle">
                          {totalAdults}
                        </td>
                        <td className="align-middle">
                        {priceConversion == false
                      ? HotelCheckout.currency
                      : Object.keys(SelectedCurrRates).length === 0
                      ? BaseCurrRates.base_code
                      : SelectedCurrRates.base_code}{" "}
                           {priceConversion == false
                      ? Number(totalmealprice)
                      : renderPrice(Number(totalPrice), HotelCheckout.currency)}
                          </td>
                      </tr>
                    )})}
                  </tbody>
                </table>
              )}   
            </div>
             )}
              </>
            )}
                  <hr class='booking-summary-separator' aria-hidden='true' />
                  <div class='duration'>
                    <div class='duration-check-in'>
                      <span class='check-in'>Check-in:</span>
                      <span class='date-range'>
                        {moment(HotelCheckout?.checkIn).format('DD-MM-YYYY')}
                      </span>
                    </div>
                    <div class='duration-check-out'>
                      <span class='check-out'>Check-out:</span>
                      <span class='date-range total-night-stay-desktop'>
                        {moment(HotelCheckout?.checkOut).format('DD-MM-YYYY')} (
                        {daysBetween}-night stay)
                      </span>
                    </div>
                  </div>
                  <hr class='booking-summary-separator' aria-hidden='true' />
                </div>

                {HotelCheckout?.rooms_list[0].request_type !== '1' && (
                  <div class='row mt-4'>
                    <div className='p-3'>
                      <div className='row payment'>
                        <div>
                          <h4 class='checkout-title-visual-alignment'>
                            Payment Method
                          </h4>
                        </div>
                        <div class='section-tab check-mark-tab text-center mt-3 pb-4'>
                          <ul class='nav' id='myTab' role='tablist'>
                            <li class='nav-item'>
                              <i class='la la-check icon-element'></i>
                              <img src={payment} alt='' />
                            </li>
                          </ul>
                        </div>
                        <div class='form-group mt-2 col-md-6'>
                          <label htmlFor='cardNumber' className='form-label'>
                            Card Holder Name
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            name='name'
                            value={cardHolderName}
                            onChange={handlecardnamechange}
                            placeholder='Name'
                          />
                        </div>
                        <div class='form-group mt-2 col-md-6'>
                          <label htmlFor='cardNumber' className='form-label'>
                            Card number
                          </label>
                          <CardNumberElement
                            className='form-control'
                            id='cardNumber'
                            placeholder='0000 0000 0000 0000'
                          />
                        </div>
                        <div class='form-group mt-2 col-md-6'>
                          <label htmlFor='expiryDate' className='form-label'>
                            Expiration date
                          </label>
                          <CardExpiryElement
                            className='form-control'
                            id='expiryDate'
                          />
                        </div>
                        <div class='form-group mt-2 col-md-6'>
                          <label htmlFor='cvc' className='form-label'>
                            CVC
                          </label>
                          <CardCvcElement
                            className='form-control'
                            id='cvc'
                            style={{ width: '40%' }}
                          />
                        </div>
                        {error && (
                          <div
                            style={{ color: 'red' }}
                            className='error mt-2'
                            role='alert'
                          >
                            {error}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div class='col-12 mt-3'>
                  <div class='form-check'>
                    <input
                      class='form-check-input'
                      type='checkbox'
                      checked={isChecked} // Set the checked state
                      onChange={handleCheckboxContinue}
                      id='invalidCheck'
                      required
                    />
                    <label class='form-check-label' for='invalidCheck'>
                      By continuing, you agree to the{' '}
                      <span style={{ color: 'red' }}>
                        {' '}
                        Terms and conditions
                      </span>
                    </label>
                  </div>
                </div>
                <button
                  type='button'
                  disabled={isBooking}
                  onClick={confirmbooking}
                  class='btn-primary btn-action complete-booking-cta booking-button'
                >
                  <span class='btn-label'>
                    {' '}
                    {isBooking ? 'Please Wait....' : 'Complete Booking'}
                  </span>
                </button>
                <div class='security-message' aria-hidden='true'>
                  <span class='icon icon-lock' aria-hidden='true'>
                    <FontAwesomeIcon icon={faLock} />
                  </span>
                  <span class='text1'>
                    {' '}
                    We use secure transmission and encrypted storage to protect
                    your personal information.
                  </span>
                  <span class='page-2 visa-mandate-message text1'>
                    Payments are processed in the U.S. except where the travel
                    provider (hotel / airline etc) processes your payment
                    outside the U.S., in which case your card issuer may charge
                    a foreign transaction fee.
                  </span>
                </div>
              </article>
            </div>
            <div className='col-lg-4 order-first order-md-last'>
              <div
                id='secondary-content'
                class='col secondary-content '
                role='complementary'
              >
                <div class='sticky-anchor'></div>
                <div>
                  <section>
                    <article
                      id='trip-summary'
                      class='trip-summary cf checkout-hotel-detail'
                    >
                      <h2 class='trip-summary-header visuallyhidden'>
                        Trip Summary
                      </h2>
                      <div class='hotel-details-wrapper two-column'>
                        <div
                          id='hotel-details-for-optimized-review'
                          class='hotel-details responsive-hotel-details'
                        >
                          <div class='full-hotel-image-overlay image-padding-for-mobile'>
                            <figure class="image aspect-ratio16-9 fbimage" data-media-type="image" data-lazy="true" data-src="https://images.trvl-media.com/lodging/28000000/27120000/27113200/27113139/66ed86c2_cko.jpg" data-aspect-ratio="16-9" data-class="tile-media">
                              <img alt="" class="tile-media"  src={hotelimg} /><noscript>
                              <img  src={hotelimg} alt="" class="tile-media" /></noscript>
                              <span class="media-loader"><span class=" loader-primary loader-light loader-static loading" aria-hidden="true" title="Loading..."></span></span>
                              <div class="img-dark-overlay">
                                <div style={{width:'100%'}} class="hotel-description content-overlay decreased-height  bottom">
                                  <h3 class="hotel-name"><span style={{background:' #9c2243'}} class="unlocalized-hotel-name p-1"><span class="hotel-summary-name">{HotelCheckout?.hotel_name}</span></span></h3>
                                </div>
                              </div>
                            </figure>
                          </div>
                        </div>
                        <div class='booking-summary display-optimized-hotel-review'>
                          <div class='expedia-extras'>
                            <div id='TH_IL'></div>
                          </div>

                          <ul class='list-items  list-items-2 py-2'>
                            <li>
                              <span>Check In:</span>
                              {moment(HotelCheckout?.checkIn).format(
                                'DD-MM-YYYY'
                              )}
                            </li>
                            <li>
                              <span>Check Out:</span>
                              {moment(HotelCheckout?.checkOut).format(
                                'DD-MM-YYYY'
                              )}
                            </li>
                          </ul>
                          {HotelCheckout?.rooms_list.map((item, index) => (
                            <div key={index}>
                              <div className='border-line'></div>
                              {item.request_type === '1' && (
                                <div className=' mt-2 room-request'>
                                  <h6>Room on Request</h6>
                                </div>
                              )}
                              <ul class='list-items list-items-2 py-3'>
                                <li>
                                  <span>Room Name:</span>
                                  {item.room_name}
                                </li>
                                <li>
                                  <span>Meal Type:</span>
                                  {item.board_id}
                                </li>
                                <li>
                                  <span>Adults:</span>
                                  {item.adults}
                                </li>
                                <li>
                                  <span> Children :</span>
                                  {item.childs}
                                </li>

                                <li>
                                  <span>Stay:</span>
                                  {daysBetween} {Number(daysBetween)===1?'Night':'Nights'}{' '}
                                </li>
                                <li>
                                  <span>Room:</span>
                                  {item.selected_qty} {Number(item.selected_qty)===1?'Room':'Rooms'}
                                </li>
                                <li>
                                  <span>Room Price:</span>
                                  {priceConversion == false
                                    ? HotelCheckout.currency
                                    : Object.keys(SelectedCurrRates).length ===
                                      0
                                    ? BaseCurrRates.base_code
                                    : SelectedCurrRates.base_code}{' '}
                                  {priceConversion == false
                                    ? Number(
                                        calculateMarkup(
                                          item.room_Promotions_Exist === '1'
                                            ? item.rooms_total_price_Promotion
                                            : item.rooms_total_price
                                        )
                                      )
                                    : renderPrice(
                                        Number(
                                          calculateMarkup(
                                            item.rooms_total_price
                                          )
                                        ),
                                        HotelCheckout.currency
                                      )}
                                </li>
                              </ul>
                            </div>
                          ))}
                        </div>
                      </div>
                    </article>
                    <article>
                    </article>
                    <article>
                      <h2 class='price-summary-header visuallyhidden'>
                        Price summary
                      </h2>
                      <section class='segmented-list price-summary checkout-hotel-detail'>
                        <article
                          id='price-summary-title'
                          class='segment no-target'
                        >
                          <div class='price-summary-title'>Price details</div>
                        </article>

                        <article id='price-summary' class='segment no-target'>
                          <div
                            id='newPriceDisplayEnabled'
                            class='pricing-details-wrapper two-column'
                          >
                            <div
                              id='details-content'
                              className='tour_booking_amount_area'
                            >
                              <div class='total-without-pwp'>
                                <ul class='list-items list-items-2 '>
                                  <li>
                                    <span>Sub Total:</span>
                                    {priceConversion == false
                                      ? HotelCheckout.currency
                                      : Object.keys(SelectedCurrRates)
                                          .length === 0
                                      ? BaseCurrRates.base_code
                                      : SelectedCurrRates.base_code}{' '}
                                    {priceConversion == false
                                      ? Number(savemarkup.final).toFixed(2)
                                      : renderPrice(
                                          Number(savemarkup.final),
                                          HotelCheckout.currency
                                        )}
                                  </li>
                                  {selectedMeals.length !== 0 && (
                                    <li>
                                      <span>Additional Meal:</span>
                                      {priceConversion == false
                                        ? HotelCheckout.currency
                                        : Object.keys(SelectedCurrRates)
                                            .length === 0
                                        ? BaseCurrRates.base_code
                                        : SelectedCurrRates.base_code}{' '}
                                      {priceConversion == false
                                        ? savemarkup.totalmealprice
                                        : renderPrice(
                                            savemarkup.totalmealprice,
                                            HotelCheckout.currency
                                          )}
                                    </li>
                                  )}
                                  <li className='fw-bold'>
                                    <span>Total Price:</span>
                                    {priceConversion == false
                                      ? (StripeCurrency =
                                          HotelCheckout.currency)
                                      : (StripeCurrency =
                                          Object.keys(SelectedCurrRates)
                                            .length === 0
                                            ? BaseCurrRates.base_code
                                            : SelectedCurrRates.base_code)}{' '}
                                    {priceConversion == false
                                      ? (StripePayment = (
                                          Number(savemarkup.final) +
                                          Number(savemarkup.totalmealprice)
                                        ).toFixed(2))
                                      : (StripePayment = Number(
                                          renderPrice(
                                            Number(savemarkup.final) +
                                              Number(savemarkup.totalmealprice),
                                            HotelCheckout.currency
                                          )
                                        ).toFixed(2))}
                                  </li>
                                </ul>
                                {/* <ul class='list-items list-items-2 '>
                                  {storedData ===null ? (
                                    <>
                                    {showPrice?(
                                       <>
                                    <li>
                                    <span>Sub Total:</span> {CurrencyRates===undefined ? (baseCName):(CurrencyRates.selectedcurrency)} {Number(renderPrice(savemarkup.final,HotelCheckout.currency))}
                                  </li>
                                   <li><span>Service Charges:</span>{CurrencyRates===undefined ? (baseCName):(CurrencyRates.selectedcurrency)} {serviceCharges=renderPrice(((2/100)*savemarkup.final),HotelCheckout.currency)}</li>
                                   </>
                                  ):(
                                    <>
                                    <li><span>Sub Total:</span>{HotelCheckout.currency} {savemarkup.final.toFixed(2)}</li>
                                    <li><span>Service Charges:</span>{HotelCheckout.currency} {serviceCharges=((2/100)*savemarkup.final).toFixed(2)}</li>
                                    </>
                                  )}
                                  {showPrice ?(
                                    <li className='fw-bold'>
                                    <span>Total Price:</span>{CurrencyRates===undefined ? (StripeCurrency=baseCName):(StripeCurrency= CurrencyRates.selectedcurrency)} {StripePayment=((Number(renderPrice(savemarkup.final,HotelCheckout.currency)))+Number(serviceCharges)).toFixed(2)}
                                  </li>
                                  ):(
                                    <li className='fw-bold'>
                                    <span>Total Price:</span>{StripeCurrency=HotelCheckout.currency} {StripePayment=(savemarkup.final+Number(serviceCharges)).toFixed(2)}
                                  </li>
                                  )}
                                    </>
                                  ):(
                                  <>
                                    <li><span>Sub Total:</span>{ConverterCurrency} {Number(hotaltotal)+Number(flighttotal)}</li>
                                    <li className='fw-bold'><span>Total Price:</span>{StripeCurrency=ConverterCurrency} {StripePayment=(Number(hotaltotal)+Number(flighttotal)+Number(serviceCharges)).toFixed(2)}</li>
                                  </>
                                  )}
                                </ul> */}
                                <div className='text-center'>
                                  <small className='text-center'>
                                    Vat and Taxes included
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </article>
                      </section>
                    </article>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default BookRoom
