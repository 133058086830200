export const setSearchQuery = (query) => ({
    type: 'SET_SEARCH_QUERY',
    payload: query,
  });
  
  export const setSearchResults = (results) => ({
    type: 'SET_SEARCH_RESULTS',
    payload: results,
  });

  export const setHotels = (hotels) => ({
    type: 'SET_HOTELS',
    payload: hotels,
  });
    
  export const setHotelDetail = (hotelDetail) => ({
    type: 'SET_HOTEL_DETAIL',
    payload: hotelDetail,
  });
  
  export const setHotelCheckout = (hotelCheckout) => ({
    type: 'SET_HOTEL_CHECKOUT',
    payload: hotelCheckout,
  });
  
// Currency 

  export const setSelectedCurr = (query) => ({
    type: 'SET_SELECTED_CURR',
    payload: query,
  });
  
  export const setBaseCurr = (results) => ({
    type: 'SET_BASE_CURR',
    payload: results,
  });
  export const setTransferList = (transferlist) => ({
    type: 'SET_TRANSFER_LIST',
    payload: transferlist,
  });
 