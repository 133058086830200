import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hotelSearchReducer from './Redux/hotelSearchReducer';
import hotelListingReducer from './Redux/hotelListingReducer';
import hotelDetailReducer from './Redux/hotelDetailReducer';
import hotelCheckoutReducer from './Redux/hotelCheckoutReducer';
import currencyReducer from './Redux/currencyReducer';
import transferReducer from './Redux/transferReducer';
// Combine reducers
const rootReducer = combineReducers({
  hotelSearch: hotelSearchReducer,
  hotelListing: hotelListingReducer,
  hotelDetail: hotelDetailReducer,
  hotelCheckout: hotelCheckoutReducer,
  currency:currencyReducer,
  transferList:transferReducer
});
const persistConfig = {
  key: 'root', // root key in local storage
  storage,
};

// Create store
const persistedReducer = persistReducer(persistConfig, rootReducer);
  
  const store = createStore(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
  const persistor = persistStore(store);
  
  export { store, persistor };
