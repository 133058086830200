import React, { useState, useEffect } from 'react'
import img1 from '../../Images/Home/imagination1.png'
import img2 from '../../Images/Home/imagination2.png'
import img3 from '../../Images/Home/imagination3.png'
import m_app from '../../Images/Pages/mobile-app.png'
import bigimage from '../../Images/Home/big-img.png'
import before1 from '../../Images/Home/beforeGo1.jpg'
import before2 from '../../Images/Home/beforeGo2.jpg'
import before3 from '../../Images/Home/beforeGo3.jpg'
import before4 from '../../Images/Home/beforeGo4.jpg'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import Axios from 'axios'
import {
  ApiEndPoint,
  Hotelapitoken,
  CurrencyConverter
} from '../../Components/GlobalData/GlobalData'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Loader from '../../Components/Loading/Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faStar } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer,toast } from 'react-toastify'
import { setSearchQuery,setSearchResults,setHotelDetail } from '../../Redux/hotelActions'
function IndexComponents () {
  const BaseCurrRates = useSelector(state => state.currency.baseCurrRates)
  const SelectedCurrRates = useSelector(
    state => state.currency.selectedCurrRates
  )
  const [makkahHotelscurr, setMakkahHotelscurr] = useState([])
  const [MadinahHotelscurr, setMadinahHotelscurr] = useState([])
  const [makkahPriceConversion, setMakkahPriceConversion] = useState(false)
  const [madinahPriceConversion, setmadinahPriceConversion] = useState(false)
  const [makkahSearch, setMakkahSeach] = useState({});
  const [madinahSearch, setMadinahSearch] = useState({});



  const { t, i18n } = useTranslation()
  var token = Hotelapitoken()
  var apiendpoint = ApiEndPoint()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isloading, setLoading] = useState(false)
  const [isloadingMakkah, setLoadingMakkah] = useState(true)
  const [isloadingMadinah, setLoadingMadinah] = useState(true)
  const [makkahPromotion, setMakkahPromotion] = useState(true)
  const [madinahPromotion, setMadinahPromotion] = useState(true)
  const [makkahHotels, setMakkahHotels] = useState([])
  const [MadinahHotels, setMadinahHotels] = useState([])
  const [allcateogries, setAllCateogries] = useState([])
  const [makkahHotelsRes, setMakkahHotelsRes] = useState([])
  const [MadinahHotelsRes, setMadinahHotelsRes] = useState([])
  const [allTours, setAllTours] = useState([])
  const [showTours, setShowTours] = useState([])
  const [GetCurrency, setGetCurrency] = useState(true)
  const [makkahDetail, setMakkahDetail] = useState({})
  const [madinahDetail, setMadinahDetail] = useState({})
  const [itemsToShow, setItemsToShow] = useState(4)
  
  const [activeItem, setActiveItem] = useState(0) // Initialize activeItem with the default value (0).
  
  const handleCategorySelect = index => {
    setActiveItem(index)
    var filter = allTours.filter(
      tour => Number(tour.categories) === allcateogries[index].id
    )

    setShowTours(
      filter.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
    )
    // Set the selected category as the active one.
  }

  useEffect(() => {
    // Add an event listener to track window width changes
    function handleResize () {
      if (window.innerWidth > 1000) {
        setItemsToShow(4) // For smaller screens, show 1 item
      } else if (window.innerWidth > 768 && window.innerWidth < 1000) {
        setItemsToShow(3) // For smaller screens, show 1 item
      } else if (window.innerWidth > 530 && window.innerWidth < 768) {
        setItemsToShow(2) // For smaller screens, show 1 item
      } else if (window.innerWidth < 530) {
        setItemsToShow(1) // For larger screens, show 4 items (you can adjust this)
      }
    }

    // Initialize the number of items based on the current window width
    handleResize()

    // Attach the event listener
    window.addEventListener('resize', handleResize)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    getUserLocation()
  }, [])
  function getUserLocation () {
    // Fetch user's location data
    fetch(
      'https://api.geoapify.com/v1/ipinfo?&apiKey=0708571ae58c4688824a7d01ca397041'
    )
      .then(response => response.json())
      .then(data => {
        SearchMakkahhotels(data.country.name)
        SearchMadinahhotels(data.country.name)
        localStorage.setItem('usercountry', data.country.name)
        // Handle location data as needed
      })
      .catch(error => {
        console.error('Error fetching user location:', error)
      })
  }
  const fetchHotelDetails = async (hotelId, provider) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: token
    }
    try {
      const response = await Axios.post(
        apiendpoint + '/api/hotels/mata_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setMakkahDetail(prevDetails => ({
        ...prevDetails,
        [hotelId]: response.data
      }))
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }

  const fetchHotelDetails1 = async (hotelId, provider) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: token
    }
    try {
      const response = await Axios.post(
        apiendpoint + '/api/hotels/mata_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setMadinahDetail(prevDetails => ({
        ...prevDetails,
        [hotelId]: response.data
      }))
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }
  const SearchMakkahhotels = async userCountry => {
    const currentDate = new Date()
    const twoDaysLater = new Date(currentDate)
    twoDaysLater.setDate(currentDate.getDate() + 2)
    const threeDaysLater = new Date(currentDate)
    threeDaysLater.setDate(currentDate.getDate() + 3)
    // Define your API URL, authToken, and dataToSend as shown in the previous example
    const FormData = {
      token: token,
      currency_slc: 'AFN',
      currency_slc_iso: 'AF',
      destination_name: 'Makkah',
      country: 'Saudi Arabia',
      lat: 21.4240968,
      long: 39.81733639999999,
      pin: 'SA',
      cityd: 'Makkah',
      country_code: 'SA',
      check_in: moment(twoDaysLater).format('YYYY-MM-DD'),
      check_out: moment(threeDaysLater).format('YYYY-MM-DD'),
      slc_nationality: userCountry,
      adult: 2,
      child: 0,
      room: 1,
      Adults: [2],
      children: [0],
      child_ages1: [],
      rooms_counter: [1],
      child_ages2: []
    }
    setMakkahSeach(FormData);
    try {
      const response = await Axios.post(
        apiendpoint + '/api/search/hotels/new_Live',
        FormData,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      sessionStorage.removeItem('FlightCheckOut')
      var promotion_Hotel_List = response.data.hotels_list.filter(item =>
        item.rooms_options.some(room => room.room_Promotions_Exist === '1')
      )
      setMakkahHotelsRes(response.data)
      if (promotion_Hotel_List.length !== 0) {
        promotion_Hotel_List.forEach(hotel => {
          if (!makkahDetail[hotel.hotel_id]) {
            fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
          }
        })
      } else {
        response.data.hotels_list.slice(0, 4).forEach(hotel => {
          if (!makkahDetail[hotel.hotel_id]) {
            fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
          }
        })
      }
      if (response.data.hotels_list.length !== 0) {
        setGetCurrency(false)
        const currencies = []
        response.data.hotels_list.forEach(hotel => {
          if (!currencies.includes(hotel.hotel_curreny)) {
            currencies.push(hotel.hotel_curreny)
          }
        })
        const currencyResponse = await AllCurrency(currencies)
        if (currencyResponse === null || currencyResponse.length === 0) {
          setMakkahHotelscurr(null)
          setMakkahPriceConversion(false)
          localStorage.setItem('AllHotelCurr', null)
        } else {
          setMakkahHotelscurr(currencyResponse)
          setMakkahPriceConversion(true)
        }
      }
      setLoadingMakkah(false)
      if (promotion_Hotel_List.length !== 0) {
        setMakkahHotels(promotion_Hotel_List)
      } else {
        setMakkahPromotion(false)
        setMakkahHotels(response.data)
      }
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }
  const SearchMadinahhotels = async userCountry => {
    const currentDate = new Date()
    const twoDaysLater = new Date(currentDate)
    twoDaysLater.setDate(currentDate.getDate() + 2)
    const threeDaysLater = new Date(currentDate)
    threeDaysLater.setDate(currentDate.getDate() + 3)
    // Define your API URL, authToken, and dataToSend as shown in the previous example
    const FormData = {
      token: token,
      currency_slc: 'AFN',
      currency_slc_iso: 'AF',
      destination_name: 'Madinah',
      country: 'Saudi Arabia',
      lat: 24.4672132,
      long: 39.6024496,
      pin: 'SA',
      cityd: 'Madinah',
      country_code: 'SA',
      check_in: moment(twoDaysLater).format('YYYY-MM-DD'),
      check_out: moment(threeDaysLater).format('YYYY-MM-DD'),
      slc_nationality: userCountry,
      adult: 2,
      child: 0,
      room: 1,
      Adults: [2],
      children: [0],
      child_ages1: [],
      rooms_counter: [1],
      child_ages2: []
    }
    setMadinahSearch(FormData);
    try {
      const response = await Axios.post(
        apiendpoint + '/api/search/hotels/new_Live',
        FormData,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      sessionStorage.removeItem('FlightCheckOut')

      var promotion_Hotel_List = response.data.hotels_list.filter(item =>
        item.rooms_options.some(room => room.room_Promotions_Exist === '1')
      )
      const currencies = []
      response.data.hotels_list.forEach(hotel => {
        if (!currencies.includes(hotel.hotel_curreny)) {
          currencies.push(hotel.hotel_curreny)
        }
      })
      const currencyResponse = await AllCurrency(currencies)
      if (currencyResponse === null || currencyResponse.length === 0) {
        setMadinahHotelscurr(null)
        setmadinahPriceConversion(false)
        localStorage.setItem('AllHotelCurr', null)
      } else {
        setMadinahHotelscurr(currencyResponse)
        setmadinahPriceConversion(true)
      }
      setLoadingMadinah(false)
      setMadinahHotelsRes(response.data)
      if (promotion_Hotel_List.length !== 0) {
        promotion_Hotel_List.forEach(hotel => {
          if (!madinahDetail[hotel.hotel_id]) {
            fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
          }
        })
        setMadinahHotels(promotion_Hotel_List)
      } else {
        setMadinahPromotion(false)
        response.data.hotels_list.slice(0, 4).forEach(hotel => {
          if (!madinahDetail[hotel.hotel_id]) {
            fetchHotelDetails1(hotel.hotel_id, hotel.hotel_provider)
          }
        })
        setMadinahHotels(response.data)
      }
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }
  const RoomDetailPage = async (id, index, city) => {
    var hotelRoomdetail = [];
    if (city === "makkah") {
      dispatch(setSearchQuery(makkahSearch));
      localStorage.setItem("AllHotelCurr", JSON.stringify(makkahHotelscurr));
      hotelRoomdetail = makkahHotels.hotels_list.filter((item) => item.hotel_id == id);
    } else {
      dispatch(setSearchQuery(madinahSearch));
      localStorage.setItem("AllHotelCurr", JSON.stringify(MadinahHotelscurr));
      hotelRoomdetail = MadinahHotels.hotels_list.filter((item) => item.hotel_id == id);
    }
    dispatch(setSearchResults(hotelRoomdetail));
    setLoading(true);
    try {
      const data = {
        token: token,
        hotel_search_data: JSON.stringify(hotelRoomdetail[0]),
        hotel_code: String(id),
      };
      const response = await Axios.post(
        apiendpoint + "/api/hotels/details_Live",
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            // Required for CORS support to work
            "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
            "Access-Control-Allow-Headers":
              "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
          },
        }
      );
      setLoading(false);
      if (response.data !== "") {
        dispatch(setHotelDetail(response.data.hotel_details));
        navigate(`/hotel_detail/${id}`, { state: { index } });
      } else {
        toast.info(
          "Something went wrong. Please select another hotel or try after some time thanks."
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  }
  const AllCurrency = async currencyArray => {
    const token = CurrencyConverter()

    if (currencyArray.length === 0) {
      return [] // Return an empty array if no currencies are provided
    }

    const requests = currencyArray.map(currencynew => {
      const config = {
        method: 'get',
        url: `https://v6.exchangerate-api.com/v6/${token}/latest/${currencynew}`, // Replace with your API URL
        maxBodyLength: Infinity,
        headers: {}
      }

      return Axios.request(config)
        .then(response => {
          return response.data // Return the response data on success
        })
        .catch(error => {
          console.error(error)
          return null // Return null for failed requests
        })
    })

    const results = await Promise.all(requests)
    const newdatcurr = results.filter(response => response !== null) // Filter out null responses
    return newdatcurr
  }
  const renderPrice = (price, currency, location) => {
    var selectedcurrency = localStorage.getItem('selectedCurrency')
    var currencyData = []
    if (location === 'makkah') {
      currencyData = makkahHotelscurr
    } else {
      currencyData = MadinahHotelscurr
    }
    if (currencyData.length === 0 || currency === selectedcurrency) {
      if (Number(price) % 1 !== 0) {
        return Number(price).toFixed(2)
      }
      return Number(price)
    } else {
      var filterroomcurrdata = currencyData.filter(
        item => item.base_code === currency
      )
      const usdprice = filterroomcurrdata[0].conversion_rates[selectedcurrency] // Use square brackets to access the property
      var baseprice = Number(usdprice) * Number(price)
      if (Number(baseprice) % 1 !== 0) {
        return Number(baseprice).toFixed(2)
      }
      return baseprice
    }
  }
  const calculateMarkup = (ctype, cvalue, atype, avalue, price) => {
    let markupprice = 0;
    let adminmarkupprice = 0;
    let clientmarkupprice = 0;
    let finalpricemarkup = Number(price);
    if (Number(avalue) !== 0) {
      if (atype === "Percentage") {
        markupprice = (price * Number(avalue)) / 100;
      } else {
        markupprice = Number(avalue);
      }
      adminmarkupprice = markupprice;
      finalpricemarkup += markupprice;
    }
    if (Number(cvalue) !== 0) {
      if (ctype === "Percentage") {
        markupprice = (Number(price) * Number(cvalue)) / 100;
      } else {
        markupprice = Number(cvalue);
      }
      clientmarkupprice = markupprice;
      finalpricemarkup += markupprice;
    }
    if (Number(finalpricemarkup) % 1 !== 0) {
      return Number(finalpricemarkup).toFixed(2);
    }
    return finalpricemarkup;
  };
  const promotionFromDate = rooms => {
    const roomWithPromotion = rooms.find(
      room => room.room_Promotions_Exist === '1'
    )
    if (roomWithPromotion) {
      const formattedDate = moment(
        roomWithPromotion.room_Promotions.availible_from
      ).format('DD-MM-YYYY')
      return formattedDate // or assign it to a variable/property as needed
    }
  }
  const promotionToDate = rooms => {
    const roomWithPromotion = rooms.find(
      room => room.room_Promotions_Exist === '1'
    )
    if (roomWithPromotion) {
      const formattedDate = moment(
        roomWithPromotion.room_Promotions.availible_to
      ).format('DD-MM-YYYY')
      return formattedDate // or assign it to a variable/property as needed
    }
  }
  return (
    <>
    <ToastContainer/>
      {isloading && <Loader />}
      <div className='container-fluid mt-4'>
        {makkahPromotion ? (
          <div class='uitk-spacing SpacingContainer uitk-spacing-padding-block-six uitk-spacing-padding-inline-three'>
            <div class='lazyload-wrapper LazyLoadRegion'>
              <div>
                <div>
                  <div class='uitk-layout-flex uitk-layout-flex-flex-direction-column'>
                    <div class='uitk-spacing uitk-spacing-margin-blockstart-two'>
                      <div class='uitk-layout-flex uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two'>
                        <h3 class='uitk-heading uitk-heading-4 overflow-wrap uitk-layout-flex-item'>
                          Promotion Hotels in Makkah
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class='uitk-spacing uitk-spacing-margin-blockstart-six'>
                    <div
                      class='uitk-carousel no-inset no-touch items-max-height uitk-spacing uitk-spacing-margin-blockstart-three lg'
                      data-stid='carousel-wrapper'
                    >
                      <div class='row'>
                        <div class='col-lg-12'>
                          <div class='tab-content' id='nav-tabContent'>
                            <div
                              class='tab-pane fade show active'
                              id='nav-hotels'
                              role='tabpanel'
                              aria-labelledby='nav-hotels-tab'
                            >
                              <div>
                                {isloadingMakkah ? (
                                  <div className='row'>
                                    <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                      <div class='theme_common_box_two img_hover'>
                                        <div class='theme_two_box_img'>
                                          <div
                                            style={{
                                              background: '#c88698',
                                              height: '12em'
                                            }}
                                          ></div>
                                        </div>
                                        <div class='theme_two_box_content'>
                                          <p class='card-text placeholder-glow'>
                                            <span class='placeholder col-7'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                      <div class='theme_common_box_two img_hover'>
                                        <div class='theme_two_box_img'>
                                          <div
                                            style={{
                                              background: '#c88698',
                                              height: '12em'
                                            }}
                                          ></div>
                                        </div>
                                        <div class='theme_two_box_content'>
                                          <p class='card-text placeholder-glow'>
                                            <span class='placeholder col-7'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                      <div class='theme_common_box_two img_hover'>
                                        <div class='theme_two_box_img'>
                                          <div
                                            style={{
                                              background: '#c88698',
                                              height: '12em'
                                            }}
                                          ></div>
                                        </div>
                                        <div class='theme_two_box_content'>
                                          <p class='card-text placeholder-glow'>
                                            <span class='placeholder col-7'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                      <div class='theme_common_box_two img_hover'>
                                        <div class='theme_two_box_img'>
                                          <div
                                            style={{
                                              background: '#c88698',
                                              height: '12em'
                                            }}
                                          ></div>
                                        </div>
                                        <div class='theme_two_box_content'>
                                          <p class='card-text placeholder-glow'>
                                            <span class='placeholder col-7'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className='row'>
                                    {makkahHotels.map((item, index) => (
                                      <div
                                        key={index}
                                        class='col-lg-3 col-md-6 col-sm-6 col-12'
                                      >
                                        <div
                                          style={{ cursor: 'pointer' }}
                                          onClick={() =>
                                            RoomDetailPage(
                                              item.hotel_id,
                                              index,
                                              'makkah'
                                            )
                                          }
                                          class='theme_common_box_two img_hover'
                                        >
                                          <div class='theme_two_box_img'>
                                            <a>
                                              {makkahDetail[item.hotel_id] &&
                                              makkahDetail[item.hotel_id]
                                                .details_data &&
                                              makkahDetail[item.hotel_id]
                                                .details_data.image ? (
                                                <img
                                                  className='home_card_img'
                                                  src={
                                                    makkahDetail[item.hotel_id]
                                                      .details_data.image
                                                  }
                                                  alt=''
                                                />
                                              ) : (
                                                <img
                                                  src='https://haramaynhotels.com/public/uploads/package_imgs/1698138818.jpg'
                                                  style={{ height: '172px' }}
                                                  alt='img'
                                                />
                                              )}
                                            </a>
                                            <p>
                                              <i class='fas fa-map-marker-alt'>
                                                <FontAwesomeIcon
                                                  icon={faMapMarkerAlt}
                                                />
                                              </i>
                                              Makkah
                                            </p>
                                          </div>
                                          <div class='theme_two_box_content'>
                                            <h5>{item.hotel_name}</h5>
                                            <p className='card-star text-center promotion_border'>
                                              {item.stars_rating === '' ? (
                                                <span className='fw-bold'>
                                                  No Rating
                                                </span>
                                              ) : (
                                                Array(item.stars_rating)
                                                  .fill(0)
                                                  .map((_, index) => (
                                                    <i
                                                      key={index}
                                                      className='fa fa-star'
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faStar}
                                                      />
                                                    </i>
                                                  ))
                                              )}
                                            </p>
                                            <div className='d-flex justify-content-between align-items-center'>
                                              <p className='p-0'>From:</p>
                                              <p className='p-0'>
                                                {promotionFromDate(
                                                  item.rooms_options
                                                )}
                                              </p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-center'>
                                              <p className='p-0'>To:</p>
                                              <p className='p-0'>
                                                {promotionToDate(
                                                  item.rooms_options
                                                )}
                                              </p>
                                            </div>
                                            <h5 className='mb-2'>
                                              <super>
                                                {makkahPriceConversion === false
                                                  ? item.hotel_curreny
                                                  : Object.keys(
                                                      SelectedCurrRates
                                                    ).length === 0
                                                  ? BaseCurrRates.base_code
                                                  : SelectedCurrRates.base_code}{' '}
                                                {makkahPriceConversion === false
                                                  ? calculateMarkup(
                                                      item.customer_markup_type,
                                                      item.customer_markup,
                                                      item.admin_markup_type,
                                                      item.admin_markup,
                                                      item.min_price
                                                    )
                                                  : renderPrice(
                                                      calculateMarkup(
                                                        item.customer_markup_type,
                                                        item.customer_markup,
                                                        item.admin_markup_type,
                                                        item.admin_markup,
                                                        item.min_price
                                                      ),
                                                      item.hotel_curreny,
                                                      'makkah'
                                                    )}
                                                <sub
                                                  style={{ color: '#808080ad' }}
                                                >
                                                  <del>
                                                    {makkahPriceConversion ===
                                                    false
                                                      ? calculateMarkup(
                                                          item.customer_markup_type,
                                                          item.customer_markup,
                                                          item.admin_markup_type,
                                                          item.admin_markup,
                                                          item.min_price_Actual
                                                        )
                                                      : renderPrice(
                                                          calculateMarkup(
                                                            item.customer_markup_type,
                                                            item.customer_markup,
                                                            item.admin_markup_type,
                                                            item.admin_markup,
                                                            item.min_price_Actual
                                                          ),
                                                          item.hotel_curreny,
                                                          'makkah'
                                                        )}
                                                  </del>
                                                </sub>
                                              </super>
                                            </h5>
                                            <small>
                                              Vat and Taxes included
                                            </small>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div class='uitk-spacing SpacingContainer uitk-spacing-padding-block-six uitk-spacing-padding-inline-three'>
            <div class='lazyload-wrapper LazyLoadRegion'>
              <div>
                <div>
                  <div class='uitk-layout-flex uitk-layout-flex-flex-direction-column'>
                    <div class='uitk-spacing uitk-spacing-margin-blockstart-two'>
                      <div class='uitk-layout-flex uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two'>
                        <h3 class='uitk-heading uitk-heading-4 overflow-wrap uitk-layout-flex-item'>
                          Hotels in Makkah
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class='uitk-spacing uitk-spacing-margin-blockstart-six'>
                    <div
                      class='uitk-carousel no-inset no-touch items-max-height uitk-spacing uitk-spacing-margin-blockstart-three lg'
                      data-stid='carousel-wrapper'
                    >
                      <div class='row'>
                        <div class='col-lg-12'>
                          <div class='tab-content' id='nav-tabContent'>
                            <div
                              class='tab-pane fade show active'
                              id='nav-hotels'
                              role='tabpanel'
                              aria-labelledby='nav-hotels-tab'
                            >
                              <div>
                                {isloadingMakkah ? (
                                  <div className='row'>
                                    <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                      <div class='theme_common_box_two img_hover'>
                                        <div class='theme_two_box_img'>
                                          <div
                                            style={{
                                              background: '#c88698',
                                              height: '12em'
                                            }}
                                          ></div>
                                        </div>
                                        <div class='theme_two_box_content'>
                                          <p class='card-text placeholder-glow'>
                                            <span class='placeholder col-7'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                      <div class='theme_common_box_two img_hover'>
                                        <div class='theme_two_box_img'>
                                          <div
                                            style={{
                                              background: '#c88698',
                                              height: '12em'
                                            }}
                                          ></div>
                                        </div>
                                        <div class='theme_two_box_content'>
                                          <p class='card-text placeholder-glow'>
                                            <span class='placeholder col-7'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                      <div class='theme_common_box_two img_hover'>
                                        <div class='theme_two_box_img'>
                                          <div
                                            style={{
                                              background: '#c88698',
                                              height: '12em'
                                            }}
                                          ></div>
                                        </div>
                                        <div class='theme_two_box_content'>
                                          <p class='card-text placeholder-glow'>
                                            <span class='placeholder col-7'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                      <div class='theme_common_box_two img_hover'>
                                        <div class='theme_two_box_img'>
                                          <div
                                            style={{
                                              background: '#c88698',
                                              height: '12em'
                                            }}
                                          ></div>
                                        </div>
                                        <div class='theme_two_box_content'>
                                          <p class='card-text placeholder-glow'>
                                            <span class='placeholder col-7'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className='row'>
                                    {makkahHotels.hotels_list
                                      .slice(0, 4)
                                      .map((item, index) => (
                                        <div
                                          key={index}
                                          class='col-lg-3 col-md-6 col-sm-6 col-12'
                                        >
                                          <div
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                              RoomDetailPage(
                                                item.hotel_id,
                                                index,
                                                'makkah'
                                              )
                                            }
                                            class='theme_common_box_two img_hover'
                                          >
                                            <div class='theme_two_box_img'>
                                              <a>
                                                {makkahDetail[item.hotel_id] &&
                                                makkahDetail[item.hotel_id]
                                                  .details_data &&
                                                makkahDetail[item.hotel_id]
                                                  .details_data.image ? (
                                                  <img
                                                    className='home_card_img'
                                                    src={
                                                      makkahDetail[
                                                        item.hotel_id
                                                      ].details_data.image
                                                    }
                                                    alt=''
                                                  />
                                                ) : (
                                                  <img
                                                    src='https://haramaynhotels.com/public/uploads/package_imgs/1698138818.jpg'
                                                    style={{ height: '172px' }}
                                                    alt='img'
                                                  />
                                                )}
                                              </a>
                                              <p>
                                                <i class='fas fa-map-marker-alt'>
                                                  <FontAwesomeIcon
                                                    icon={faMapMarkerAlt}
                                                  />
                                                </i>
                                                Makkah
                                              </p>
                                            </div>
                                            <div class='theme_two_box_content'>
                                              <h5>{item.hotel_name}</h5>
                                              <p className='card-star'>
                                                {item.stars_rating === '' ? (
                                                  <span className='fw-bold'>
                                                    No Rating
                                                  </span>
                                                ) : (
                                                  Array(item.stars_rating)
                                                    .fill(0)
                                                    .map((_, index) => (
                                                      <i
                                                        key={index}
                                                        className='fa fa-star'
                                                      >
                                                        <FontAwesomeIcon
                                                          icon={faStar}
                                                        />
                                                      </i>
                                                    ))
                                                )}
                                              </p>
                                              <h5 className='mb-2'>
                                              <super>
                                                {makkahPriceConversion === false
                                                  ? item.hotel_curreny
                                                  : Object.keys(
                                                      SelectedCurrRates
                                                    ).length === 0
                                                  ? BaseCurrRates.base_code
                                                  : SelectedCurrRates.base_code}{' '}
                                                {makkahPriceConversion === false
                                                  ? calculateMarkup(
                                                      item.customer_markup_type,
                                                      item.customer_markup,
                                                      item.admin_markup_type,
                                                      item.admin_markup,
                                                      item.min_price
                                                    )
                                                  : renderPrice(
                                                      calculateMarkup(
                                                        item.customer_markup_type,
                                                        item.customer_markup,
                                                        item.admin_markup_type,
                                                        item.admin_markup,
                                                        item.min_price
                                                      ),
                                                      item.hotel_curreny,
                                                      'makkah'
                                                    )}
                                              </super>
                                              </h5>
                                              <small>
                                                Vat and Taxes included
                                              </small>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {madinahPromotion ? (
          <div class='uitk-spacing SpacingContainer uitk-spacing-padding-block-six uitk-spacing-padding-inline-three'>
            <div class='lazyload-wrapper LazyLoadRegion'>
              <div>
                <div>
                  <div class='uitk-layout-flex uitk-layout-flex-flex-direction-column'>
                    <div class='uitk-spacing uitk-spacing-margin-blockstart-two'>
                      <div class='uitk-layout-flex uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two'>
                        <h3 class='uitk-heading uitk-heading-4 overflow-wrap uitk-layout-flex-item'>
                          Promotion Hotels in Madinah
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class='uitk-spacing uitk-spacing-margin-blockstart-six'>
                    <div
                      class='uitk-carousel no-inset no-touch items-max-height uitk-spacing uitk-spacing-margin-blockstart-three lg'
                      data-stid='carousel-wrapper'
                    >
                      <div class='row'>
                        <div class='col-lg-12'>
                          <div class='tab-content' id='nav-tabContent'>
                            <div
                              class='tab-pane fade show active'
                              id='nav-hotels'
                              role='tabpanel'
                              aria-labelledby='nav-hotels-tab'
                            >
                              <div>
                                {isloadingMadinah ? (
                                  <div className='row'>
                                    <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                      <div class='theme_common_box_two img_hover'>
                                        <div class='theme_two_box_img'>
                                          <div
                                            style={{
                                              background: '#c88698',
                                              height: '12em'
                                            }}
                                          ></div>
                                        </div>
                                        <div class='theme_two_box_content'>
                                          <p class='card-text placeholder-glow'>
                                            <span class='placeholder col-7'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                      <div class='theme_common_box_two img_hover'>
                                        <div class='theme_two_box_img'>
                                          <div
                                            style={{
                                              background: '#c88698',
                                              height: '12em'
                                            }}
                                          ></div>
                                        </div>
                                        <div class='theme_two_box_content'>
                                          <p class='card-text placeholder-glow'>
                                            <span class='placeholder col-7'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                      <div class='theme_common_box_two img_hover'>
                                        <div class='theme_two_box_img'>
                                          <div
                                            style={{
                                              background: '#c88698',
                                              height: '12em'
                                            }}
                                          ></div>
                                        </div>
                                        <div class='theme_two_box_content'>
                                          <p class='card-text placeholder-glow'>
                                            <span class='placeholder col-7'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                      <div class='theme_common_box_two img_hover'>
                                        <div class='theme_two_box_img'>
                                          <div
                                            style={{
                                              background: '#c88698',
                                              height: '12em'
                                            }}
                                          ></div>
                                        </div>
                                        <div class='theme_two_box_content'>
                                          <p class='card-text placeholder-glow'>
                                            <span class='placeholder col-7'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className='row'>
                                    {MadinahHotels.hotels_list
                                      .slice(0, 4)
                                      .map((item, index) => (
                                        <div
                                          key={index}
                                          class='col-lg-3 col-md-6 col-sm-6 col-12'
                                        >
                                          <div
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                              RoomDetailPage(
                                                item.hotel_id,
                                                index,
                                                'madinah'
                                              )
                                            }
                                            class='theme_common_box_two img_hover'
                                          >
                                            <div class='theme_two_box_img'>
                                              <a>
                                                {madinahDetail[item.hotel_id] &&
                                                madinahDetail[item.hotel_id]
                                                  .details_data &&
                                                madinahDetail[item.hotel_id]
                                                  .details_data.image ? (
                                                  <img
                                                    className='home_card_img'
                                                    src={
                                                      madinahDetail[
                                                        item.hotel_id
                                                      ].details_data.image
                                                    }
                                                    alt=''
                                                  />
                                                ) : (
                                                  <img
                                                    src='https://haramaynhotels.com/public/uploads/package_imgs/1698138818.jpg'
                                                    style={{ height: '172px' }}
                                                    alt='img'
                                                  />
                                                )}
                                              </a>
                                              <p>
                                                <i class='fas fa-map-marker-alt'>
                                                  <FontAwesomeIcon
                                                    icon={faMapMarkerAlt}
                                                  />
                                                </i>
                                                Madinah
                                              </p>
                                            </div>
                                            <div class='theme_two_box_content'>
                                              <h5>{item.hotel_name}</h5>
                                              <p className='card-star text-center promotion_border'>
                                                {item.stars_rating === '' ? (
                                                  <span className='fw-bold'>
                                                    No Rating
                                                  </span>
                                                ) : (
                                                  Array(item.stars_rating)
                                                    .fill(0)
                                                    .map((_, index) => (
                                                      <i
                                                        key={index}
                                                        className='fa fa-star'
                                                      >
                                                        <FontAwesomeIcon
                                                          icon={faStar}
                                                        />
                                                      </i>
                                                    ))
                                                )}
                                              </p>
                                              <h5 className='mb-2'>
                                              <super>
                                                {madinahPriceConversion === false
                                                  ? item.hotel_curreny
                                                  : Object.keys(
                                                      SelectedCurrRates
                                                    ).length === 0
                                                  ? BaseCurrRates.base_code
                                                  : SelectedCurrRates.base_code}{' '}
                                                {madinahPriceConversion === false
                                                  ? calculateMarkup(
                                                      item.customer_markup_type,
                                                      item.customer_markup,
                                                      item.admin_markup_type,
                                                      item.admin_markup,
                                                      item.min_price
                                                    )
                                                  : renderPrice(
                                                      calculateMarkup(
                                                        item.customer_markup_type,
                                                        item.customer_markup,
                                                        item.admin_markup_type,
                                                        item.admin_markup,
                                                        item.min_price
                                                      ),
                                                      item.hotel_curreny,
                                                      'madinah'
                                                    )}
                                                <sub
                                                  style={{ color: '#808080ad' }}
                                                >
                                                  <del>
                                                    {madinahPriceConversion ===
                                                    false
                                                      ? calculateMarkup(
                                                          item.customer_markup_type,
                                                          item.customer_markup,
                                                          item.admin_markup_type,
                                                          item.admin_markup,
                                                          item.min_price_Actual
                                                        )
                                                      : renderPrice(
                                                          calculateMarkup(
                                                            item.customer_markup_type,
                                                            item.customer_markup,
                                                            item.admin_markup_type,
                                                            item.admin_markup,
                                                            item.min_price_Actual
                                                          ),
                                                          item.hotel_curreny,
                                                          'madinah'
                                                        )}
                                                  </del>
                                                </sub>
                                              </super>
                                              </h5>
                                              <small>
                                                Vat and Taxes included
                                              </small>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div class='uitk-spacing SpacingContainer uitk-spacing-padding-block-six uitk-spacing-padding-inline-three'>
            <div class='lazyload-wrapper LazyLoadRegion'>
              <div>
                <div>
                  <div class='uitk-layout-flex uitk-layout-flex-flex-direction-column'>
                    <div class='uitk-spacing uitk-spacing-margin-blockstart-two'>
                      <div class='uitk-layout-flex uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two'>
                        <h3 class='uitk-heading uitk-heading-4 overflow-wrap uitk-layout-flex-item'>
                          Hotels in Madinah
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class='uitk-spacing uitk-spacing-margin-blockstart-six'>
                    <div
                      class='uitk-carousel no-inset no-touch items-max-height uitk-spacing uitk-spacing-margin-blockstart-three lg'
                      data-stid='carousel-wrapper'
                    >
                      <div class='row'>
                        <div class='col-lg-12'>
                          <div class='tab-content' id='nav-tabContent'>
                            <div
                              class='tab-pane fade show active'
                              id='nav-hotels'
                              role='tabpanel'
                              aria-labelledby='nav-hotels-tab'
                            >
                              <div>
                                {isloadingMadinah ? (
                                  <div className='row'>
                                    <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                      <div class='theme_common_box_two img_hover'>
                                        <div class='theme_two_box_img'>
                                          <div
                                            style={{
                                              background: '#c88698',
                                              height: '12em'
                                            }}
                                          ></div>
                                        </div>
                                        <div class='theme_two_box_content'>
                                          <p class='card-text placeholder-glow'>
                                            <span class='placeholder col-7'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                      <div class='theme_common_box_two img_hover'>
                                        <div class='theme_two_box_img'>
                                          <div
                                            style={{
                                              background: '#c88698',
                                              height: '12em'
                                            }}
                                          ></div>
                                        </div>
                                        <div class='theme_two_box_content'>
                                          <p class='card-text placeholder-glow'>
                                            <span class='placeholder col-7'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                      <div class='theme_common_box_two img_hover'>
                                        <div class='theme_two_box_img'>
                                          <div
                                            style={{
                                              background: '#c88698',
                                              height: '12em'
                                            }}
                                          ></div>
                                        </div>
                                        <div class='theme_two_box_content'>
                                          <p class='card-text placeholder-glow'>
                                            <span class='placeholder col-7'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                      <div class='theme_common_box_two img_hover'>
                                        <div class='theme_two_box_img'>
                                          <div
                                            style={{
                                              background: '#c88698',
                                              height: '12em'
                                            }}
                                          ></div>
                                        </div>
                                        <div class='theme_two_box_content'>
                                          <p class='card-text placeholder-glow'>
                                            <span class='placeholder col-7'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-4'></span>
                                            <span class='placeholder col-6'></span>
                                            <span class='placeholder col-8'></span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className='row'>
                                    {MadinahHotels.hotels_list
                                      .slice(0, 4)
                                      .map((item, index) => (
                                        <div
                                          key={index}
                                          class='col-lg-3 col-md-6 col-sm-6 col-12'
                                        >
                                          <div
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                              RoomDetailPage(
                                                item.hotel_id,
                                                index,
                                                'madinah'
                                              )
                                            }
                                            class='theme_common_box_two img_hover'
                                          >
                                            <div class='theme_two_box_img '>
                                              <a>
                                                {madinahDetail[item.hotel_id] &&
                                                madinahDetail[item.hotel_id]
                                                  .details_data &&
                                                madinahDetail[item.hotel_id]
                                                  .details_data.image ? (
                                                  <img
                                                    className='home_card_img'
                                                    src={
                                                      madinahDetail[
                                                        item.hotel_id
                                                      ].details_data.image
                                                    }
                                                    alt=''
                                                  />
                                                ) : (
                                                  <img
                                                    src='https://haramaynhotels.com/public/uploads/package_imgs/1698138818.jpg'
                                                    style={{ height: '172px' }}
                                                    alt='img'
                                                  />
                                                )}
                                              </a>
                                              <p>
                                                <i class='fas fa-map-marker-alt'>
                                                  <FontAwesomeIcon
                                                    icon={faMapMarkerAlt}
                                                  />
                                                </i>
                                                Madinah
                                              </p>
                                            </div>
                                            <div class='theme_two_box_content'>
                                              <h5>{item.hotel_name}</h5>
                                              <p className='card-star'>
                                                {item.stars_rating === '' ? (
                                                  <span className='fw-bold'>
                                                    No Rating
                                                  </span>
                                                ) : (
                                                  Array(item.stars_rating)
                                                    .fill(0)
                                                    .map((_, index) => (
                                                      <i
                                                        key={index}
                                                        className='fa fa-star'
                                                      >
                                                        <FontAwesomeIcon
                                                          icon={faStar}
                                                        />
                                                      </i>
                                                    ))
                                                )}
                                              </p>
                                              <h5 className='mb-2'>
                                              <super>
                                                {madinahPriceConversion === false
                                                  ? item.hotel_curreny
                                                  : Object.keys(
                                                      SelectedCurrRates
                                                    ).length === 0
                                                  ? BaseCurrRates.base_code
                                                  : SelectedCurrRates.base_code}{' '}
                                                {madinahPriceConversion === false
                                                  ? calculateMarkup(
                                                      item.customer_markup_type,
                                                      item.customer_markup,
                                                      item.admin_markup_type,
                                                      item.admin_markup,
                                                      item.min_price
                                                    )
                                                  : renderPrice(
                                                      calculateMarkup(
                                                        item.customer_markup_type,
                                                        item.customer_markup,
                                                        item.admin_markup_type,
                                                        item.admin_markup,
                                                        item.min_price
                                                      ),
                                                      item.hotel_curreny,
                                                      'madinah'
                                                    )}
                                              </super>
                                              </h5>
                                              <small>
                                                Vat and Taxes included
                                              </small>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div class='uitk-spacing whiteBackground bex-homepage-module SimpleContainer'>
          <div class='uitk-spacing SimpleContainer'>
            <div class='uitk-spacing SpacingContainer uitk-spacing-padding-block-four uitk-spacing-padding-inline-three'></div>
          </div>
          <div className='row align-items-center mt-4 mb-4'>
            <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
              <div class='heading_left_area'>
                <h3>Go beyond your imagination</h3>
                <h5>Discover your ideal experience with us</h5>
              </div>
            </div>
            <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
              <div class='imagination_boxed'>
                <a href='#'>
                  <img src={img1} alt='img' />
                </a>
                <h3>
                  <p>7% Discount for all Transfers</p>
                </h3>
              </div>
            </div>
            <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
              <div class='imagination_boxed'>
                <a href='#'>
                  <img src={img2} alt='img' />
                </a>
                <h3>
                  <p>Special Managers Hotel Makkah</p>
                </h3>
              </div>
            </div>
            <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
              <div class='imagination_boxed'>
                <a href='#'>
                  <img src={img3} alt='img' />
                </a>
                <h3>
                  <p>Special Managers Hotel Medina</p>
                </h3>
              </div>
            </div>
          </div>

          <div class='uitk-spacing SimpleContainer'>
            <div class='uitk-spacing SpacingContainer uitk-spacing-padding-block-three uitk-spacing-padding-inline-three'></div>
          </div>
          <div class='uitk-spacing SpacingContainer uitk-spacing-padding-block-three uitk-spacing-padding-inline-three'>
            <div class='lazyload-wrapper LazyLoadRegion'>
              <div>
                <div id='retail-recommendation-module-1'>
                  <div>
                    <h2
                      class='uitk-heading uitk-heading-4 uitk-spacing uitk-spacing-margin-blockstart-unset'
                      aria-hidden='false'
                    >
                      Explore Saudi Arabia
                    </h2>
                    <div class='uitk-spacing uitk-spacing-margin-blockstart-three'>
                      <div class='row'>
                        <div class='col-lg-6 col-md-12 col-sm-12 col-12'>
                          <div class='destinations_content_box img_animation'>
                            <img src={bigimage} alt='img' />
                            <div class='destinations_content_inner'>
                              <h2>Up to</h2>
                              <div class='destinations_big_offer'>
                                <h1>25</h1>
                                <h6>
                                  <span>%</span> <span>Off</span>
                                </h6>
                              </div>
                              <h2>Hotel Bookings</h2>
                              <button className='btn btn-primary search-btn1'>
                                Book now
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class='col-lg-6 col-md-12 col-sm-12 col-12'>
                          <div class='row'>
                            <div class='col-lg-6 col-md-6 col-sm-12 col-12'>
                              <NavLink to='/about_saudi' target='blank'>
                                <div class='destinations_content_box img_animation'>
                                  <div class='image-wrapper'>
                                    <img
                                      style={{
                                        height: '20em',
                                        objectFit: 'cover'
                                      }}
                                      src={before3}
                                      alt='img'
                                    />
                                    <div class='overlay'></div>
                                  </div>
                                  <div class='text-center destinations_content_inner'>
                                    <h4>About Saudi</h4>
                                  </div>
                                </div>
                              </NavLink>
                              <NavLink to='/travel_tips' target='blank'>
                                <div class='destinations_content_box img_animation'>
                                  <div class='image-wrapper'>
                                    <img
                                      style={{
                                        height: '20em',
                                        objectFit: 'cover'
                                      }}
                                      src={before1}
                                      alt='img'
                                    />
                                    <div class='overlay'></div>
                                  </div>
                                  <div class='text-center destinations_content_inner'>
                                    <h4>Safety Travel Tips</h4>
                                  </div>
                                </div>
                              </NavLink>
                            </div>
                            <div class='col-lg-6 col-md-6 col-sm-12 col-12'>
                              <NavLink to='/useful_contacts' target='blank'>
                                <div class='destinations_content_box img_animation'>
                                  <div class='image-wrapper'>
                                    <img
                                      style={{
                                        height: '20em',
                                        objectFit: 'cover'
                                      }}
                                      src={before2}
                                      alt='img'
                                    />
                                    <div class='overlay'></div>
                                  </div>
                                  <div class='text-center destinations_content_inner'>
                                    <h4>Useful Contacts</h4>
                                  </div>
                                </div>
                              </NavLink>
                              <div class='destinations_content_box img_animation'>
                                <div class='image-wrapper'>
                                  <img
                                    style={{
                                      height: '20em',
                                      objectFit: 'cover'
                                    }}
                                    src={before4}
                                    alt='img'
                                  />
                                  <div class='overlay'></div>
                                </div>
                                <div class='text-center destinations_content_inner'>
                                  <h4>Travel Regulations</h4>
                                </div>
                              </div>

                              {/* <div class='destinations_content_box'>
                                <button className='btn btn-primary search-btn1'>
                                  View All
                                </button>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='uitk-spacing SimpleContainer'>
            <div class='uitk-spacing SpacingContainer uitk-spacing-padding-block-six uitk-spacing-padding-inline-three'>
              <div class='row'>
                <div class='col-lg-6 col-md-12 col-sm-12 col-12'>
                  <div class='about_two_left'>
                    <div class='about_two_left_top'>
                      <h5>Download Our App </h5>
                      <h2>Discover new horizons with Haramayn Hotels</h2>
                      <p>
                        The one-stop destination for all your travel and
                        accommodation needs! We are excited to announce that we
                        have launched our brand-new app, designed to provide you
                        with a seamless and convenient booking experience, right
                        at your fingertips.
                      </p>
                    </div>
                    <div class='about_two_left_middel'>
                      <div class='about_two_item'>
                        <div class='about_two_item_icon'>
                          <i class='fas fa-download'></i>
                        </div>
                        <div class='about_two_item_text'>
                          <h3>Download the App</h3>
                          <p class='mb-5'>
                            Visit the App Store or Google Play Store and search
                            for "haramaynhotels" to find our official app. Click
                            "Install" to download it onto your device.
                          </p>
                          <a
                            href='https://play.google.com/store/apps/details?id=com.haramaynhotels.haramaynhotels'
                            target='blank'
                            class='btn btn-success mb-3'
                          >
                            Download
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='col-lg-6 col-md-12 col-sm-12 col-12'>
                  <div class='about_two_left_img px-5'>
                    <img src={m_app} alt='img' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='uitk-spacing SimpleContainer'>
            <div
              class='uitk-spacing NativeAdContainerRegion uitk-spacing-padding-medium-inline-three uitk-spacing-padding-small-inlinestart-three'
              id='native-ad-NMC1'
            >
              <div class=''>
                <meso-native
                  class='is-visually-hidden'
                  aria-hidden='true'
                  uci='NMC1'
                  nativetype='native-ad'
                  isfluid='true'
                  adslot='NMC1'
                  data-testid='meso-native'
                  adunitpath='/expedia.us_en/home/all'
                  timeout='5000'
                  slot='NMC1'
                ></meso-native>
                <div
                  class='fallback'
                  aria-hidden='false'
                  data-testid='native-ad-container'
                ></div>
              </div>
            </div>
          </div>
          <div class='uitk-spacing bex-homepage-module SimpleContainer'>
            <div data-testid='one-key-banner-1' id='one-key-banner-1'>
              <div>
                <div
                  class='uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-padded uitk-card-has-global-loyalty-standard-theme'
                  data-stid='one-key-message-card'
                >
                  <div class='uitk-layout-flex uitk-layout-flex-align-items-center uitk-spacing uitk-spacing-padding-inline-three uitk-spacing-padding-block-three'>
                    {/* <div class='uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item'>
                    <div class='uitk-spacing uitk-spacing-margin-small-inlineend-three uitk-spacing-margin-medium-inlineend-six'>
                      <img
                        class='uitk-mark uitk-mark-landscape-oriented'
                        alt=''
                        src='https://a.travel-assets.com/egds/marks/onekey__standard__always_light.svg'
                        id='onekey__standard__always_light'
                      />
                    </div>
                  </div> */}
                    <div class=' container uitk-card-content-section uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1'>
                      <div class='uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two uitk-layout-flex-flex-wrap-wrap'>
                        <div class='uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item uitk-layout-flex-item-flex-basis-88x uitk-layout-flex-item-flex-grow-1'>
                          <div class='uitk-spacing uitk-spacing-padding-inlineend-two'>
                            <h3 className='mt-2 mb-2' style={{ color: '#fff' }}>
                              Special Offers
                            </h3>
                            <h2
                              style={{
                                textAlign: 'justify',
                                lineHeight: '1.5em'
                              }}
                              class='uitk-heading mt-2 uitk-heading-6 uitk-layout-flex-item uitk-text-inverse-theme'
                            >
                              We offer a variety of tours and activities that
                              will show you the best that this country has to
                              offer, from its stunning landscapes and rich
                              history to its luxurious experiences. With just a
                              few clicks, you can easily book your
                              accommodation, transfers, visas, and activities
                              all in one place. Our user-friendly B2C platform
                              makes booking your dream trip easier than ever, so
                              you can focus on enjoying your time in Saudi
                              Arabia.
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default IndexComponents
