import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Layout from '../../Components/Layout/Layout'
import calender from '../../Images/calendar_shield.svg'
import Select from 'react-select'
import { Checkbox } from 'rsuite'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarDays,
  faCheck,
  faHeadset,
  faAngleDown,
  faAngleRight,
  faWindowRestore,
  faCancel,
  faClock,
  faDiagramProject,
  faSnowflake,
  faStar,
  faUser,
  faCar,
  faLock,
  faEnvelope,
  faPhone
} from '@fortawesome/free-solid-svg-icons'
import { Stripe } from 'stripe'
import moment from 'moment'
import Loading from '../../Components/Loading/Loader'
import {
  ApiEndPoint,
  TransferCheckoutToken,
  CustomerDomainName
} from '../../Components/GlobalData/GlobalData'
import { ToastContainer, toast } from 'react-toastify'
import payment from '../../Images/Packages/Logo/payment-img.png'
import { useNavigate } from 'react-router-dom'
import { StripeClientSecret } from '../../Components/GlobalData/GlobalData'
import TransferNew from '../../Components/SearchBar/TransferNew'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js'
import { countryListLocal } from '../../Components/Data/CountryList'
var StripePayment = 0
var StripeCurrency = ''
var exchangeRate = 0
function TransferCheckout () {
  const [baseCName, setBaseCName] = useState('GBP')
  const BaseCurrRates = useSelector(state => state.currency.baseCurrRates)
  const SelectedCurrRates = useSelector(
    state => state.currency.selectedCurrRates
  )
  const navigate = useNavigate()
  const endpoint = ApiEndPoint()
  const token = TransferCheckoutToken()
  const Transferid = sessionStorage.getItem('TransferID')
  const TransferDetail = useSelector(state => state.transferList.transferlist)
  const NewSelectedTransfer = TransferDetail?.transfers_list.filter(
    item => Number(item.destination_id) === Number(Transferid)
  )
  const [priceConversion, setPriceConversion] = useState(false)
  const [currencyRates, setCurrencyRates] = useState([])
  var Secretkey = StripeClientSecret()
  const stripe = new Stripe(Secretkey)
  const elements = useElements()
  const stripeInstance = useStripe()
  const [error, setError] = useState(null)
  const [isChecked, setIsChecked] = useState(false)
  const [countryList, setCountryList] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [isBooking, setIsBooking] = useState(false)
  const [cardInfo, setCardInfo] = useState({ name: '' })
  const [checkedItems, setCheckedItems] = useState([])
  const [extraTransferTotal, setExtraTransferTotal] = useState({ total: 0 })
  const extraData = JSON.parse(sessionStorage.getItem('Extradata'))
  const [selectedTransfer, setSelectedTransfer] = useState(
    NewSelectedTransfer[0]
  )
  const usercountry = localStorage.getItem('usercountry')
  var moreDestin = []
  var ziyarat = []
  if (
    NewSelectedTransfer[0].more_destination_details !== '' &&
    NewSelectedTransfer[0].more_destination_details !== null
  ) {
    moreDestin = JSON.parse(NewSelectedTransfer[0].more_destination_details)
  }
  if (
    NewSelectedTransfer[0].ziyarat_City_details !== '' &&
    NewSelectedTransfer[0].ziyarat_City_details !== null
  ) {
    ziyarat = JSON.parse(NewSelectedTransfer[0].ziyarat_City_details)
  }
  const [moreDestination, setMoreDestination] = useState(moreDestin)
  const [mazarat, setMazarat] = useState(ziyarat)
  const [selectedNationality, setSelectedNationality] = useState('')
  const [gestinfo, setGestinfo] = useState({
    title: '',
    firstname: '',
    lastname: '',
    dateofbirth: '',
    numbercode: '',
    email: '',
    phonenumber: '',
    pno: '',
    country: '',
    nationality: ''
  })
  useEffect(() => {
    var currdata = localStorage.getItem('AllHotelCurr')
    if (currdata !== 'null' && currdata !== null) {
      setPriceConversion(true)
      setCurrencyRates(currdata)
    }
  }, [])
  useEffect(() => {
    const countryOptions = countryListLocal.item.map(country => ({
      value: country.name.common,
      label: country.name.common,
      flag: country.flags.png,
      phoneCode: country.idd.root + country.idd.suffixes[0]
    }))
    setCountryList(countryOptions)
    var defaultOption = countryOptions.find(
      option => option.label === usercountry
    )
    setSelectedCountry(defaultOption)
    setSelectedNationality(defaultOption.value)
    setGestinfo(prevdata => ({
      ...prevdata,
      phonenumber: defaultOption.phoneCode,
      numbercode: defaultOption.phoneCode,
      nationality: defaultOption.value
    }))
  }, [])

  // async function fetchData () {
  //   Axios.get(
  //     'https://restcountries.com/v3.1/all?fields=name,flags,cca2,cca3,idd'
  //   )
  //     .then(response => {
  //       const countryOptions = response?.data.map(country => ({
  //         value: country.name.common,
  //         label: country.name.common,
  //         flag: country.flags.png,
  //         phoneCode: country.idd.root + country.idd.suffixes[0]
  //       }))
  //       setCountryList(countryOptions)
  //       var defaultOption = countryOptions.find(option => option.label === usercountry);
  //       setSelectedCountry(defaultOption);
  //     setSelectedNationality(defaultOption.value);
  //     setGestinfo(prevdata=>({...prevdata, phonenumber:defaultOption.phoneCode, numbercode:defaultOption.phoneCode,nationality:defaultOption.value }));

  //     })
  //     .catch(error => {
  //       console.error('Error:', error)
  //     })
  // }
  const handlegestchange = e => {
    const { value, name } = e.target
    setGestinfo(prevgestinfo => ({ ...prevgestinfo, [name]: value }))
  }
  const handleCountryChange = selectedOption => {
    setSelectedCountry(selectedOption)
    setSelectedNationality(selectedOption.value)
    setGestinfo(prevdata => ({
      ...prevdata,
      phonenumber: selectedOption.phoneCode,
      numbercode: selectedOption.phoneCode,
      nationality: selectedOption.value
    }))
  }

  const handlecarddetail = e => {
    const { value, name } = e.target
    setCardInfo(prevcardinfo => ({ ...prevcardinfo, [name]: value }))
  }

  const handlePayment = async () => {
    if (cardInfo.name === '') {
      setError('Enter Card Holder Name.')
      return { success: false }
    }
    const response = await stripe.paymentIntents.create({
      amount: (Number(StripePayment) * 100).toFixed(0), // Amount in cents
      currency: StripeCurrency,
      description: 'Example payment',
      payment_method_types: ['card']
    })
    var Client_Secret = response.client_secret

    try {
      // Confirm the payment intent with the payment method
      const { paymentIntent, error } = await stripeInstance.confirmCardPayment(
        Client_Secret,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: cardInfo.name
            }
          }
        }
      )

      if (error) {
        setError(error.message)

        console.error(error)
        setIsBooking(false)
        return { success: false }
      } else {
        return { success: true, data: paymentIntent }
      }
    } catch (error) {
      console.error('Error:', error)
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT
      })
      setIsBooking(false)

      return { success: false }
    }
  }

  const BookTransfer = async newdata => {
    var status = await handlePayment()
    if (status.success === false) {
      setIsBooking(false)
      return
    }
    const LeadPassengerData = {
      lead_passenger_details: {
        lead_title: gestinfo.title,
        lead_first_name: gestinfo.firstname,
        lead_last_name: gestinfo.lastname,
        lead_email: gestinfo.email,
        lead_date_of_birth: gestinfo.dateofbirth,
        lead_country: gestinfo.numbercode,
        lead_phone: gestinfo.phonenumber,
        passport_img: ''
      },
      other_passenger_details: [],
      transfer_price_details: {
        original_price_transfer: selectedTransfer?.total_fare_markup,
        original_price_total_transfer:
          selectedTransfer?.search_passenger *
          selectedTransfer?.total_fare_markup,
        original_curreny_transfer: selectedTransfer?.sale_currency,
        exchange_price_transfer: StripePayment,
        exchange_price_total_transfer: StripePayment,
        exchange_curreny_transfer: StripeCurrency,
        destination_avail_id: selectedTransfer?.destination_id,
        no_of_paxs_transfer: selectedTransfer?.search_passenger
      }
    }

    const extraData = sessionStorage.getItem('Extradata')
    selectedTransfer.total_fare_markup =
      Number(selectedTransfer.total_fare_markup) +
      Number(extraTransferTotal.total)
    var bookingdata = {
      token: token,
      extras_Data: extraData,
      extras_Price: JSON.stringify(checkedItems),
      slc_pyment_method: JSON.stringify(status.data),
      transfer_data: JSON.stringify(LeadPassengerData),
      transfer_destination_data: JSON.stringify(selectedTransfer),
      booking_From: selectedTransfer.booking_From,
      response_confirm_booking: JSON.stringify(newdata)
    }
    try {
      const response = await Axios.post(
        endpoint + '/api/transfer_checkout_submit_react',
        bookingdata,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setIsBooking(false)
      if (response.data.status === 'success') {
        navigate(`/transfer_invoice/${response.data.Invoice_no}`)
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        })
        return
      }
    } catch (error) {
      setIsBooking(false)
      console.error('Error:', error)
    }
  }

  const ThrirdPartyTransfer = async () => {
    if (isChecked) {
      if (gestinfo.title === '') {
        toast.error('Please Select Lead Guest Title.', {
          position: toast.POSITION.TOP_RIGHT
        })
        return
      } else if (gestinfo.firstname === '') {
        toast.error('Please Enter Lead Guest First Name.', {
          position: toast.POSITION.TOP_RIGHT
        })
        return
      } else if (gestinfo.lastname === '') {
        toast.error('Please Enter Lead Guest Last Name.', {
          position: toast.POSITION.TOP_RIGHT
        })
        return
      } else if (gestinfo.email === '') {
        toast.error('Please Enter Lead Guest Email.', {
          position: toast.POSITION.TOP_RIGHT
        })
        return
      } else if (selectedNationality === '') {
        toast.error('Please Select Lead Guest Nationality.', {
          position: toast.POSITION.TOP_RIGHT
        })
        return
      } else if (gestinfo.phonenumber.length <= 5) {
        toast.error('Please Enter Phone Number.', {
          position: toast.POSITION.TOP_RIGHT
        })
        return
      }
      setIsBooking(true)
      if (selectedTransfer.booking_From !== '3rd Party API') {
        BookTransfer()
      }
      const TransectionNumber = sessionStorage.getItem(
        'TransferTransectionNumber'
      )
      var bookingData = {
        country: selectedTransfer.country,
        propertyname: gestinfo.firstname,
        j1propertyname: gestinfo.lastname,
        accomodationaddress: 'H#10 St#9 etc',
        deppoint: selectedTransfer.pickup_api_City,
        depinfo: selectedTransfer.pickup_City,
        transactionnumber: TransectionNumber,
        title: gestinfo.title,
        firstname: gestinfo.firstname,
        lastname: gestinfo.lastname,
        email: gestinfo.email,
        RetPoint: selectedTransfer.dropof_api_City,
        RetInfo: selectedTransfer.dropof_City,
        phone: gestinfo.phonenumber,
        mobile: gestinfo.phonenumber
      }

      var data = {
        token: token,
        confirm_Booking_Arr: JSON.stringify(bookingData)
      }
      try {
        const response = await Axios.post(
          endpoint + '/api/confbook_Transfer_Api',
          data,
          {
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          }
        )
        if (!response.data.error) {
          BookTransfer(response.data)
        } else {
          setIsBooking(false)
          toast.error(response.data.error, {
            position: toast.POSITION.TOP_RIGHT
          })
          return
        }
      } catch (error) {
        setIsBooking(false)
        console.error('Error:', error)
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    } else {
      toast.error('Please Agree with Terms and Conditions.', {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  }

  const handleCheckboxContinue = () => {
    setIsChecked(!isChecked)
  }

  const renderPrice = (price, currency) => {
    var selectedcurrency = localStorage.getItem('selectedCurrency')
    var currencyData = JSON.parse(currencyRates)
    if (currencyData.length === 0 || currency === selectedcurrency) {
      if (Number(price) % 1 !== 0) {
        return Number(price).toFixed(2)
      }
      return Number(price)
    } else {
      var filterroomcurrdata = currencyData.filter(
        item => item.base_code === currency
      )

      const gbpprice = filterroomcurrdata[0].conversion_rates[selectedcurrency] // Use square brackets to access the property
      var baseprice = Number(gbpprice) * Number(price)
      if (Number(baseprice) % 1 !== 0) {
        return Number(baseprice).toFixed(2)
      }
      return baseprice
    }
  }

  const handleChange = item => {
    const isChecked = checkedItems.some(
      checkedItem => checkedItem.ExtrasID === item.ExtrasID
    )
    const { ExtrasID, Price } = item

    if (isChecked) {
      // If already checked, remove the item
      setCheckedItems(prevItems =>
        prevItems.filter(checkedItem => checkedItem.ExtrasID !== ExtrasID)
      )
      setExtraTransferTotal(prevTransfer => ({
        ...prevTransfer,
        total: prevTransfer.total - Number(item.Price)
      }))
    } else {
      // If not checked, add the item
      setCheckedItems(prevItems => [...prevItems, item])
      setExtraTransferTotal(prevTransfer => ({
        ...prevTransfer,
        total: prevTransfer.total + Number(item.Price)
      }))
    }
  }
  return (
    <>
      {isBooking && <Loading />}
      <ToastContainer />
      <Layout>
        <div className='container mt-2'>
          {/* <h1 class="section-header-main" style={{fontWeight:'30px'}}>Secure booking - only takes 2 minutes!</h1> */}
          {/* <div
            id='free-cancellation-alignment'
            class='uitk-grid flex-layout-custom flex-listing flex-theme-light checkout-hotel-detail'
          >
            <div id='free-cancellation-message' class='uitk-col '>
              <div class='flex-card d-flex'>
                <div class='flex-figure'>
                  <img
                    class='image background aspect-ratio1-1'
                    src={calender}
                  />
                </div>
                <div class='flex-content'>
                  <div class='flex-area-primary'>
                    <p class='confidence-text-header'>
                      Free cancellation if plans change
                    </p>
                    <p class='confidence-text-body'>
                      Cancel free of charge anytime. Reserve now and pay at pick
                      up.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className='row mt-2'>
            <div className='col-lg-8 margin-checkout order-last'>
              <div className='row'>
                <div className='hotel-checkout-shadow p-3'>
                  <div className='row'>
                    {extraData !== null && extraData.length !== 0 && (
                      <div>
                        <div>
                          <h4>Extra Services</h4>
                        </div>
                        {extraData.map(item => (
                          <div key={item.ExtrasID}>
                            <Checkbox
                              checked={checkedItems.some(
                                checkedItem =>
                                  checkedItem.ExtrasID === item.ExtrasID
                              )}
                              onChange={() => handleChange(item)}
                            >
                              {item.Extras_Description} -{' '}
                              {priceConversion == false
                                ? selectedTransfer.sale_currency
                                : Object.keys(SelectedCurrRates).length === 0
                                ? BaseCurrRates.base_code
                                : SelectedCurrRates.base_code}{' '}
                              {priceConversion == false
                                ? item.Price
                                : renderPrice(
                                    Number(item.Price),
                                    selectedTransfer.sale_currency
                                  )}
                            </Checkbox>
                          </div>
                        ))}
                      </div>
                    )}
                    <div>
                      <h4 style={{ color: 'black' }}>Guest Informations</h4>
                    </div>
                    <div class='form-group mt-2 col-md-6'>
                      <label className='fw-bold'>Title</label>
                      <select
                        id='inputState'
                        name='title'
                        value={gestinfo.title}
                        onChange={handlegestchange}
                        class='form-control mt-2 form-select select-styling'
                      >
                        <option selected>Select Title</option>
                        <option value='MR'>Mr</option>
                        <option value='MRS'>Mrs</option>
                      </select>
                    </div>
                    <div class='form-group mt-2 col-md-6'>
                      <label className='fw-bold'>First Name</label>
                      <input
                        type='text'
                        class='form-control mt-2'
                        value={gestinfo.firstname}
                        name='firstname'
                        onChange={handlegestchange}
                        placeholder='First Name'
                      />
                    </div>
                    <div class='form-group mt-2 col-md-6'>
                      <label className='fw-bold'>Last Name</label>
                      <input
                        type='text'
                        class='form-control mt-2'
                        value={gestinfo.lastname}
                        name='lastname'
                        onChange={handlegestchange}
                        placeholder='Last Name'
                      />
                    </div>
                    <div class='form-group mt-2 col-md-6'>
                      <label className='fw-bold'>Email</label>
                      <input
                        type='email'
                        class='form-control mt-2'
                        value={gestinfo.email}
                        name='email'
                        onChange={handlegestchange}
                        placeholder='Email'
                      />
                    </div>
                    <div class='form-group field-icon-wrap mt-2 col-md-6'>
                      <label className='fw-bold'>Nationality</label>
                      <Select
                        options={countryList}
                        isSearchable={true}
                        className='mt-2'
                        onChange={handleCountryChange}
                        value={selectedCountry}
                        getOptionLabel={option => (
                          <div>
                            <img
                              src={option.flag}
                              alt={option.label}
                              style={{ width: '20px', marginRight: '8px' }}
                            />
                            {option.label} ({option.phoneCode})
                          </div>
                        )}
                        getOptionValue={option => option.value}
                      />
                    </div>
                    <div class='form-group mt-2 col-md-6'>
                      <label className='fw-bold'>Phone Number</label>
                      <input
                        type='text'
                        class='form-control mt-2'
                        value={gestinfo.phonenumber}
                        name='phonenumber'
                        onChange={handlegestchange}
                        placeholder='Phone Number'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <article
                id='complete'
                class='row segment mt-2 no-target review-and-book checkout-hotel-detail'
              >
                <div id='fxmp' class='hidden'>
                  <p class='fx-header'>
                    <strong>
                      The following currencies are available for your booking:
                    </strong>
                  </p>
                  <fieldset class='fx-fields'>
                    <label class='check'>
                      <input
                        type='radio'
                        class='fx-poscurrency gb-whitelist'
                        name='fx_selected'
                        value='false'
                        data-cko-change='trackFX'
                      />
                      <span class='inline-label fx-label'>
                        Pay in <span class='fx-code fx-poscurrency-code'></span>{' '}
                        - <span class='fx-value fx-poscurrency-value'></span>
                      </span>
                    </label>
                    <label class='check'>
                      <input
                        type='radio'
                        class='fx-cccurrency gb-whitelist'
                        name='fx_selected'
                        value='true'
                        checked='checked'
                        data-cko-change='trackFX'
                      />
                      <span class='inline-label fx-label'>
                        Pay in <span class='fx-code fx-cccurrency-code'></span>{' '}
                        - <span class='fx-value fx-cccurrency-value'></span>{' '}
                      </span>
                    </label>
                  </fieldset>
                </div>
                <div
                  id='important-information'
                  class='important-information-rr-cancellation scroll-wrapper'
                >
                  <h2 class='checkout-title-visual-alignment'>
                    Important Car information{' '}
                  </h2>
                  <div class='rules-and-restrictions-payment car'>
                    <div class='title-sub default secondary'>
                      <ul class='rr-bullets rules-list'>
                        <li>
                          The following rules and restrictions are provided by
                          the car rental company.
                        </li>
                        <li>
                          The driver must present a valid{' '}
                          <a href='#' data-cko-click='openWindow'>
                            driver's license{' '}
                            <span
                              class='icon icon-popup'
                              aria-hidden='true'
                            ></span>{' '}
                            <span class='alt'>(Opens a new window) </span>
                          </a>{' '}
                          and credit card in their name upon pick-up. The credit
                          card is required as a deposit when renting any
                          vehicle. The deposit amount is held by the car rental
                          company. Please ensure sufficient funds are available
                          on the card.
                        </li>
                        <li>
                          International rentals may have different driver
                          license requirements. An international driving license
                          is required if the drivers' license is non-roman
                          alphabet.
                        </li>
                        <li>
                          <a href='#' data-cko-click='openWindow'>
                            Additional charges or restrictions
                            <span
                              class='icon icon-popup'
                              aria-hidden='true'
                            ></span>{' '}
                            <span class='alt'>(Opens a new window) </span>
                          </a>{' '}
                          may apply for drivers under 25 or over 70.
                        </li>
                        <li>
                          Charges for{' '}
                          <a href='#' data-cko-click='openWindow'>
                            refueling
                            <span
                              class='icon icon-popup'
                              aria-hidden='true'
                            ></span>{' '}
                            <span class='alt'>(Opens a new window) </span>
                          </a>
                          , additional drivers, etc. are not included in the
                          total price.
                        </li>
                        <li>
                          <a href='#' data-cko-click='openWindow'>
                            Special equipment
                            <span
                              class='icon icon-popup'
                              aria-hidden='true'
                            ></span>{' '}
                            <span class='alt'>(Opens a new window) </span>
                          </a>
                          , such as child seats and GPS, can be purchased upon
                          pick-up (if available).
                        </li>
                        <li>
                          Geographical restrictions may apply, even for rental
                          contracts that feature unlimited mileage. Some car
                          rental companies do not allow you to take their
                          vehicles across certain domestic or international
                          borders, or may apply an additional charge to do so.
                        </li>
                        <li>
                          Your rental may have{' '}
                          <a href='#' data-cko-click='openWindow'>
                            mandatory, local insurance requirements
                            <span
                              class='icon icon-popup'
                              aria-hidden='true'
                            ></span>{' '}
                            <span class='alt'>(Opens a new window) </span>
                          </a>{' '}
                          that result in additional charges at the time of
                          rental.
                        </li>
                        <li>
                          The total price includes all mandatory taxes and fees.
                        </li>
                        <li>Rental fees are due at pick-up.</li>
                        <li>
                          Unless and otherwise specified, rates are quoted in US
                          dollars.
                        </li>
                        <li>
                          View the rental car company’s{' '}
                          <a href='#' data-cko-click='openWindow'>
                            privacy policy
                            <span
                              class='icon icon-popup'
                              aria-hidden='true'
                            ></span>{' '}
                            <span class='alt'>(Opens a new window) </span>
                          </a>
                          .
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div id='booking-summary'>
                  <hr class='booking-summary-separator' aria-hidden='true' />
                </div>
                {/* <div
                  id='rules-and-restrictions'
                  class='legal-consent page-2  consent-margin'
                >
                  <div class='implicit-consent'>
                    By clicking on the button below, I acknowledge that I have
                    reviewed the
                    <a
                      href='#'
                      data-cko-click='openWindow'
                      data-cko-dctk-pageid='HOTEL.CKO.PRIVACY'
                    >
                      Privacy Statement
                      <span class='icon icon-popup' aria-hidden='true'>
                        <FontAwesomeIcon icon={faWindowRestore} />
                      </span>
                      <span class='alt'>Opens in a new window.</span>
                    </a>{' '}
                    and
                    <a
                      href='#'
                      data-cko-click='openWindow'
                      data-cko-dctk-pageid='HOT.CKO.TravelAdvice'
                    >
                      {' '}
                      Government Travel Advice
                      <span class='icon icon-popup' aria-hidden='true'>
                        <FontAwesomeIcon icon={faWindowRestore} />
                      </span>
                      <span class='alt'>Opens in a new window.</span>
                    </a>{' '}
                    and have reviewed and accept the
                    <a
                      href='#'
                      data-cko-click='openWindow'
                      data-cko-dctk-pageid='HOTEL.CKO.RULES'
                    >
                      Rules &amp; Restrictions
                      <span class='icon icon-popup' aria-hidden='true'>
                        <FontAwesomeIcon icon={faWindowRestore} />
                      </span>
                      <span class='alt'>Opens in a new window.</span>
                    </a>{' '}
                    and{' '}
                    <a
                      href='#'
                      data-cko-click='openWindow'
                      data-cko-dctk-pageid='HOTEL.CKO.TERMS'
                    >
                      Terms of Use
                      <span class='icon icon-popup' aria-hidden='true'>
                        <FontAwesomeIcon icon={faWindowRestore} />
                      </span>
                      <span class='alt'>Opens in a new window.</span>
                    </a>
                    .
                  </div>
                </div> */}
                <div class='row mt-4'>
                  <div className=' p-3'>
                    <div className='row'>
                      <div>
                        <h4 style={{ color: 'black' }}>Payment Method</h4>{' '}
                      </div>
                      <div class='section-tab check-mark-tab text-center mt-3 pb-4'>
                        <ul class='nav' id='myTab' role='tablist'>
                          <li class='nav-item'>
                            <i class='la la-check icon-element'></i>
                            <img src={payment} alt='' />
                          </li>
                        </ul>
                      </div>
                      <div class='form-group mt-2 col-md-6'>
                        <label htmlFor='cardNumber' className='form-label'>
                          Card Holder Name
                        </label>
                        <input
                          type='text'
                          class='form-control'
                          name='name'
                          value={cardInfo.name}
                          onChange={handlecarddetail}
                          placeholder='Name'
                        />
                      </div>
                      <div class='form-group mt-2 col-md-6'>
                        <label htmlFor='cardNumber' className='form-label'>
                          Card number
                        </label>
                        <CardNumberElement
                          className='form-control h-50'
                          id='cardNumber'
                        />
                      </div>
                      <div class='form-group mt-2 col-md-6'>
                        <label htmlFor='expiryDate' className='form-label'>
                          Expiration date
                        </label>
                        <CardExpiryElement
                          className='form-control h-100'
                          id='expiryDate'
                        />
                      </div>
                      <div class='form-group mt-2 col-md-6'>
                        <label htmlFor='cvc' className='form-label'>
                          CVC
                        </label>
                        <CardCvcElement
                          className='form-control h-100'
                          id='cvc'
                        />
                      </div>
                      {error && (
                        <div
                          style={{ color: 'red' }}
                          className='error mt-2'
                          role='alert'
                        >
                          {error}
                        </div>
                      )}
                    </div>
                    <div class='form-check mt-5'>
                      <input
                        class='form-check-input'
                        type='checkbox'
                        checked={isChecked}
                        onChange={handleCheckboxContinue}
                        id='invalidCheck'
                        required
                      />
                      <label class='form-check-label' for='invalidCheck'>
                        By continuing, you agree to the{' '}
                        <span style={{ color: 'red' }}>
                          {' '}
                          Terms and conditions
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <button
                  type='button'
                  onClick={ThrirdPartyTransfer}
                  class='btn-primary btn-action complete-booking-cta booking-button'
                >
                  <span class='btn-label'>Reserve Now</span>
                </button>
                <div class='security-message' aria-hidden='true'>
                  <span class='icon icon-lock' aria-hidden='true'>
                    <FontAwesomeIcon icon={faLock} />
                  </span>
                  <span class='text1'>
                    {' '}
                    We use secure transmission and encrypted storage to protect
                    your personal information.
                  </span>
                  <span class='page-2 visa-mandate-message text1'>
                    Payments are processed in the U.S. except where the travel
                    provider (hotel / airline etc) processes your payment
                    outside the U.S., in which case your card issuer may charge
                    a foreign transaction fee.
                  </span>
                </div>
              </article>
            </div>
            <div className='col-lg-4 margin-checkout '>
              <section id='trip-summary' class='trip-summary cf'>
                <div
                  class='product-summary'
                  data-cko-click='toggleProductDetails'
                  data-cko-space-click='toggleProductDetails'
                >
                  <span
                    class='icon icon-carsalt product-section-icon'
                    aria-hidden='true'
                  >
                    <FontAwesomeIcon icon={faCar} />
                  </span>
                  <div class='product-content'>
                    <a
                      role='button'
                      href='#'
                      style={{ fontSize: '20px' }}
                      class='toggle-trigger product-content-title'
                    >
                      {selectedTransfer.vehicle_Name}
                    </a>
                    <div class='summary'>
                      {/* <div class="location-info">Pickup Location: {selectedTransfer.pickup_City}. Dropof Location: {selectedTransfer.dropof_City} </div> */}
                      <div class='date-info'>
                        {moment(selectedTransfer.pickup_date).format(
                          'DD-MM-YYYY'
                        )}
                      </div>
                      <div class='product-description'>
                        <span class='product-feature'>
                          <span class='icon icon-traveler' aria-hidden='true'>
                            <FontAwesomeIcon icon={faUser} />
                          </span>
                          <span class='alt'>Passengers</span>{' '}
                          {selectedTransfer.search_passenger}
                        </span>
                        <span class='product-feature'>
                          <span class='icon icon-snowflake' aria-hidden='true'>
                            <FontAwesomeIcon icon={faCar} />
                          </span>{' '}
                          <span class='alt' id='Transmission type'>
                            Vehicle
                          </span>
                          {selectedTransfer.no_of_vehicles}
                        </span>
                      </div>
                      <div className='p-3 tour_booking_amount_area'>
                        <div className='Hotel-img'>
                          <div class='card-img mt-2 pb-2'>
                            <a href='#' class='d-block'>
                              <img
                                className='tour-img'
                                src={selectedTransfer.vehicle_image}
                                alt=''
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div class='product-feature'>
                        <p
                          style={{ color: '#1675e0' }}
                          class='icon icon-snowflake'
                          aria-hidden='true'
                        >
                          Pickup Location:
                        </p>
                        <p>{selectedTransfer.pickup_City}</p>
                      </div>
                      <div class='product-feature mt-2'>
                        <p
                          style={{ color: '#1675e0' }}
                          class='icon icon-snowflake'
                          aria-hidden='true'
                        >
                          Dropof Location:
                        </p>
                        <p>{selectedTransfer.dropof_City}</p>
                      </div>
                      {moreDestination.map((Loc, index) => (
                        <div key={index}>
                          <div className='border-line mt-2'></div>
                          <div class='container3 justify-content-center mt-2'>
                            <h5 class='text3 '>Destination {index + 2}</h5>
                          </div>
                          <div>
                            <h6>Pick-up Location </h6>
                            <p>{Loc.subLocationPic}</p>
                            <h6>Drop-off Location </h6>
                            <p>{Loc.subLocationdrop}</p>
                          </div>
                        </div>
                      ))}
                      <div className='border-line mt-2'></div>
                      {mazarat.length !== 0 && (
                        <div>
                          <div class='container3 justify-content-center mt-2'>
                            <h5 class='text3 '>Mazarat</h5>
                          </div>
                          <ul class='list-items  list-items-2  mt-2 py-2'>
                            {mazarat.map((item, index) => (
                              <li key={index}>{item.ziyarat_City}</li>
                            ))}
                          </ul>
                          <div className='border-line mt-2'></div>
                        </div>
                      )}
                      <ul class='list-items  list-items-2  mt-2 py-2'>
                        <li>
                          <span>Vehicle Class:</span>
                          {selectedTransfer.vehicle_Name}
                        </li>
                        <li>
                          <span>Transfer Type:</span>
                          {selectedTransfer.transfer_type}
                        </li>
                        <li>
                          <span>Pickup Date:</span>
                          {moment(selectedTransfer.pickup_date).format(
                            'DD-MM-YYYY'
                          )}
                        </li>
                        <li>
                          <span>Duration:</span>
                          {selectedTransfer.duration}
                        </li>
                        <li>
                          <span>Passenger:</span>
                          {selectedTransfer.search_passenger}
                        </li>
                        <li>
                          <span>Vehicles:</span>
                          {selectedTransfer.no_of_vehicles}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class='multi-item-price-breakdown-summary'>
                  <div class='price-breakdown'>
                    <div class='total-breakdown-summary '>
                      {/* <div id='no_surprises' class='nosurprises'>
                        <div class='nosurprises-wrapper'>
                          <div>
                            <span class='nosurprises-header'>
                              No surprises!
                            </span>
                            <span class='noSurprises-message'>
                              Here’s a breakdown of your price:
                            </span>
                          </div>
                        </div>
                      </div> */}
                      {/* <dl class='cf zero-due  due-today-zero'>
                        <dt class='due_today'>Due today</dt>
                        <dd data-price-update='zeroDueToday'>$0.00</dd>
                      </dl> */}
                      {/* <div class='fee-details due-at-car-rental car-rental-counter-border with-total-price-breakdown'>
                        <a
                          role='button'
                          href='#'
                          aria-controls='car-fee-summary'
                          data-control='toggle'
                          data-transition='fade'
                          data-toggle-text='Due at car rental counter<span class="icon icon-expand" aria-hidden="true"></span>'
                          class='toggle-trigger '
                          data-isdisplay-icon=''
                          aria-expanded='false'
                          data-expand-text='Due at car rental counter<span class="icon icon-expand" aria-hidden="true"></span>'
                          data-track='CAR.CKO.PaymentDueToday.COLLAPSE'
                        >
                          Due at car rental counter
                        </a>
                        <span
                          class='subtotal-due-amount oms-price'
                          data-price-update='dueAtPickupPrice'
                        >
                          {CurrencyRates === undefined
                            ? selectedTransfer.sale_currency
                            : CurrencyRates.selectedcurrency}{' '}
                          {renderPrice(
                            Number(selectedTransfer.total_fare_markup)
                          )}
                        </span>
                        <div
                          id='car-fee-summary'
                          class='toggle-pane '
                          role='region'
                        >
                          <div class='toggle-inner'>
                            <dl class='cf'>
                              <dt class='description'>Car rental</dt>
                              <dd
                                class='amount'
                                data-price-update='carTaxOrFeeItem0'
                              >
                                {' '}
                                {CurrencyRates === undefined
                                  ? selectedTransfer.sale_currency
                                  : CurrencyRates.selectedcurrency}{' '}
                                {renderPrice(
                                  Number(selectedTransfer.total_fare_markup)
                                )}
                              </dd>
                              <dt class='description'>
                                Rft - registration fee/ road tax
                              </dt>
                              <dd
                                class='amount'
                                data-price-update='carTaxOrFeeItem1'
                              >
                                $0
                              </dd>
                              <dt class='description'>Tax - other tax</dt>
                              <dd
                                class='amount'
                                data-price-update='carTaxOrFeeItem2'
                              >
                                $0
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <div class='total-summary'>
                      <div class='total-due-today'>
                        <span class='total-desc '>Total</span>
                        <div
                          id='hasNoPointsAndDueAtPickUpPrice'
                          class='prod-total oms-price'
                          data-price-update='finalTripTotal'
                        >
                          {' '}
                          {priceConversion == false
                            ? (StripeCurrency = selectedTransfer.sale_currency)
                            : (StripeCurrency =
                                Object.keys(SelectedCurrRates).length === 0
                                  ? BaseCurrRates.base_code
                                  : SelectedCurrRates.base_code)}{' '}
                          {priceConversion == false
                            ? (StripePayment =
                                Number(selectedTransfer.total_fare_markup) +
                                Number(extraTransferTotal.total))
                            : (StripePayment = renderPrice(
                                Number(selectedTransfer.total_fare_markup) +
                                  Number(extraTransferTotal.total),
                                selectedTransfer.sale_currency
                              ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='coalition-rewards-section'> </div>
              </section>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default TransferCheckout
