import React, { useEffect, useState } from 'react'
import '../../Components/Carousal/style.css'
import SearchBar from '../../Components/SearchBar/Searchbar'
import Layout from '../../Components/Layout/Layout'
import Loader from '../../Components/Loading/Loader'
import Helmet from 'react-helmet'
import wow from 'wowjs'
import IndexComponents from './IndexComponents'
function Home () {
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    new wow.WOW().init()
    
    const fetchData = async () => {
      
      await new Promise(resolve => setTimeout(resolve, 2000))
      setIsLoading(false)
    }

    fetchData()
  }, []);


  return (
    <>
      <Helmet><title>Haramayn Hotels</title></Helmet>
      {isLoading && <Loader />}
      <Layout>
        <SearchBar />
        <IndexComponents />
      </Layout>
     
    </>
  )
}

export default Home
