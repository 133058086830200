const initialState = {
    transferlist: [],
  };
  
  const transferReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_TRANSFER_LIST':
        return {
          ...state,
          transferlist: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default transferReducer;
  