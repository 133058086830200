import React from "react";
import Layout from "../../Components/Layout/Layout";
import img from './faq.jpg'
function ComplaintPolicy(){
    return(
        <>
        <Layout>
        <div>
                <img src={img}/>
            </div>
            <div className='container'>
          <div className='row mt-3 text-center'>
            <h3>Complaints Policy & Procedures</h3>
          </div>
          <div className='row mt-4 '>
            <p className='FontSize15'>
              Haramayn Hotels stands out as the pioneer tour operator, providing
              unparalleled travel experiences for explorers worldwide. Our
              meticulously crafted packages cater to every aspect of your
              journey, including hotel bookings, flights, transfers, and
              activity arrangements. From securing comfortable accommodations to
              facilitating seamless travel arrangements, our dedicated team
              meticulously attends to every detail, ensuring a stress-free and
              enriching experience. Trust in Haramayn Hotels to tailor packages that
              perfectly align with your individual needs and preferences,
              providing peace of mind and unforgettable memories throughout your
              expedition.
            </p>{' '}
          </div>
          <p className='mt-2'>
            How to Submit a Complaint
            <br />
            You can submit a complaint through the following channels:
            <br />
            Email: info@haramaynhotels.com
            <br />
            Phone: +966 509 786777
            <br />
            Online Form: Contact us on our website
            <br />
            Please include as much detail as possible, such as your booking
            reference and the nature of your complaint.
          </p>
        </div>
        </Layout>
        </>
    )
}

export default ComplaintPolicy;